export const IconDictionary = {
  ///////////////////////////////////
  // Keep this alphabetic please!!!
  ///////////////////////////////////
  add: 'fas fa-plus-circle',
  addDoc: 'fal fa-file-circle-plus',
  addUser: 'fas fa-user-plus',
  ageBands: 'fal fa-sliders-v',
  alert: 'fal fa-exclamation-triangle',
  allgroups: 'fal fa-users',
  allocate: 'fal fa-list-alt',
  android: 'fab fa-android',
  apple: 'fab fa-apple',
  asterisk: 'fal fa-asterisk',
  archive: 'fal fa-archive',
  'arrow-left': 'fas fa-arrow-left',
  'arrow-right': 'fas fa-arrow-right',
  attendance: 'fal fa-clipboard-check',
  attendeeHere: 'fal fa-user-check',
  attendeeAbsent: 'fal fa-user-xmark',
  attendeeLate: 'fal fa-user-clock',
  attendeeExcused: 'fal fa-user-minus',
  'auto-allocate': 'fal fa-magic',
  awaitingUser: 'fas fa-user-clock',

  back: 'fal fa-arrow-to-left',
  balanceBalance: 'fas fa-money-bill',
  balanceCredit: 'fas fa-wallet',
  balanceVouchers: 'fas fa-receipt',
  basket: 'far fa-shopping-basket',
  bell: 'fal fa-bell',
  blankSlateSignUps: 'fad fa-calendar-plus',
  block: 'fal fa-ban',
  blockdates: 'fal fa-calendar-times',

  caretUp: 'fas fa-caret-up',
  caretDown: 'fas fa-caret-down',
  changeRequest: 'fal fa-comment-edit',
  chart: 'fas fa-chart-bar',
  check: 'fal fa-check-square',
  checkCircle: 'far fa-check-circle',
  chevronDown: 'fal fa-chevron-down',
  chevronLeft: 'fal fa-chevron-left',
  chevronRight: 'fal fa-chevron-right',
  circle: 'fas fa-circle',
  clock: 'fas fa-clock',
  close: 'fal fa-times',
  cog: 'fas fa-cog',
  coin: 'fas fa-coin',
  coins: 'fas fa-coins',
  collapse: 'fas fa-minus-square',
  confirm: 'fal fa-check-circle',
  configSettings: 'fal fa-sliders-v',
  commentEdit: 'far fa-comment-alt-edit',
  contacts: 'fal fa-address-book',
  copy: 'fal fa-copy',
  corporation: 'fal fa-building',
  cost: 'fas fa-money-bill',
  createEvents: 'fal fa-calendar-plus',
  cross: 'fal fa-times',
  'cross-solid': 'fas fa-times',
  crossSquare: 'fal fa-times-square',
  crossCircle: 'fal fa-times-circle',
  customForms: 'fal fa-table',

  dashboard: 'fal fa-tachometer-alt-slow',
  database: 'fal fa-database',
  date: 'far fa-calendar',
  delete: 'fal fa-trash-alt',
  diary: 'fal fa-calendar',
  documents: 'fal fa-copy',
  done: 'fal fa-check-circle',
  download: 'fal fa-download',

  edit: 'fal fa-edit',
  ellipsis: 'fas fa-ellipsis-h',
  emailTemplate: 'fal fa-envelope-open-text',
  events: 'fal fa-check-square',
  eventSportsFriendly: 'far fa-handshake',
  eventSportsMatch: 'far fa-medal',
  eventSportsCup: 'far fa-trophy',
  eventSportsPractice: 'far fa-school',
  eventSportsTournament: 'far fa-list-alt',
  eventManageInvites: 'fal fa-calendar-circle-user',
  eventManageConsent: 'fal fa-check-double',
  eventManageSelection: 'fal fa-list-check',
  eventTripTrip: 'far fa-bus-school',
  eventTripCamp: 'far fa-campground',
  eventTripResidential: 'far fa-house',
  eventOtherLesson: 'far fa-person-chalkboard',
  eventOtherAssembly: 'far fa-people-group',
  exclamation: 'fal fa-exclamation',
  exclamationCircle: 'fal fa-exclamation-circle',
  expand: 'fas fa-plus-square',
  expandWindow: 'fas fa-expand-arrows-alt',
  existingEvents: 'fas fa-th-list',
  exit: 'fas fa-sign-out',

  favourite: 'fas fa-star',
  follow: 'ti-direction',
  followLink: 'fal fa-external-link',
  forms: 'fal fa-clipboard',
  formtags: 'fal fa-file-user',
  forward: 'fal fa-arrow-to-right',
  file: 'fas fa-file',
  'file-csv': 'fas fa-file-csv',
  'file-excel': 'fas fa-file-excel',
  'file-pdf': 'fas fa-file-pdf',
  filter: 'fal fa-filter',
  fullScreen: 'fal fa-expand-wide',

  garage: 'fas fa-garage',
  generateEvents: 'fal fa-calendar-edit',
  generateFees: 'fal fa-usd-circle',
  globe: 'fas fa-globe-americas',
  groups: 'fal fa-users',
  groupdetail: 'fal fa-info-circle',
  groupSettings: 'fas fa-users-cog',
  guardians: 'fal fa-shield-alt',

  handPointer: 'fa-light fa-hand-pointer',
  hamburgerMenu: 'far fa-bars',
  hide: 'fal fa-eye-slash',
  history: 'fal fa-history',
  homeWork: 'ti ti-briefcase',

  importData: 'fal fa-file-import',
  inboxAll: 'fal fa-envelope-open',
  inboxArchived: 'fal fa-archive',
  inboxSent: 'fal fa-location-arrow',
  inboxStarred: 'fal fa-star',
  inboxUnread: 'fal fa-envelope',
  info: 'fal fa-info-circle',
  insurance: 'fal fa-hands-helping',
  integration: 'fas fa-plug',
  investment: 'fal fa-piggy-bank',
  invoice: 'fas fa-file-invoice',

  joinrequests: 'fal fa-hand-point-up',
  kit: 'fad fa-tshirt',

  languageSelect: 'fal fa-language',
  leaver: 'fas fa-user-slash',
  lightning: 'fal fa-bolt',
  link: 'fal fa-link',
  linkedAccounts: 'fal fa-link',
  list: 'fal fa-list-ul',
  listBoxed: 'fal fa-list-alt',
  loading: 'far fa-pulse fa-spinner',
  locations: 'fal fa-map-signs',
  'location-pin': 'fa fa-map-marker-alt',
  'location-item': 'fas fa-building',
  'location-address': 'fas fa-map-marked',
  locked: 'fal fa-lock',
  logout: 'fas fa-right-from-bracket',

  manageConsent: 'fas fa-tasks',
  mainSettings: 'fal fa-atom',
  manualBooking: 'fal fa-person-carry',
  massPupilTransportAssignment: 'fas fa-users-class',
  matchReports: 'ti ti-headphone-alt',
  matchResults: 'far fa-file-lines',
  medical: 'fal fa-notes-medical',
  meeting: 'fas fa-users',
  meetingFormat: 'far fa-users-rectangle',
  membership: 'fal fa-address-card',
  messageCenter: 'fal fa-envelope',
  microphone: 'fas fa-microphone',
  'microphone-off': 'fas fa-microphone-slash',
  minus: 'fal fa-minus',
  minusCircle: 'fas fa-minus-circle',
  minusCircleD: 'fad fa-minus-circle',

  moneyBill: 'fal fa-money-bill-wave',
  mortgage: 'fal fa-home-heart',
  move: 'far fa-arrows-up-down-left-right',

  news: 'ti ti-announcement',
  next: 'fal fa-angle-double-right',
  notes: 'fal fa-sticky-note',
  notices: 'ti ti-blackboard',
  notify: 'fal fa-envelope',

  ok: 'fal fa-check',
  onHold: 'fas fa-pause-circle',
  onSchedule: 'fas fa-calendar-check',
  order: 'far fa-arrow-down-wide-short',

  pageNew: 'fas fa-file-plus',
  pageExisting: 'fas fa-file-alt',
  pageClone: 'fas fa-copy',
  pageExternal: 'fas fa-external-link-square-alt',
  parentNotice: 'ti ti-home',
  payments: 'fas fa-credit-card',
  pin: 'fas fa-thumbtack',
  phoneLandline: 'fas fa-phone',
  phoneMobile: 'fas fa-mobile',
  play: 'fal fa-play',
  plus: 'fal fa-plus',
  plusCircle: 'fas fa-plus-circle',
  plusCircleD: 'fad fa-plus-circle',
  plusFolder: 'fas fa-folder-plus',
  pom: 'fas fa-medal',
  portal: 'far fa-browser',
  preSelect: 'fal fa-hand-point-right',
  products: 'fas fa-cubes',
  prev: 'fal fa-angle-double-left',
  print: 'fal fa-print',
  profile: 'fal fa-portrait',
  publishEvents: 'fal fa-calendar-check',
  pupilAllocationOverview: 'fal fa-id-card',
  pupilSelections: 'fal fa-check-square',

  question: 'fa fa-question',
  'question-solid': 'fas fa-question',
  questionCircle: 'fal fa-question-circle',

  receipt: 'fal fa-receipt',
  referenceData: 'fal fa-hdd',
  refresh: 'fal fa-sync',
  registerNotStarted: 'far fa-circle-xmark',
  registerIncomplete: 'far fa-arrows-rotate',
  registerComplete: 'far fa-circle-check',
  refund: 'fal fa-money-check-alt',
  register: 'fal fa-check-square',
  reggroups: 'fal fa-home',
  remove: 'fas fa-minus-circle',
  reply: 'fas fa-reply',
  reports: 'fal fa-cloud-download',
  restore: 'fal fa-undo',
  route: 'fal fa-route',
  ruler: 'fas fa-horizontal-rule',

  save: 'fal fa-save',
  school: 'fas fa-school',
  schoolDetails: 'fas fa-money-check',
  schoolsbuddy: 'far fa-layer-group',
  search: 'fal fa-search',
  searchSettings: 'fas fa-sliders-h',
  send: 'fal fa-share-square',
  sendCommunications: 'fal fa-bullhorn',
  show: 'fal fa-eye',
  singleSignOn: 'fal fa-key',
  siteMap: 'fas fa-sitemap',
  smsTopUp: 'fal fa-comment-dollar',
  sort: 'fas fa-sort',
  sortUp: 'fas fa-sort-up',
  sortDown: 'fas fa-sort-down',
  'square-down': 'fa fa-caret-square-down',
  'square-up': 'fa fa-caret-square-up',
  staff: 'fal fa-id-badge',
  staffNotice: 'ti ti-clipboard',
  staffroles: 'fal fa-address-card',
  staffschedule: 'fal fa-calendar-alt',
  statement: 'fal fa-file-invoice-dollar',
  stop: 'fal fa-hand-paper',
  superAdmin: 'fas fa-star',
  style: 'fas fa-paint-roller',
  switch: 'fas fa-repeat',

  tasks: 'fal fa-clipboard-list',
  tick: 'fal fa-check',
  tickDouble: 'fal fa-check-double',
  'tick-solid': 'fas fa-check',
  tickCircle: 'fal fa-check-circle',
  ticket: 'fal fa-ticket',
  transactions: 'fal fa-bars',
  transportAll: 'fal fa-car-bus',

  unarchive: 'fas fa-archive',
  unblock: 'fal fa-check-circle',
  uncheck: 'fal fa-square',
  'unknown-circle': 'fal fa-question-circle',
  unlocked: 'fal fa-lock-open',
  unsorted: 'fal fa-sort',
  users: 'fal fa-user',
  'user-edit': 'fas fa-user-edit',
  userAdd: 'fas fa-user-plus',
  userTypeParent: 'fas fa-user',
  userTypeStaff: 'fas fa-school',
  userTypeStudent: 'fas fa-users-class',
  registrationTypeParent: 'fas fa-person',
  registrationTypeChildren: 'fas fa-children',
  registrationTypeBoth: 'fas fa-family-pants',

  video: 'fas fa-video',
  'video-off': 'fas fa-video-slash',

  wallet: 'fal fa-wallet',
  warning: 'fal fa-exclamation-triangle',
  'warning-circle': 'fal fa-exclamation-circle',
  web: 'fal fa-globe',
  welcomemessage: 'ti ti-comments'

  ///////////////////////////////////
  // Keep this alphabetic please!!!
  ///////////////////////////////////
};

export interface TransportIcon {
  iconName: string;
  displayName: string;
  unicode: string;
  faIcon: string;
  id: string;
}

export const TransportIcons: TransportIcon[] = [
  {
    iconName: 'transport-bike',
    displayName: 'SB_Bicycle',
    unicode: '\uf206',
    faIcon: 'bicycle',
    id: 'bike'
  },
  {
    iconName: 'transport-bus',
    displayName: 'SB_Bus',
    unicode: '\uf5dd',
    faIcon: 'bus-school',
    id: 'bus'
  },
  {
    iconName: 'transport-car',
    displayName: 'SB_Car',
    unicode: '\uf5e4',
    faIcon: 'car-side',
    id: 'car'
  },
  {
    iconName: 'transport-metro',
    displayName: 'SB_Metro',
    unicode: '\uf239',
    faIcon: 'subway',
    id: 'metro'
  },
  {
    iconName: 'transport-minibus',
    displayName: 'SB_Minibus',
    unicode: '\uf5b6',
    faIcon: 'shuttle-van',
    id: 'minbus'
  },
  {
    iconName: 'transport-motorbike',
    displayName: 'SB_Motorcycle',
    unicode: '\uf21c',
    faIcon: 'motorcycle',
    id: 'motorbike'
  },
  {
    iconName: 'transport-taxi',
    displayName: 'SB_Taxi',
    unicode: '\uf1ba',
    faIcon: 'taxi',
    id: 'taxi'
  },
  {
    iconName: 'transport-train',
    displayName: 'SB_Train',
    unicode: '\uf238',
    faIcon: 'train',
    id: 'train'
  },
  {
    iconName: 'transport-tram',
    displayName: 'SB_Tram',
    unicode: '\uf7da',
    faIcon: 'tram',
    id: 'tram'
  },
  {
    iconName: 'transport-truck',
    displayName: 'SB_Truck',
    unicode: '\uf0d1',
    faIcon: 'truck',
    id: 'truck'
  },
  {
    iconName: 'transport-walk',
    displayName: 'SB_Walk',
    unicode: '\uf554',
    faIcon: 'walking',
    id: 'walk'
  },
  {
    iconName: 'transport-other',
    displayName: 'SB_Other',
    unicode: '\uf85a',
    faIcon: 'car-bus',
    id: 'other'
  }
];
