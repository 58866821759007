<div class="container-fluid form-horizontal">
  <fieldset class="rhythm-m-t-1">
    <div class="form-group" [hidden]="!isTripAdminOrHigher">
      <label class="control-label col-sm-2" [innerHTML]="'SB_Clubs' | translate"></label>
      <div class="col-md-9">
        <ng1-team-club-picker-summary
          [clubIds]="model.clubIds"
          [showClubs]="true"
          (onChangeModel)="change()"
        ></ng1-team-club-picker-summary>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" [innerHTML]="'SB_Teams' | translate"></label>
      <div class="col-md-9">
        <ng1-team-club-picker-summary
          [teamIds]="model.teamIds"
          [showTeams]="true"
          (onChangeModel)="change()"
        ></ng1-team-club-picker-summary>
      </div>
    </div>
    <div class="form-group" *ngIf="showSubjects">
      <label class="control-label col-sm-2" [innerHTML]="'SB_SubjectClasses' | translate"></label>
      <div class="col-md-9">
        <ng1-team-club-picker-summary
          [subjectClassIds]="model.subjectClassIds"
          [showSubjectClasses]="true"
          (onChangeModel)="change()"
        ></ng1-team-club-picker-summary>
      </div>
    </div>
  </fieldset>
  <ng1-team-club-picker
    [(clubIds)]="model.clubIds"
    [(teamIds)]="model.teamIds"
    [(subjectClassIds)]="model.subjectClassIds"
    [(excludedTeamIds)]="model.excludedTeamIds"
    [(excludedClubIds)]="model.excludedClubIds"
    [(excludedSubjectClassIds)]="model.excludedSubjectClassIds"
    (onChangeModel)="change()"
    [subjectsEnabled]="showSubjects"
  ></ng1-team-club-picker>
</div>
<div class="modal-footer">
  <button class="btn btn-success" ng-click="close()" [innerHTML]="'SB_Continue' | translate"></button>
</div>
