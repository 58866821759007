<div class="toast-container">
  <ngb-toast
    *ngFor="let toastItem of toast.toasts"
    [class]="toastItem.toastClass"
    [autohide]="!toastItem.delayWhenHover || autohide"
    [delay]="toastItem.timeout || 5000"
    (mouseenter)="autohide = false"
    (mouseleave)="autohide = true"
    (hidden)="toast.remove(toastItem)"
  >
    <sb-alert
      [alertClass]="toastItem.toastClass"
      [customClasses]="toastItem.additionalClasses"
      [alertTitle]="toastItem.toastTitle"
      [message]="toastItem.content"
      [template]="toastItem.template"
      [routerLink]="toastItem.routerLink"
      (onDismiss)="toast.remove(toastItem)"
    ></sb-alert>
  </ngb-toast>
</div>
