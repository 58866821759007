import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SubMenuItemId } from '@sb-events/enums/sub-menu-item';
import { OrganisationLabelTags } from '@sb-shared/constants/organisation-label-tags.constants';
import { AvatarMenuItemConfig, SubMenuItemConfig } from '@sb-shared/constants/submenu.constants';
import { RoleId } from '@sb-shared/enums/role.enum';
import { MenuItem, MenuItems, SubMenuItem } from '@sb-shared/models/UI/menu-item';
import { StudentProfileCanViewSettings, Organisation } from '@sb-shared/models/organisation';
import { HttpRequestSettings, apis, controllerTypes } from '@sb-shared/models/request-settings';
import { UserUi } from '@sb-shared/models/user-ui';
import { BehaviorSubject, Observable, ReplaySubject, forkJoin, of } from 'rxjs';
import { filter, map, share, shareReplay, switchMap, take } from 'rxjs/operators';
import { CcaSignUpService } from 'src/app/cca-sign-up/services/cca-sign-up.service';
import { DiaryService } from 'src/app/diary/services/diary.service';
import { MainMenuItem } from '../models/UI/menu-item';
import { MainMenuItemId } from './../../events/enums/main-menu-item';
import { MessageViewingService } from './../../messaging/services/message-viewing.service';
import { ArrayService } from './array.service';
import { HttpWebApiService } from './http-web-api.service';
import { OrganisationService } from './organisation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  currentPageMenuItems: ReplaySubject<MenuItems> = new ReplaySubject<MenuItems>(1);
  currentPageSubMenuItems: ReplaySubject<SubMenuItem[]> = new ReplaySubject<SubMenuItem[]>(1);

  currentMainMenuItem: ReplaySubject<MainMenuItem> = new ReplaySubject<MainMenuItem>(1);
  currentSubMenuItem: ReplaySubject<SubMenuItem> = new ReplaySubject<SubMenuItem>(1);

  cachedMainMenuItems$: Observable<MainMenuItem[]>;
  cachedSubMenuItems$: Observable<SubMenuItem[]>;
  cachedAvatarMenuItem$: Observable<MenuItem[]>;

  private showSubMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public get showSubMenu$(): Observable<boolean> {
    return this.showSubMenu.asObservable();
  }

  constructor(
    private router: Router,
    private user: UserService,
    private organisation: OrganisationService,
    private messageViewing: MessageViewingService,
    private diary: DiaryService,
    private http: HttpWebApiService,
    private ccaSignUp: CcaSignUpService,
    private arraySvc: ArrayService
  ) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      // Type any to allow url property
      .subscribe((event: NavigationEnd) => {
        this.getMainMenuItems().subscribe(mainMenuItems => {
          const currentMainMenuItem =
            mainMenuItems.find(
              mainMenuItem => mainMenuItem.route && event.urlAfterRedirects?.indexOf(mainMenuItem.route) === 1
            ) || mainMenuItems.find(mainMenuItem => mainMenuItem.menuItemTypeId === MainMenuItemId.Dashboard);
          if (currentMainMenuItem) {
            this.currentMainMenuItem.next(currentMainMenuItem);
          } else {
            this.currentMainMenuItem.next(null);
          }
        });

        this.getSubMenuItems().subscribe(subMenuItems => {
          const currentSubMenuItem = subMenuItems.find(
            subMenuItem => subMenuItem.route && event.urlAfterRedirects?.indexOf(subMenuItem.route) > -1
          );

          if (currentSubMenuItem) {
            this.currentSubMenuItem.next(currentSubMenuItem);
          } else {
            this.currentSubMenuItem.next(null);
          }
        });
      });
  }

  getMainMenuItems(): Observable<MainMenuItem[]> {
    return this.user.getCurrentUser().pipe(
      switchMap(user => {
        return forkJoin([
          this.messageViewing.getPersonUnreadCount(),
          // if the user is as a staff, no need to get invitation data
          user.isStaff ? of([]) : this.diary.getInvites(user.id),
          this.getCachedMainMenuItems(user.isStaff)
        ]).pipe(
          map(([unreadCount, invites, mainMenuItems]) => {
            return mainMenuItems.map(mainMenuItem => {
              switch (mainMenuItem.menuItemTypeId) {
                case MainMenuItemId.Messaging:
                  mainMenuItem.count = unreadCount ?? 0;
                  break;
                case MainMenuItemId.Diary:
                  mainMenuItem.count = invites?.length ?? 0;
                  break;
                case MainMenuItemId.Invites:
                  mainMenuItem.count = invites?.length ?? 0;
                  mainMenuItem.isHidden = true;
                  break;
              }
              return mainMenuItem;
            });
          })
        );
      }),
      share()
    );
  }

  getCachedMainMenuItems(isStaff): Observable<MainMenuItem[]> {
    if (this.cachedMainMenuItems$) {
      return this.cachedMainMenuItems$;
    }

    const propertiesConfig: HttpRequestSettings = {
      api: apis.Core,
      controllerType: controllerTypes.User,
      params: { viewAsStaff: isStaff }
    };
    this.cachedMainMenuItems$ = this.http.get('Menu/Top', propertiesConfig).pipe(shareReplay(1));
    return this.cachedMainMenuItems$;
  }

  getMainMenuItemByRoute(route: string) {
    return this.getMainMenuItems().subscribe(mainMenuItems => {
      return mainMenuItems.find(menuItem => {
        return menuItem.route && route?.indexOf(menuItem.route) === 1;
      });
    });
  }

  getAvatarMenuItems(): Observable<MenuItem[]> {
    // Filter subMenuItems based on user settings
    if (!this.cachedAvatarMenuItem$) {
      this.cachedAvatarMenuItem$ = forkJoin([
        this.user.getCurrentUser(),
        this.organisation.getCurrentOrganisation()
      ]).pipe(
        switchMap(([user, organisation]) => {
          const menuItems = AvatarMenuItemConfig.filter(menuItem => {
            const userKeyMatch = this.arraySvc.keyMatch(menuItem.userKeys, user);
            const organisationKeyMatch = this.arraySvc.keyMatch(menuItem.organisationKeys, organisation);
            return userKeyMatch && organisationKeyMatch;
          });
          const switchItem = menuItems.find(menuItem => menuItem.label == OrganisationLabelTags.Switch);
          if (switchItem) {
            switchItem.externalUrl = organisation.switchOrganisationUrl;
          }
          return of(menuItems);
        }),
        shareReplay(1)
      );
    }
    return this.cachedAvatarMenuItem$;
  }

  getSubMenuItems(): Observable<SubMenuItem[]> {
    // Filter subMenuItems based on user settings
    if (!this.cachedSubMenuItems$) {
      this.cachedSubMenuItems$ = forkJoin([
        this.user.getCurrentUser(),
        this.organisation.getCurrentOrganisation()
      ]).pipe(
        switchMap(([user, organisation]) => {
          const menuItems = SubMenuItemConfig.filter(menuItem => {
            const userKeyMatch = this.arraySvc.keyMatch(menuItem.userKeys, user);
            const organisationKeyMatch = this.arraySvc.keyMatch(menuItem.organisationKeys, organisation);
            const userRoleMatch =
              !menuItem.requiredRoles || menuItem.requiredRoles.some(role => user.userRoleIds.includes(role));
            return userKeyMatch && organisationKeyMatch && userRoleMatch;
          });
          let filterredMenuItems = this.filterSubMenuByOrgConfig(menuItems, organisation);

          if (user.isCcaAdmin) {
            return this.ccaSignUp.getActiveCcaTypeMenuItems().pipe(
              map(res => [
                ...filterredMenuItems,
                ...res.ccaSignupMenuItems.map(menuItem => ({
                  id: menuItem.id,
                  label: menuItem.title,
                  parentId: MainMenuItemId.CcaSignUp,
                  route: 'CcaSignup/' + menuItem.ccaSignupTypeId,
                  icon: menuItem.iconClass
                }))
              ])
            );
          }

          if (user.userRoleIds.includes(RoleId.ExternalCoachAccess)) {
            filterredMenuItems = this.displayMenuItemsBasedOnSettings(
              user,
              organisation.externalCoachRoleSettings,
              filterredMenuItems
            );
          }

          //TODO: will add same custom menu items for bus monitor

          return of(filterredMenuItems);
        }),
        shareReplay(1)
      );
    }
    return this.cachedSubMenuItems$;
  }

  private filterSubMenuByOrgConfig(menuItems: SubMenuItem[], organisation: Organisation) {
    return menuItems.filter(menuItem => {
      if (menuItem.parentId !== MainMenuItemId.Reception) {
        return true;
      } else if (!organisation.receptionTabVisible) {
        console.warn('Reception tab visible settings not found in organisation settings.');
        return true;
      }

      switch (menuItem.id) {
        case SubMenuItemId.Events:
          return organisation.receptionTabVisible.events;

        case SubMenuItemId.MessageCenter:
          return organisation.receptionTabVisible.messageCenter;

        case SubMenuItemId.RegGroups:
          return organisation.receptionTabVisible.regGroups;

        case SubMenuItemId.AllGroups:
          return organisation.receptionTabVisible.allGroups;

        case SubMenuItemId.Users:
          return organisation.receptionTabVisible.users;

        case SubMenuItemId.Locations:
          return organisation.receptionTabVisible.locations;

        case SubMenuItemId.JoinRequests:
          return organisation.receptionTabVisible.joinRequests;

        case SubMenuItemId.ReceptionReports:
          return organisation.receptionTabVisible.reports;

        case SubMenuItemId.StaffSchedule:
          return organisation.receptionTabVisible.staffSchedule;

        case SubMenuItemId.Attendance:
          return organisation.receptionTabVisible.attendance;

        default:
          return true; // !show sub item of reception by default
      }
    });
  }

  setCurrentPageMenuItems(menuItems: MenuItems) {
    this.currentPageMenuItems.next(menuItems);
  }

  getCurrentSubMenuItem(): Observable<SubMenuItem> {
    return this.currentSubMenuItem.pipe(take(1));
  }

  getPageTabs() {
    return this.getCurrentSubMenuItem().pipe(map(subMenuItem => subMenuItem?.pageTabs));
  }

  setShowSubMenu(showSubMenu: boolean): void {
    this.showSubMenu.next(showSubMenu);
  }

  private displayMenuItemsBasedOnSettings(
    user: UserUi,
    settings: StudentProfileCanViewSettings,
    filterredMenuItems: SubMenuItem[]
  ): SubMenuItem[] {
    if (user.userRoleIds.includes(RoleId.Teacher) || !settings.canViewStudentProfile) {
      return filterredMenuItems;
    }

    const removeTabItemIds = new Set(this.getRemoveTabItemIdsBySetting(settings));

    return filterredMenuItems.filter(menuItem => !removeTabItemIds.has(menuItem.id));
  }

  private getRemoveTabItemIdsBySetting(settings: StudentProfileCanViewSettings): SubMenuItemId[] {
    const removeTabIds: SubMenuItemId[] = [];
    const { canViewEventGuardians, canViewEventMedicalRecords, canViewCustomData } = settings;

    if (!canViewEventGuardians) {
      removeTabIds.push(SubMenuItemId.Linked);
    }
    if (!canViewEventMedicalRecords) {
      removeTabIds.push(SubMenuItemId.Medical);
    }
    if (!canViewCustomData) {
      removeTabIds.push(SubMenuItemId.UserForms);
    }

    return removeTabIds;
  }
}
