import { Injectable } from '@angular/core';
import { EventFieldIds } from '@sb-events/constants/event-fields-ids';
import { formElements } from '@sb-shared/constants/shared.constant';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { Options } from '@sb-shared/models/UI/filter';
import {
  FieldRule,
  FieldRules,
  WizardField,
  WizardFields,
  WizardTab,
  WizardTabs
} from '@sb-shared/models/UI/wizard-tabs';
import { SubWizardSave } from '@sb-shared/models/sub-wizard-save';
import { SubWizardSaveEvent } from '@sb-shared/models/sub-wizard-save-event';
import { DateTimeService } from '@sb-shared/services/date-time.service';
import { groupBy } from 'lodash-es';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  constructor(private dateTime: DateTimeService) {}

  addDataToWizardTab(tabs: WizardTabs, tabId: number, fieldId: string, options: any[]) {
    const tab = tabs.find(tab => tab.id === tabId);
    if (tab) {
      const updatedTabFields = tab.fields.map(field => {
        return {
          ...field,
          options: field.id === fieldId ? (options as Options) : field.options
        };
      });
      return tabs.map(tab => {
        return {
          ...tab,
          fields: tab.id === tabId ? updatedTabFields : tab.fields
        };
      });
    }
    return tabs;
  }

  checkTabAlreadyLoaded(tabs: WizardTabs, tabId: number) {
    return !tabs.find(tab => tab.id === tabId && tab.fields.some(field => field.loadOptions && !field.options));
  }

  addSubWizardTabsToWizardTab(tabs: WizardTabs, tabId: number, fieldId: string, subWizardTabs: WizardTabs) {
    const tab = tabs.find(tab => tab.id === tabId);
    if (tab) {
      const updatedTabFields = tab.fields.map<WizardField>(field => {
        return {
          ...field,
          subWizardConfig: {
            ...field.subWizardConfig,
            subWizardTabs: field.id === fieldId ? subWizardTabs : field.subWizardConfig?.subWizardTabs
          }
        };
      });
      return tabs.map(tab => {
        return {
          ...tab,
          fields: tab.id === tabId ? updatedTabFields : tab.fields
        };
      });
    }
    return tabs;
  }

  getAllFields(tabs: WizardTabs, withIds?: boolean): WizardFields {
    let fields: WizardFields = [];
    tabs.forEach(tab => (fields = [...fields, ...tab.fields]));
    if (withIds) {
      return fields.filter(field => field.id !== undefined);
    }
    return fields;
  }

  applySecondaryFields(field: WizardField, sourceData: any, targetData: any) {
    if (!field.type?.secondaryKeys || !field.secondaryIds) {
      return targetData;
    }
    field.type?.secondaryKeys.forEach((key, index) => {
      const fieldSecondaryId = field.secondaryIds && field.secondaryIds[index];
      if (fieldSecondaryId) {
        if (field.type?.reverseSecondaryKeys) {
          targetData[key] = sourceData[fieldSecondaryId];
        } else {
          targetData[fieldSecondaryId] = sourceData[key];
        }
      }
    });
    if (field.id) {
      delete targetData[field.id];
    }
    return targetData;
  }

  unmatchedRequirement(req: FieldRule, wizardData: any) {
    // Used in two functions below
    const property = req.property;
    let dataValue: any;
    if (property.indexOf(CommonChars.Hyphen) > -1) {
      const propertyArray = property.split(CommonChars.Hyphen);
      if (propertyArray.length > 1 && wizardData[propertyArray[0]]) {
        dataValue = wizardData[propertyArray[0]][propertyArray[1]];
      }
    } else {
      dataValue = wizardData[property];
    }

    let valuesMismatch;
    const checkValueMismatch = value => !req.values.includes(value);

    if (!req.values) {
      valuesMismatch = !dataValue;
    } else if (Array.isArray(dataValue)) {
      valuesMismatch = dataValue.every(item => checkValueMismatch(item?.id));
    } else {
      valuesMismatch = checkValueMismatch(dataValue);
    }

    let minLengthMismatch = false;
    let maxLengthMismatch = false;

    if (req.minLength !== undefined) {
      // minLength: Array of > x or non-array which is truthy
      minLengthMismatch =
        (Array.isArray(dataValue) && dataValue.length < req.minLength) || (!Array.isArray(dataValue) && dataValue);
    }
    if (req.maxLength !== undefined) {
      // maxLength: Array of < x
      maxLengthMismatch = !Array.isArray(dataValue) || dataValue.length > req.maxLength;
    }

    return valuesMismatch || maxLengthMismatch || minLengthMismatch;
  }

  requirementsMatched(requirements: FieldRules, wizardData: any) {
    return requirements.every(req => !this.unmatchedRequirement(req, wizardData));
  }

  showField(field: WizardField, wizardData: any) {
    if (!field) {
      return false;
    }
    if (field.isHidden) {
      return false;
    }
    // Show a field if requirements to show it are matched
    const displayMatched = !field.displayIf || this.requirementsMatched(field.displayIf, wizardData);
    const hiddenUnmatched = !field.hideIf || !this.requirementsMatched(field.hideIf, wizardData);
    return displayMatched && hiddenUnmatched;
  }

  enableField(field: WizardField, wizardData: any) {
    // Enable a field if requirements to show it are matched

    if (field.isDisabled) {
      return false;
    }
    if (!field.enableIf) {
      return true;
    }
    const unmatched = field.enableIf.filter(req => {
      return this.unmatchedRequirement(req, wizardData);
    }).length;
    return unmatched === 0;
  }

  formatData(data: any, tabs: WizardTabs) {
    // Format data for post requests
    // Deep clone the data so we can work on a copy.
    let exportData = JSON.parse(JSON.stringify(data));
    const allFields = this.getAllFields(tabs, true);
    allFields.forEach((field: WizardField) => {
      if (field.id && field.type) {
        const value = exportData[field.id];
        // Delete any hidden fields
        if (!this.showField(field, data) || field.isToggledOn === false || field.type === formElements.GameSummary) {
          delete exportData[field.id];
          return;
        }

        if (field.id === EventFieldIds.NewGroupStaffList) {
          exportData[field.id] = data[field.id];
        }

        if (field.type.mappingFunc) {
          exportData[field.id] = field.type.mappingFunc(value);
        }

        // Apply related fields
        if (field.secondaryIds) {
          exportData = this.applySecondaryFields(field, value, exportData);
        }

        // Turn select option id or numeric input into number
        if (field.type.isSelect || field.type.typeLabel == 'number') {
          const numberValue = !Array.isArray(value) && parseInt(value);
          if (numberValue) {
            exportData[field.id] = numberValue;
          }
        }

        // Get date from single date in daterangepicker
        if (field.type.isSingleDate) {
          exportData[field.id] = value.startDate;
        }

        // Convert time to dateTime
        if (field.type === formElements.Time) {
          const dayProviderId = field.dayProvider;
          if (dayProviderId) {
            const date = exportData[dayProviderId];
            const time = value;
            if (date && time) {
              exportData[field.id] = this.dateTime.combineDateAndTime(new Date(date), time);
            }
          }
        }

        if ((field.type === formElements.Text || field.type === formElements.Content) && exportData[field.id]) {
          exportData[field.id] = exportData[field.id].toString();
        }

        // Remove formatting from text if required
        if (field.type === formElements.Content && field.isPlainText) {
          const formattedText = exportData[field.id].replace(/<\/?[^>]+(>|$)/g, CommonChars.Blank);
          exportData[field.id] = formattedText;
        }

        if (field.type === formElements.CheckboxList) {
          if (!Array.isArray(value)) return;

          delete exportData[field.id];

          value.forEach((item: string) => {
            exportData[item] = true;
          });
        }

        if (field.id.indexOf(CommonChars.Hyphen) > -1) {
          const fieldIdArray = field.id.split(CommonChars.Hyphen);
          if (!exportData[fieldIdArray[0]]) {
            exportData[fieldIdArray[0]] = {};
          }

          exportData[fieldIdArray[0]][fieldIdArray[1]] = exportData[field.id];
          delete exportData[field.id];
        }

        if (field.nestedField) {
          this.setNestedField(exportData, field);
        }
      }
    });

    return exportData;
  }

  setNestedField(data: any, field: WizardField): void {
    const value = data[field.id];
    let currentProperty: any = data;

    for (let i = 0; i < field.nestedField.length; i++) {
      const isLastNestedField: boolean = i + 1 === field.nestedField.length;
      if (isLastNestedField) {
        currentProperty[field.nestedField[i]] = value;
      } else {
        currentProperty[field.nestedField[i]] ??= {};
        currentProperty = currentProperty[field.nestedField[i]];
      }
    }

    delete data[field.id];
  }

  addDataToNewTabs(
    tabs: WizardTabs,
    tabId: number,
    isUpdate?: boolean
  ): Observable<{ tabs: WizardTabs; alreadyLoaded: boolean }> {
    const alreadyLoaded = !isUpdate && this.checkTabAlreadyLoaded(tabs, tabId);
    if (alreadyLoaded) {
      return of({ tabs, alreadyLoaded });
    }

    const activeTab: WizardTab = tabs.find(tab => tab.id === tabId);

    const groupedFieldsWithOptionsConfig: WizardField[][] = Object.values(
      groupBy(
        activeTab.fields.filter(field => field.optionsConfig && !field.isHidden),
        field => field.optionsConfig.type
      )
    );

    activeTab.fields
      .filter(field => field.subWizardConfig && !field.isHidden)
      .forEach(
        field =>
          (tabs = this.addSubWizardTabsToWizardTab(tabs, tabId, field.id, field.subWizardConfig.getSubWizardTabsFn()))
      );

    return forkJoin([...groupedFieldsWithOptionsConfig.map(fields => fields[0].optionsConfig.getOptionsFn())]).pipe(
      map(results => {
        for (let i = 0; i < groupedFieldsWithOptionsConfig.length; i++) {
          for (const field of groupedFieldsWithOptionsConfig[i]) {
            tabs = this.addDataToWizardTab(tabs, tabId, field.id, results[i]);
          }
        }

        return {
          tabs,
          alreadyLoaded
        };
      })
    );
  }

  onSubWizardSave(
    subWizardSave: SubWizardSave,
    wizardTabs: WizardTabs,
    wizardData: any
  ): Observable<SubWizardSaveEvent> {
    const data = subWizardSave.data;

    const activeTab: WizardTab = wizardTabs.find(tab => tab.id === subWizardSave.activeTabId);
    const saveField: WizardField = activeTab?.fields.find(field => field.id === subWizardSave.fieldId);

    if (saveField?.subWizardConfig?.saveFn && saveField?.subWizardConfig?.getSaveResponseValueFn) {
      return saveField.subWizardConfig.saveFn(data).pipe(
        switchMap(saveRes => {
          if (saveRes) {
            if (saveField.optionsConfig) {
              const fieldsWithOptionsType: WizardFields = activeTab.fields.filter(
                field => field.optionsConfig?.type === saveField.optionsConfig.type
              );

              return saveField.optionsConfig.getOptionsFn(true).pipe(
                map(options => {
                  const updatedData = { ...wizardData };
                  updatedData[subWizardSave.fieldId] = saveField.subWizardConfig.getSaveResponseValueFn(saveRes);

                  for (const field of fieldsWithOptionsType) {
                    wizardTabs = this.addDataToWizardTab(wizardTabs, subWizardSave.activeTabId, field.id, options);
                  }

                  return {
                    data: updatedData,
                    tabs: wizardTabs
                  };
                })
              );
            }
          }

          // If save not successful, httpWebApi service will handle error
          // Return tabs and data as they were passed in
          return of({
            data: data,
            tabs: wizardTabs
          });
        })
      );
    }

    return of({
      data: data,
      tabs: wizardTabs
    });
  }
}
