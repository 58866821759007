import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MomentOrDate, momentToDate } from '@sb-helpers';
import { DateRangeList, SearchFilterType, searchFilterFormats } from '@sb-shared/constants/shared.constant';
import { FileTypes } from '@sb-shared/globals/file-types';
import { Buttons } from '@sb-shared/models/UI/buttons';
import { Confirmation } from '@sb-shared/models/UI/confirmation';
import { FilterGroup, FilterGroups, Option, Options } from '@sb-shared/models/UI/filter';
import { MenuItems } from '@sb-shared/models/UI/menu-item';
import {
  MatchedIcons,
  TableClickEvent,
  TableColumn,
  TableColumns,
  TableUpdateEvent,
  tableProperties
} from '@sb-shared/models/UI/table';
import { DataTableChangeFiltersEvent } from '@sb-shared/models/events/data-table-change-filters';
import { Ng1DatePickerChange } from '@sb-shared/models/events/ng1-date-range-picker-change';
import { ArrayService } from '@sb-shared/services/array.service';
import { BlobStoragePhotoService } from '@sb-shared/services/blob-storage-photo.service';
import { ColoursService } from '@sb-shared/services/colours.service';
import { ConfirmDialogService } from '@sb-shared/services/confirm-dialog.service';
import { DataTableService } from '@sb-shared/services/data-table.service';
import { DateTimeService } from '@sb-shared/services/date-time.service';
import { NavigatorService } from '@sb-shared/services/navigator.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { DataTableExportSettings } from '@sb-shared/types/data-table-export';
import { UiClasses } from '@sb-shared/types/ui-classes';
import type { Duration } from 'date-fns';
import { isAfter, sub } from 'date-fns';
import { EChartsOption } from 'echarts';
import { DiaryEvent } from 'src/app/diary/models/diary-event';

@Component({
  selector: 'sb-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges {
  // Search/filter

  // Type of search, such as TextSearch or DateRange
  @Input() searchFilterType: SearchFilterType;
  // List of date ranges to pick from
  @Input() dateRangeList: DateRangeList;
  // Layout to show search/filters in one area
  @Input() isFilterSingleArea: boolean;
  // Hide 'reset' button for filters
  @Input() isFilterHideReset: boolean;
  // Layout to show filter in left column
  @Input() isFilterLeftColumn: boolean;
  // Show filters by default, without clicking on 'Filter' button
  @Input() showFilters: boolean;
  // Page size select options
  @Input() pageSize: number;
  // Event for when page size is changes
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();
  // Disabled paginations
  @Input() isPaginationDisabled: boolean;
  // Hide search filter when an item is selected
  @Input() hideSearchFilterOnSelected: boolean;
  // Label for search/filter
  @Input() searchFilterLabel: string;
  // Start date of date range
  @Input() set startDate(value: MomentOrDate) {
    this._startDate = momentToDate(value);
  }
  get startDate(): Date {
    return this._startDate;
  }
  // End date of date range
  @Input() set endDate(value: MomentOrDate) {
    this._endDate = momentToDate(value);
  }
  get endDate(): Date {
    return this._endDate;
  }

  defaultPageSize: number;
  filterGroups: FilterGroups;
  searchFilters: { textFilter?; textTerms? } = {};
  summaries: Options;
  private _startDate: Date;
  private _endDate: Date;

  // Master

  // Title of table
  @Input() title: string;
  // Table columns for table
  @Input() tableColumns: TableColumns;
  // Data passed into table
  @Input() tableData: unknown[];
  // Message to show when table is empty
  @Input() emptyMessage: string;
  // Error state as deifned by parent
  @Input() isError: boolean;
  // Message to show on error
  @Input() errorMessage: string;
  // Search text as defined by parent
  @Input() searchText: string;
  // Sort columns by default - don't require isSortable to be true
  @Input() sortByDefault: boolean;
  // Filter columns by default - don't require isFilterable to be true - not currently applied
  @Input() filterByDefault: boolean;
  // Total numeric columns by default - don't require showTotal to be true
  @Input() totalByDefault: boolean;
  // Search using columns by default - don't require isSearchable to be true - not currently applied
  @Input() searchByDefault: boolean;
  // Reload when column filter changed by default - don't require doTriggerReload to be true - not currently applied
  @Input() reloadByDefault: boolean;
  // Can row be clicked
  @Input() isRowClickable: boolean;
  // Is table cell click disabled
  @Input() isClickDisabled: boolean;
  @Input() disabledColumnIds: string[];
  // Processing state as defined by parent
  @Input() isParentProcessing: boolean;
  // Width of table element
  @Input() tableWidth: number;
  // Default icon class
  @Input() iconClass: string;
  // Tooltip when hovering over a row
  @Input() rowTooltip: string;
  // Info string to show above table
  @Input() tableInfo: string;
  // Label for button to show detail on small devices - not used
  @Input() viewXsDetailViewLabel: string;
  // Current page in pagination
  @Input() currentPage: number;
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>();
  // Row count as defined by parent - usually from api repsonse
  @Input() rowCount: number;
  // Is loading data state as defined by parent
  @Input() isLoadingData: boolean;
  // Show data on the right
  @Input() isDataOnRight: boolean;
  // Row to show as in loading state - eg when clicking a user on users page, the avatar shows spinner
  @Input() loadingRecordId: number;
  // Can export data
  @Input() isExport: boolean;
  // Settings for exporting data
  @Input() exportSettings: DataTableExportSettings;
  // Note to show by table
  @Input() note: string;
  // Column Id to use for the row key (defaults to "id")
  @Input() rowKey: string;

  // Event handlers
  @Output() onClick: EventEmitter<TableClickEvent> = new EventEmitter<TableClickEvent>();
  @Output() onChangeFilters: EventEmitter<DataTableChangeFiltersEvent> =
    new EventEmitter<DataTableChangeFiltersEvent>();
  @Output() onDateRangeChange: EventEmitter<Ng1DatePickerChange> = new EventEmitter<Ng1DatePickerChange>();
  @Output() onReady: EventEmitter<void> = new EventEmitter<void>();
  // Calls event when list of selected items changes
  @Output() selectedIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() onRequestUpdateValue: EventEmitter<TableUpdateEvent> = new EventEmitter<TableUpdateEvent>();

  filterableTableColumns: TableColumns;
  filteredTableData: unknown[];
  formattedTableData: unknown[] = [];
  tableId: number;
  sortType: string;
  sortReverse = false;
  cellTypes = tableProperties.CellTypes;
  specialTypes = tableProperties.SpecialColumns;
  currencyDisplaySymbol: string;
  tables = [];
  groupByColumn: TableColumn;
  hideReset: boolean;
  paginationMaxPages: number;
  isPaginated: boolean;
  isProcessing: boolean;
  isAllSelected: boolean;
  selectedIds: number[] = [];
  rowStateColumnId: string;
  confirmationConfig: Confirmation = {
    text: 'Change filters? Selections will be reset.',
    title: 'Filter change',
    okButtonText: 'SB_Yes',
    cancelButtonText: 'SB_No'
  };
  chart: { title: string; option: EChartsOption };
  exportButtons: Buttons = FileTypes.map(type => {
    return {
      ...type,
      message: type.label,
      iconClasses: type.iconClass
    };
  });
  isExporting: boolean;
  filterableSelectorTableColumns: TableColumns = [];
  filterableCheckBoxListTableColumns: TableColumns = [];
  filterableCheckBoxTableColumns: TableColumns = [];
  filterableDateRangeTableColumns: TableColumns = [];
  totalLabels: string[] = []; // cache of total labels
  customDateOptions: { autoApply: boolean; singleDatePicker: boolean; alwaysShowCalendars?: boolean };
  photoUrlsRequested: boolean = false;

  @ViewChild('tableContainer', { static: false }) tableContainer: ElementRef;
  @ViewChild('tableAccordion', { static: false }) tableAccordion: NgbAccordion;
  @ViewChild('filterAccordion', { static: false }) filterAccordion: NgbAccordion;
  @ViewChild('chartModal') chartModal: NgbModal;

  // Detail

  // Default value for master/detail detail area
  @Input() defaultValue = null;
  // Selected value for master/detail detail area
  @Input() selectedValue;
  @Output() selectedValueChange: EventEmitter<unknown> = new EventEmitter<unknown>();
  // Placeholder text when no item is yet selected
  @Input() placeholderText: string;
  // Placeholder icon when no item is yet selected
  @Input() placeholderIcon: string;
  // Text to return to master and unselect item
  @Input() backText: string;
  // Whether route changes when an item is selected
  @Input() isLocationChange: boolean;
  // Whether an item is currently being loaded
  @Input() isLoadingValue: boolean;
  // Whether detail tabs within a selected item have separate routes
  @Input() isRouted: boolean;
  // Menu items to show as detail tabs
  @Input() menuItems: MenuItems;
  // Width of  master/detail detail area
  @Input() contentWidth: number;
  // Background url for detail card
  @Input() cardBackgroundUrl: string;
  // Content element
  @ViewChild('contentRef', { static: false }) contentRef: ElementRef;

  // Banner - used when selecting items so an action can be performed

  // Message in banner button
  @Input() bannerButtonMessage: string;
  // Confirmation message after pressing banner button
  @Input() bannerConfirmationMessage: string;
  // Confirmation message
  @Input() bannerConfirmButtonMessage: string;
  // Confirmation message UI class
  @Input() bannerMessageClass: UiClasses;
  @Input() bannerButtonIsSubmitted: boolean;
  // Confirmation button click event
  @Output() onBannerButtonClick: EventEmitter<void> = new EventEmitter<void>();
  digitsInfo: string;

  constructor(
    private array: ArrayService,
    private navigator: NavigatorService,
    private organisation: OrganisationService,
    private blobStoragePhoto: BlobStoragePhotoService,
    private confirmDialog: ConfirmDialogService,
    private dateTime: DateTimeService,
    private changeRef: ChangeDetectorRef,
    private modal: NgbModal,
    private translate: TranslateService,
    private colour: ColoursService,
    private dataTable: DataTableService
  ) {}

  ngOnInit(): void {
    this.isProcessing = true;
    this.tableId = Math.floor(Math.random() * 100);
    this.selectedValue = this.selectedValue || this.defaultValue;
    this.organisation.getCurrentOrganisation().subscribe(organisation => {
      this.isProcessing = false;
      this.defaultPageSize = 20;
      this.pageSize = this.pageSize || this.defaultPageSize;
      this.pageSizeChange.emit(this.pageSize);
      this.paginationMaxPages = 15;
      this.currencyDisplaySymbol = organisation.currencyDisplaySymbol;
      this.currentPage = 1;
      this.onReady.emit();
    });

    this.organisation.getCurrentOrganisation().subscribe(org => {
      this.digitsInfo = `1.${org.currencyExponent}-${org.currencyExponent}`;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableColumns?.currentValue) {
      // Handle columns
      this.sortType =
        this.sortType || (this.tableColumns.find((item: TableColumn) => item.isDefaultSort) || this.tableColumns[0]).id;
      this.sortReverse = this.sortReverse || false;
      this.rowStateColumnId = this.tableColumns.find((item: TableColumn) => item.isRowState)?.id;
      this.hideReset =
        (this.reloadByDefault && !this.tableColumns.find(column => column.doTriggerReload !== false)) ||
        !this.tableColumns.find(column => !column.doTriggerReload);
    }
    if (
      (changes.tableColumns?.currentValue || changes.tableData?.currentValue) &&
      this.tableColumns &&
      this.tableData
    ) {
      const tableDataChanged: boolean = changes.tableData?.currentValue ? true : false;
      if (tableDataChanged) {
        this.photoUrlsRequested = false;
      }
      this.createFilters(tableDataChanged);
      this.resetFormattedData();
      this.resetTotalLabels();

      this.groupByColumn = this.tableColumns.find((column: TableColumn) => column.isGroupedBy);
      this.tables = this.groupByColumn?.options?.filter(option => option.id) || [{}];

      this.tableColumns.forEach(column => {
        if (column.isTrueGroupedBy) {
          this.tables.unshift({
            name: column.name,
            iconName: column.iconName,
            iconClass: column.iconClass,
            id: column.id
          });
        }
      });
      if (this.tables.length === 1) {
        this.tableAccordion?.expandAll();
      }
      // this.filterVisibleColumns();
    }
    if (changes?.tableData?.currentValue) {
      this.selectedIds = [];
      // Add row id as needed is some cases
      this.tableData = this.tableData.map((row: { id; personId; calendarEventId }) => {
        return {
          ...row,
          id: (row.id = row[this.rowKey || 'id'] || row.personId || row.calendarEventId)
        };
      });
      // Preselect selections
      this.selectedIds =
        this.selectedIds.length > 0
          ? this.selectedIds
          : this.tableData
              .filter((row: { isSelected: boolean }) => row.isSelected)
              .map(row => row[this.rowKey || 'id']);
      this.onChangeSelectedIds();
    }
    if (changes.rowCount?.currentValue || changes.tableData?.currentValue) {
      this.isPaginated = this.rowCount > this.pageSize;
    }
    if (changes.selectedValue) {
      // const value = this.selectedValue;
      // if (!value?.background && typeof value === 'object') {
      //   this.image.getPhotoBackground(this.selectedValue.title).subscribe(imgUrl => {
      //     this.selectedValue.background = imgUrl;
      //   })
      // }
    }
    // if (this.tableColumns && !this.searchFilters && !this.tableData) {
    //   this.searchFilters = {};
    //   this.onChangeFilters.emit({
    //     searchFilters: this.dataTable.getInitialSearchParams(this.tableColumns),
    //     doTriggerReload: this.tableColumns.some(col => col.doTriggerReload)
    //   });
    // }
  }

  visibleTableColumns() {
    if (!this.tableColumns) {
      return [];
    }
    return this.tableColumns.filter(column => {
      return (
        !column.isHidden &&
        (!column.isHiddenIfFalse || this.searchFilters[column.id]) &&
        column.id !== this.groupByColumn?.id &&
        (!this.selectedValue || column.keepOpen || this.placeholderText)
      );
    });
  }

  showTotalRow() {
    return (
      this.totalByDefault ||
      this.visibleTableColumns().some(column => {
        return column.showTotal ?? false;
      })
    );
  }

  resetTotalLabels() {
    this.totalLabels = [];
  }

  resetFormattedData() {
    this.filterTableData();
    const filteredLength = this.filteredTableData?.length;
    if (filteredLength > 0) {
      this.formattedTableData = new Array(Math.floor(filteredLength / (this.pageSize || 1))) as unknown[];
      if (!this.rowCount) {
        this.currentPage = 1;
      }
      this.formatTableData();
    } else {
      this.formattedTableData = [];
    }
  }

  isColumnDisabled(columnId: string): boolean {
    return this.disabledColumnIds?.some(id => id === columnId);
  }

  filterTableData(tableId?): void {
    let filteredData;
    if (!this.filterableTableColumns) {
      filteredData = this.tableData;
    } else {
      filteredData = this.tableData?.filter(row => {
        // Check column-based filters
        let columnMatch = false;
        if (this.filterGroups) {
          if (this.filterGroups.every(group => group.filteredCount === 0)) {
            return false;
          }
          columnMatch = this.filterGroups.some(group => {
            return this.groupRowMatch(row, group);
          });
        } else {
          columnMatch =
            this.filterableTableColumns.length === 0 ||
            this.filterableTableColumns?.every(th => {
              if (th.doTriggerReload) {
                // No front end filter needed for backend-filtered column
                return true;
              }
              const thId = th.id;

              if (th.cellType == this.cellTypes.DateRange && row[thId]) {
                let date = new Date(row[thId]);
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                const startDate = new Date(th.startDate);
                return (
                  date >= new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) &&
                  date <= th.endDate
                );
              }
              const thFilter = this.searchFilters[thId];
              if (!thFilter || thFilter == -1) {
                return true;
              }
              const rowThId = row[thId];
              const cellValue = rowThId && typeof rowThId === 'object' ? rowThId.id : rowThId;
              if (th.isInclude !== undefined) {
                if (th.isInclude) {
                  return thFilter || !row[thId] || row[thId]?.value === false;
                }
                return !thFilter || row[thId];
              }
              if (th.isMultiOption) {
                if (thFilter.length === th.options.length) return true;
                if (th.items) {
                  // For list of items, check at least one match to row column with corresponding id
                  return thFilter.find(item => row[item.id]);
                }

                // If the column is array, check at least one match to the selection
                if (Array.isArray(rowThId)) {
                  return rowThId.some(value => thFilter.some(filter => value === filter.id || value.id === filter.id));
                }

                const value = row[thId]?.id || row[thId];
                return thFilter.find(option => option.id === value);
              } else if (th.items) {
                return row[thFilter];
              }
              return cellValue == thFilter;
            });
        }
        // Check main filter
        let mainMatch;
        if (!this.searchFilterType) {
          mainMatch = true;
        } else {
          switch (this.searchFilterType?.format) {
            case searchFilterFormats.Text:
              mainMatch =
                !(this.searchFilters.textFilter?.length > 0) ||
                this.tableColumns?.some((col: TableColumn) => {
                  if (!col.isSearchable) return false;
                  const cellValue = this.getCellValue(row[col.id])?.toLowerCase();
                  return cellValue && this.searchFilters.textTerms.every(term => cellValue.indexOf(term) > -1);
                });
              break;
            // Add other main search checks as needed
            default:
              mainMatch = true;
          }
        }
        const cellGroupById = row[this.groupByColumn?.id]?.id || row[this.groupByColumn?.id];
        const trueGroupMatch = typeof tableId === 'string' && row[tableId]?.value;
        // Check match to specific table
        const tableMatch = !this.groupByColumn || !tableId || cellGroupById === tableId || trueGroupMatch;

        return columnMatch && mainMatch && tableMatch;
      });
    }
    if (!this.sortType) {
      this.filteredTableData = filteredData;
    } else {
      let sortedData = filteredData.sort((a, b) => {
        if (a[this.sortType] < b[this.sortType]) {
          return -1;
        } else if (a[this.sortType] > b[this.sortType]) {
          return 1;
        } else {
          return 0;
        }
      });
      if (!this.isPaginationDisabled) {
        sortedData = sortedData.slice((this.currentPage - 1) * this.pageSize);
      }
      this.filteredTableData = this.sortReverse ? sortedData.reverse() : sortedData;
    }
  }

  formatTableData() {
    const filteredData = this.filteredTableData;
    // Format data for a page
    if (filteredData) {
      const pageData = this.groupByColumn ? filteredData : filteredData.slice(this.getPageStart(), this.getPageEnd());
      // if (!this.formattedTableData || pageData.length === 0) {
      //   this.isError = true;
      // }
      this.isLoadingData = true;
      const addFormattedData = array => {
        // Handle data
        const formattedPageData = array.map(row => {
          if (!row) {
            return {};
          }
          const rowEntries = Object.entries(row).map((rowEntry: unknown) => {
            const key = rowEntry[0];
            const column = this.tableColumns.find(column => column.id === key);
            // Skip any columns not defined as table headers
            if (!column) return rowEntry;
            let value = rowEntry[1];
            let cellValue = value;
            // Convert false value into blank label unless 0
            if (value === undefined || value === null) return { label: '' };
            // We should not show 'true' text. If no icon, default to check mark.
            if (column.cellType === this.cellTypes.Badge && typeof value === 'number') {
              const badgeValue = column.options.find(option => option.id === value);
              cellValue = {
                label: badgeValue?.name,
                class: badgeValue?.class,
                iconName: badgeValue?.iconName
              };
            }
            if (value === true && !column.isSpecial) {
              cellValue = {
                label: ' ',
                iconName: value.icon || column.iconName || 'tick',
                cellClass: 'text-success'
              };
            }
            if (value === false && !column.isSpecial) {
              cellValue = {
                label: ' ',
                iconName: value.icon || column.iconName ? '' : 'times',
                cellClass: 'text-danger'
              };
            }
            if (value === false && column.showFalse) {
              value = {
                label: ' ',
                iconName: 'times',
                cellClass: 'text-danger'
              };
            }
            if (column.dateFormat) {
              cellValue = {
                id: value,
                label: this.dateTime.formatDate(value, column.dateFormat)
              };
            }
            if (column.isCopy) {
              cellValue = {
                label: value,
                iconName: 'copy',
                iconTooltip: 'SB_Copy_To_Clipboard'
              };
            }
            // Handle styling for non-currency number
            if (typeof value === 'number' && column.cellType !== this.cellTypes.Currency && !column.options) {
              return [
                key,
                Object.assign(value, {
                  id: value,
                  label: value,
                  value: value,
                  cellClass: 'semi-bold text-primary'
                })
              ];
            }
            return [
              key,
              Object.assign(cellValue, {
                label: this.getCellValue(cellValue || value),
                // For filter selects - get option id from data or use label as id
                id: value?.id || this.getCellValue(value),
                value: value,
                cellClass: value.cellClass || value.iconName ? 'text-muted' : '',
                iconName: value.iconName ?? cellValue.iconName,
                tooltip: value.tooltip
              })
            ];
          });
          return Object.fromEntries(rowEntries as [string, unknown][]);
        });

        this.formattedTableData[this.currentPage] = [...formattedPageData] as unknown;
        this.isLoadingData = false;
      };
      if (!this.photoUrlsRequested && this.tableColumns.some(column => column.id === this.specialTypes.avatar.id)) {
        this.photoUrlsRequested = true; // don't request the photo Urls if it has already been done for the current data
        this.blobStoragePhoto.addStudentPhotoUrlsToArray(pageData as DiaryEvent[]).subscribe(pageDataWithPhotos => {
          addFormattedData(pageDataWithPhotos);
        });
      } else {
        addFormattedData(pageData);
      }
    }
  }

  resetFilters() {
    const reset = () => {
      this.currentPage = 1;
      this.createFilters();
      this.searchFilters.textFilter = '';
      const shouldReload = this.tableColumns.some(column => column.doTriggerReload) || this.reloadByDefault;
      this.changeFilters(shouldReload);
    };
    if (this.hasSelectionAction()) {
      return this.confirmDialog.confirmThis(
        this.confirmationConfig,
        () => reset(),
        () => {
          // Retain selections
        }
      );
    } else {
      reset();
    }
  }

  hasSelectionAction() {
    return this.selectedIds.length > 0 && this.bannerButtonMessage;
  }

  onClickReset() {
    this.searchFilters = {};
    this.resetFilters();
  }

  changeFilters(doTriggerReload: boolean) {
    if (!this.formattedTableData[1]) {
      this.formatTableData();
    }
    const searchFilters = { ...this.searchFilters };
    this.currentPage = 1;
    // this.filterVisibleColumns();
    if (this.searchFilters.textFilter?.length > 0) {
      this.searchFilters.textTerms = this.searchFilters.textFilter.toLowerCase().trim().split(' ');
    }

    if (!doTriggerReload) {
      // Only reset if no reload on the way.
      this.resetFormattedData();
      this.resetTotalLabels();
    }
    const filteredData = this.filteredTableData;
    this.onChangeSelectedIds();
    this.onChangeFilters.emit({
      searchFilters: searchFilters,
      searchFilterGroups: this.filterGroups,
      doTriggerReload: doTriggerReload,
      filteredData: filteredData
    });
  }

  dateRangeChange(event: Ng1DatePickerChange) {
    this.onDateRangeChange.emit(event);
  }

  onChangeSelectedIds() {
    this.selectedIdsChange.emit(this.selectedIds);
  }

  getCellValue(cell) {
    if (typeof cell === 'string') {
      return cell;
    }
    if (typeof cell === 'boolean') {
      return ' ';
    }
    return cell?.label || cell?.name;
  }

  getCellClass(col: TableColumn, td?): string {
    let cellClass = '';
    if (col?.cellClass) {
      cellClass += ` ${col.cellClass}`;
    }
    if (td?.cellClass) {
      cellClass += ` ${col.cellClass}`;
    }
    if (col.id === this.specialTypes.avatar.id) {
      cellClass += ' avatar-cell';
    }
    return cellClass;
  }

  createFilters(tableDataChanged = false) {
    this.tableColumns = this.tableColumns.map(item => {
      const th = item;
      const id = item.id;
      let thOptions;
      if ((th.isFilterable || th.options || th.items) && th.isInclude === undefined) {
        // Generate select options
        const options =
          tableDataChanged && th.isGroupedBy
            ? this.buildFilterOptions(id)
            : th.options || th.items || this.buildFilterOptions(id);

        const uniqueOptionsArray = this.array.uniqueBy(options, option => {
          return option.id;
        });
        thOptions = this.array
          .generateOptions(
            uniqueOptionsArray,
            !th.disableAllOption && !th.isCheckboxList && !th.isMultiOption,
            th.allLabel
          )
          .filter(option => option.id)
          .map(option => {
            return {
              ...option,
              count: option.id === -1 ? this.tableData.length : this.optionDataCount(this.tableData, th, option),
              class: option.class || option.iconClass
            };
          });
      }
      return {
        ...th,
        id: id,
        isSortable: th.isSortable || (this.sortByDefault && th.isSortable !== false),
        isSearchable: th.isSearchable || (this.searchByDefault && th.isSearchable !== false),
        options: thOptions?.map(option => {
          return {
            ...option,
            name: option.name ? this.translate.instant(option.name) : ''
          };
        })
      };
    });
    this.filterableTableColumns = this.tableColumns.filter(th => th.isFilterable);
    this.filterableSelectorTableColumns = this.filterableTableColumns.filter(
      th => th.options && !th.isCheckboxList && th.cellType != this.cellTypes.DateRange
    );
    this.filterableDateRangeTableColumns = this.filterableTableColumns.filter(
      th => th.cellType == this.cellTypes.DateRange
    );
    this.filterableCheckBoxListTableColumns = this.filterableTableColumns.filter(th => th.options && th.isCheckboxList);
    this.filterableCheckBoxTableColumns = this.filterableTableColumns.filter(th => th.isInclude !== undefined);
    this.filterableTableColumns.forEach(th => {
      if (th.doTriggerReload) {
        // If back end filter, leave as is
        return;
      }
      if (th.isMultiOption) {
        this.searchFilters[th.id] = [...th.options];
        return;
      }

      if (this.searchFilters[th.id] !== null && this.searchFilters[th.id] !== undefined) {
        return;
      }

      if (th.isInclude !== undefined) {
        this.searchFilters[th.id] = th.isInclude;
      } else if (th.options && th.options[0]) {
        this.searchFilters[th.id] = th.options[0].id;
      }
      if (th.defaultValue && th.options.find(option => option.id === th.defaultValue)) {
        this.searchFilters[th.id] = th.defaultValue;
      }
    });
    this.summaries = this.tableColumns.find(column => column.id === this.rowStateColumnId)?.options;
    const filterGroupColumn = this.tableColumns.find(column => {
      return column.isFilterGrouping;
    });
    const filterGroups = filterGroupColumn?.options?.map(option => {
      return { ...option, id: option.id.toString() };
    });
    if (filterGroups) {
      this.filterGroups = filterGroups.filter(group => group?.id !== '-1');
      this.filterGroups.forEach(group => {
        const groupMatchData = this.tableData.filter(row => {
          if (row[group.id] !== undefined) {
            return row[group.id];
          }
          return row[filterGroupColumn.id] === group.id;
        });
        group.rows = groupMatchData;
        group.count = groupMatchData.length;
        group.filteredCount = 0;
        group.isExpanded = false;
        group.filterItems = this.filterableTableColumns
          .filter(column => {
            return (
              !column.doTriggerReload &&
              column.id !== filterGroupColumn.id &&
              (!column.displayIf ||
                column.displayIf.every(condition => {
                  return condition.property === filterGroupColumn.id && condition.values.includes(group.id);
                }))
            );
          })
          .sort((a, b) => b.groupSortOrder - a.groupSortOrder)
          .map(filter => {
            return {
              ...filter,
              options: filter.options
                ?.map(option => {
                  return {
                    ...option,
                    count: this.optionDataCount(groupMatchData, filter, option),
                    isSelected: false
                  };
                })
                .filter(option => option?.count > 0),
              isHidden: filter.otherFilterRequirements?.length > 0
            };
          });
      });
    } else {
      this.filterGroups = null;
    }
    this.filterAccordion?.collapseAll();
  }

  buildFilterOptions(thId: number | string) {
    return this.tableData
      .map(row => {
        const cell = row[thId];
        if (Array.isArray(cell)) {
          const options = cell.map(item => {
            const itemValue = this.getCellValue(item);
            return {
              name: itemValue || item?.iconTooltip || '',
              id: item?.id || itemValue,
              iconName: item.iconName
            };
          });

          return options;
        }
        const cellValue = this.getCellValue(cell);
        return [
          {
            name: cellValue || cell?.iconTooltip || '',
            id: cell?.id || cellValue,
            iconName: cell?.iconName
          }
        ];
      })
      .flatMap(item => item)
      .filter(item => item.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  showOptionCount(column: TableColumn, option: Option): boolean {
    return option.id !== -1 && !column.doTriggerReload;
  }

  optionRowMatch(row, column: TableColumn, option: Option): boolean {
    if (row[option.id] !== undefined) {
      return row[option.id];
    }
    return (
      row[column.id] === option.id ||
      row[column.id]?.id === option.id ||
      (Array.isArray(row[column.id]) && row[column.id].some(item => item.id === option.id))
    );
  }

  optionDataCount(data, column: TableColumn, option: Option): number {
    return data.filter(row => {
      return this.optionRowMatch(row, column, option);
    }).length;
  }

  groupRowMatch(row, group: FilterGroup) {
    const inactive = group.filterItems.every(filter => filter?.options.every(option => !option.isSelected));
    // row[group.id] will only work for filterGroup column with defined items
    if (!group.isExpanded || !row[group.id] || inactive) {
      return false;
    }
    return group.filterItems.every(filter => {
      if (filter.options.every(option => !option.isSelected)) return true;
      return filter.options
        .filter(option => option.isSelected)
        .some(selectedOption => {
          return this.optionRowMatch(row, filter, selectedOption);
        });
    });
  }

  filterCount() {
    return this.filterableTableColumns?.filter(column => {
      if (column.isInclude) {
        // For anything only included by user selection and not included by default, we don't need to alert user
        return false;
      }
      if (column.isMultiOption) {
        return this.searchFilters[column.id].length !== column.options.length;
      }
      // -1 Used for unselected filter. 0 is sometimes used as a selection
      return (
        this.searchFilters[column.id] != -1 && (this.searchFilters[column.id] || this.searchFilters[column.id] === 0)
      );
    })?.length;
  }

  onToggleFilterGroupItem(groupId, filterId, optionId) {
    const select = () => {
      this.isProcessing = true;
      this.changeRef.detectChanges();
      const group = this.filterGroups.find(group => group.id === groupId);
      const filterItem = group.filterItems.find(filter => filter.id === filterId);
      filterItem.options.forEach(option => {
        if (option.id === optionId) {
          option.isSelected = !option.isSelected;
          if (option.actionMessage) {
            this.bannerButtonMessage = option.actionMessage;
          }
        } else if (!filterItem.isMultiOption) {
          option.isSelected = false;
        }
      });

      filterItem.hasSelections = filterItem.options?.some(option => option.isSelected);
      group.filterItems.forEach(filter => {
        filter.isHidden = filter.otherFilterRequirements?.some(condition => {
          return group.filterItems.some(otherFilter => {
            return condition.property === otherFilter.id && otherFilter.options.every(option => !option.isSelected);
          });
        });
        if (filter.isHidden) {
          filter.options.forEach(option => (option.isSelected = false));
        }
        filter.options.forEach(option => {
          option.filteredCount = group.rows.filter(row => {
            return (
              filter.otherFilterRequirements?.every(filterCondition => {
                const otherFilterItem = group.filterItems.find(filter => filter.id === filterCondition.property);
                if (!filterCondition.values) {
                  const selectedItem = otherFilterItem.options?.find(option => option.isSelected)?.id;
                  return row[selectedItem || filterCondition.property];
                }
                return filterCondition.values?.some(conditionValue => {
                  const option = otherFilterItem.options.find(option => option.id === conditionValue);
                  return this.optionRowMatch(row, otherFilterItem, option);
                });
              }) && this.optionRowMatch(row, filter, option)
            );
          }).length;
        });
      });
      this.countGroup(groupId);
      this.changeFilters(false);
      this.isProcessing = false;
    };
    if (this.selectedIds.length > 0) {
      this.confirmDialog.confirmThis(
        this.confirmationConfig,
        () => {
          select();
        },
        () => {
          // Retain selections
        }
      );
    } else {
      select();
    }
  }

  clearFilterSubGroup(groupId, filterId) {
    const group = this.filterGroups.find(group => group.id === groupId);
    const filterItem = group.filterItems.find(filter => filter.id === filterId);
    filterItem.options.forEach(option => {
      option.isSelected = false;
    });
    filterItem.hasSelections = false;
    this.countGroup(groupId);
    this.changeFilters(false);
  }

  toggleFilterGroup(groupId) {
    const toggle = () => {
      this.filterGroups.forEach(group => {
        group.isExpanded = group.id === groupId ? !group.isExpanded : false;
        group.filteredCount = 0;
        group.filterItems.forEach(filter => {
          filter.isHidden = filter.otherFilterRequirements?.length > 0;
          filter.options.forEach(option => {
            option.isSelected = false;
          });
        });
        if (group.isExpanded) {
          this.filterAccordion.expand(group.id);
        } else {
          this.filterAccordion.collapse(group.id);
        }
      });
      this.changeFilters(false);
    };
    if (this.selectedIds.length > 0) {
      this.confirmDialog.confirmThis(
        this.confirmationConfig,
        () => {
          toggle();
        },
        () => {
          // Reopen selected accordion item
          this.filterAccordion.collapseAll();
          const expandedGroupId = this.filterGroups.find(group => group.isExpanded).id;
          if (expandedGroupId) {
            this.filterAccordion.expand(expandedGroupId.toString());
          }
        }
      );
    } else {
      toggle();
    }
  }

  countGroup(groupId) {
    const group = this.filterGroups.find(group => group.id === groupId);
    group.filteredCount = group.rows.filter(row => this.groupRowMatch(row, group)).length;
  }

  onTableClick(tableId: number, rowIdOrIdColumn?: number | TableColumn, columnId?: string) {
    if (this.isClickDisabled) {
      return;
    }

    // This is a hack to support columns with an id of 'id'.
    // The 'id' column is a special column used as the row index.
    const rowId = typeof rowIdOrIdColumn === 'number' ? rowIdOrIdColumn : rowIdOrIdColumn.id;

    // Get actual row number from filtered data row
    const tableDataRow = this.tableData.find(row => row[this.rowKey || 'id'] === rowId);
    const tableDataRowIndex = this.tableData.indexOf(tableDataRow);
    const column = this.tableColumns.find(column => column.id === columnId);
    if (column.isCopy && tableDataRow[column.id]) {
      this.navigator.copyToClipboard(tableDataRow[columnId]);
      return;
    }
    if (column || (tableDataRowIndex !== undefined && this.isRowClickable)) {
      this.onClick.emit({ rowIndex: tableDataRowIndex, columnId: columnId, data: tableDataRow });
    }
  }

  back() {
    this.selectedValue = this.defaultValue;
    this.selectedValueChange.emit(this.selectedValue);
  }

  canGoBack() {
    return (
      (this.selectedValue && !this.placeholderText) || (this.defaultValue && this.selectedValue !== this.defaultValue)
    );
  }

  changePageSize() {
    this.pageSizeChange.emit(this.pageSize);
    this.currentPage = 1;
    this.resetFormattedData();
    this.changePage();
    window.scrollTo(this.tableContainer.nativeElement.yPosition);
  }

  changePage() {
    // When paginating on back end we use index, starting at 0
    this.currentPageChange.emit(this.currentPage);
    if (!this.isPaginationDisabled) {
      this.formatTableData();
    }
  }

  getPageSize(): number {
    return this.isPaginationDisabled ? this.tableData.length : this.pageSize;
  }

  getPageStart(): number {
    return this.getPageSize() * (this.currentPage - 1);
  }

  getPageEnd(): number {
    return this.getPageStart() + this.getPageSize();
  }

  resultsMessage() {
    const totalRows = this.rowCount || this.filteredTableData.length;
    const lastPage = Math.ceil(totalRows / this.pageSize);
    const end = this.currentPage === lastPage ? totalRows : this.pageSize * this.currentPage;
    const start = this.pageSize * this.currentPage - this.pageSize + 1;
    const isPaginated = !this.isPaginationDisabled && totalRows > this.pageSize;

    if (isPaginated) {
      return this.translate.instant('SB_Showing_Page_Results_Count', { start: start, end: end, total: totalRows });
    }

    return this.translate.instant('SB_Showing_Results_Count', { count: totalRows });
  }

  currentPageData(tableId: number): { length } {
    if (tableId === -1) {
      // Don't show 'all' table
      return null;
    }
    if (tableId) {
      const data = (this.formattedTableData[1] as []).filter((row: { value }[]) => {
        if (typeof tableId === 'number') {
          return row[this.groupByColumn.id] === tableId || row[this.groupByColumn.id]?.id === tableId;
        } else {
          return row[tableId]?.value;
        }
      });
      return data.length > 0 ? data : null;
    }
    return this.formattedTableData[this.currentPage] as { length: string };
  }

  allFilterGroupsInactive() {
    return (
      !this.filterGroups ||
      this.filterGroups.every(group =>
        group.filterItems?.every(filterItem => filterItem.options?.every(option => !option.isSelected))
      )
    );
  }

  toggleSelectAll() {
    const selectedNotDisabled = this.filteredTableData.filter(data => {
      return !data['isDisabled'];
    });
    const allSelected = this.selectedIds.length === selectedNotDisabled.length;
    if (allSelected) {
      // Clear all
      this.selectedIds = [];
      this.isAllSelected = false;
    } else {
      // Check all
      this.selectedIds = selectedNotDisabled.map(row => row[this.rowKey || 'id']);
      this.isAllSelected = true;
    }
    this.onChangeSelectedIds();
  }

  onCheckBoxClicked(id: number) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
      this.isAllSelected = false;
    } else {
      this.selectedIds.push(id);
      this.isAllSelected = this.selectedIds.length === this.tableData.length;
    }
    this.onChangeSelectedIds();
  }

  isRowSelected(id: number) {
    return this.selectedIds.includes(id);
  }

  bannerMessage() {
    return this.selectedIds.length + ' selected';
  }

  bannerButtonClick() {
    this.onBannerButtonClick.emit();
  }

  isReadable(value): boolean {
    return typeof value === 'string' || typeof value === 'number';
  }

  noContent() {
    return !this.contentRef?.nativeElement?.hasChildNodes();
  }

  hideTable(): boolean {
    // Show table if some columns should be shown after detail opened
    if (this.visibleTableColumns().length > 0 || this.placeholderText) {
      return false;
    }
    // If selected value and both ng-content and routing navigation columns shown, hide table
    if (this.noContent()) {
      return this.selectedValue?.id > 0;
    }
    return this.selectedValue?.id > 0 && this.isRouted && !this.noContent();
  }

  showChart(columnId?: string) {
    if (!columnId && this.filterGroups) {
      this.chart = {
        title: 'SB_Summary',
        option: {
          textStyle: {
            fontFamily: 'Open Sans'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value'
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              data: this.filterGroups.map(group => this.translate.instant(group.name))
            }
          ],
          series: this.filterGroups[0].filterItems[0].options.map(option => {
            return {
              name: this.translate.instant(option.name),
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              emphasis: {
                focus: 'series'
              },
              barGap: 1,
              data: this.filterGroups.map(
                group => group.filterItems[0].options.find(itemOption => itemOption.id == option.id)?.count
              ),
              color: this.filterGroups[0].filterItems[0].options.map(itemOption =>
                this.colour.getColourFromClass(itemOption.class)
              )
            };
          })
        }
      };
    } else {
      const column = this.tableColumns.find(column => column.id === columnId);
      this.chart = {
        title: 'SB_Summary',
        option: {
          textStyle: {
            fontFamily: 'Open Sans'
          }
        }
      };
      const data = column.options?.filter(option => option.id !== -1);
      if (data) {
        if (column.isMultiValue) {
          this.chart.option = {
            ...this.chart.option,
            xAxis: {
              type: 'category',
              data: data.map(option => this.translate.instant(option.name)),
              axisLabel: {
                interval: 0
              }
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: data.map(option => {
                  return {
                    value: option.count,
                    itemStyle: { color: option.class ? this.colour.getColourFromClass(option.class) : null }
                  };
                }),
                type: 'bar'
              }
            ]
          };
        } else {
          this.chart.option = {
            ...this.chart.option,
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: data.map(option => {
                  return { name: this.translate.instant(option.name), value: option.count };
                }),
                color: data.every(option => option.class)
                  ? data.map(option => this.colour.getColourFromClass(option.class))
                  : null,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
        }
      } else if (column.dateFormat) {
        const timeLengths: { name: string; length?: string; value? }[] = [
          {
            name: 'Total'
          },
          {
            name: 'This year',
            length: 'year'
          },
          {
            name: 'This month',
            length: 'month'
          },
          {
            name: 'Last 1 week',
            length: 'week'
          },
          {
            name: 'Last 24 hours',
            length: 'day'
          }
        ];
        let filteredRows = this.tableData.filter(row => row[column.id]);
        timeLengths.forEach(item => {
          if (item.length) {
            const dateToCompare = sub(new Date(), `{item.length}s` as Duration);
            filteredRows = filteredRows.filter(row => isAfter(row[column.id], dateToCompare));
          }
          return (item.value = filteredRows.length);
        });
        this.chart.option = {
          ...this.chart.option,
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c}'
          },
          series: [
            {
              type: 'funnel',
              data: timeLengths,
              label: {
                show: true,
                position: 'inside'
              },
              labelLine: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: 'solid'
                }
              }
            }
          ]
        };
      }
    }
    this.modal
      .open(this.chartModal, { ariaLabelledBy: 'modal-data-chart', size: 'lg', modalDialogClass: 'modal-chart' })
      .result.then(() => (this.chart = null));
  }

  onClickSummary(value: string | number) {
    this.resetFilters();
    this.searchFilters[this.rowStateColumnId] = value;
    this.changeFilters(false);
  }

  getColumnOption(column: TableColumn, row) {
    return column.options.find(option => option.id == row[column.id]);
  }

  getColumnItem(column: TableColumn, row) {
    return column.items.find(item => item.id == row[column.id]);
  }

  getLabel(column: TableColumn, row) {
    return this.dataTable.getLabel(column, row, this.digitsInfo);
  }

  showItemLabel(column: TableColumn, row, item: TableColumn) {
    return column.items && typeof row[item.id] !== 'boolean' && this.isReadable(this.getLabel(item, row));
  }

  getTotalLabel(column: TableColumn, dataTable) {
    if (!this.totalLabels[column.id]) {
      this.totalLabels[column.id] = this.dataTable.getTotalLabel(column, dataTable, this.digitsInfo);
    }
    return this.totalLabels[column.id];
  }

  getClass(column: TableColumn, row): UiClasses {
    return this.dataTable.getClass(column, row);
  }

  getRowStateClass(row): string {
    return this.dataTable.getRowStateClass(this.tableColumns, this.rowStateColumnId, row);
  }

  getTooltip(col: TableColumn, row: unknown[], item: TableColumn) {
    return row[item.id]?.tooltip || col.tooltip;
  }

  getIconTooltip(col: TableColumn, row: unknown[], item: TableColumn) {
    const prefix = item.iconTooltipPrefix;
    const itemValue = row[item.id];
    if (prefix && itemValue) {
      return `${this.translate.instant(prefix)}: ${itemValue}`;
    }
    return item.iconTooltip || row[item.id]?.iconTooltip || col.iconTooltip || this.getTooltip(col, row, item);
  }

  showContent(item: TableColumn) {
    return (
      !item.cellType ||
      item.cellType === this.cellTypes.Text ||
      item.cellType === this.cellTypes.Numeric ||
      item.cellType === this.cellTypes.Currency ||
      item.cellType === this.cellTypes.Popover ||
      item.cellType === this.cellTypes.DateRange
    );
  }

  getPopoverContentArray(row, item) {
    const value = row[item.id];
    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  }

  getMatchedIcon(col: TableColumn, value: unknown): MatchedIcons | undefined {
    return col.valueMatchedIcons?.find(option => option.matchedValue === value);
  }

  requestUpdateValue(col: TableColumn, row: unknown[], value) {
    this.onRequestUpdateValue.emit({
      columnId: col.id,
      rowId: row[this.rowKey || 'id'],
      value: value
    });
  }

  export($event: number) {
    this.isExporting = true;

    const columns = this.tableColumns.map(column => ({
      ...column,
      name: column.name && column.name?.startsWith('SB_') ? this.translate.instant(column.name) : column.name
    }));

    this.dataTable
      .export(
        {
          ...this.exportSettings,
          data: this.tableData,
          columns: columns,
          fileTypeId: FileTypes[$event].id,
          rowStateColumnId: this.rowStateColumnId
        },
        this.digitsInfo,
        this.exportSettings.fileName
      )
      .subscribe(() => {
        this.isExporting = false;
      });
  }

  onChangeDate($event: Ng1DatePickerChange, column: TableColumn) {
    column.startDate = $event.startDate;
    column.endDate = $event.endDate;
    this.changeFilters(false);
  }
}
