import { TeamClubSelection } from './../../models/team-club-selection';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UserService } from '@sb-shared/services/user.service';

@Component({
  selector: 'sb-team-club-picker',
  templateUrl: './team-club-picker.component.html',
  styleUrls: ['./team-club-picker.component.scss']
})
export class TeamClubPickerComponent {
  clubIds: number[] = [];
  teamIds: number[] = [];
  subjectClassIds: number[] = [];
  excludedTeamIds: number[] = [];
  excludedClubIds: number[] = [];
  excludedSubjectClassIds: number[] = [];
  isTripAdminOrHigher: boolean = this.userService.isTripAdminOrHigher();
  @Input() showSubjects: boolean;
  @Input() model: TeamClubSelection = new TeamClubSelection();
  @Output() modelChange: EventEmitter<TeamClubSelection> = new EventEmitter<TeamClubSelection>();

  constructor(private userService: UserService) {}

  change() {
    if (Object.keys(this.model).every(key => Array.isArray(this.model[key]) && this.model[key].length === 0)) {
      // Emit undefined if all arrays are empty, to make required validation work
      this.modelChange.emit(undefined);
    } else {
      this.modelChange.emit(this.model);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && !changes.model.currentValue) {
      // Must always follow TeamClubSelection model
      this.model = new TeamClubSelection();
    }
  }
}
