export const RouterPaths = {
  Diary: 'Diary',
  Events: 'Events',
  Fixtures: 'Fixtures',
  Invites: 'Invites',
  Locations: 'Locations',
  Reception: 'Reception',
  SportsPortal: 'SportsPortal',
  Squads: 'Squads',
  TripsPortal: 'TripsPortal'
};
