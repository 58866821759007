'use strict';

angular.module('sysadmin.components.modules', [
    'sysadmin.services.modulesService',
    'shared.services.simpleAlertService',
    'shared.components.sbOnOffSwitch',
    'shared.services.tinymceConfigHelper',
    'shared.filters.decoded',
]).component('modules', {
    templateUrl: '/Scripts/app/sysadmin/components/modules.template.html',
    bindings: {
        organisation: '<',
    },
    controller: [
        'modulesService',
        'simpleAlertService',
        'tinymceConfigHelper',
        function modulesController(modulesService, simpleAlertService, tinymceConfigHelper) {
            var self = this;
            this.anyChanges = false;
            this.anyModuleTypeChanges = false;
            this.anyAddNewChanges = false;
            this.model = null;
            this.showingAddNewCcaSignupType = false;

            this.showNewModuleValidationError = false;
            this.showManageModulesValidationError = false;
            this.showManageModuleTypesValidationError = false;

            // Upsell rich text editor settings - for Module Default Upsell and CCA Signup Module Upsell.
            this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({
                images: true,
                fonts: true,
                editHtml: true,
                height: 300,
                forceLinkPrefix: false,
                fontSize: true,
                fontFamily: true
            });

            this.newCcaSignupType =
            {
                organisationId: 0,
                moduleTypeId: '',
                type: '',
                title: '',
                isActive: false,
                isForInfoOnly: false,
                menuDisplayOrder: 1,
                iconClass: 'far fa-question-circle',
                upsell: '',
                hasTransport: false
            };

            this.$onChanges = function (changes) {
                if (typeof self.organisation !== 'undefined' && self.organisation != null) {
                    self.loadModules();
                    self.loadCommunicationsModule();
                }
            };

            this.loadModules = function () {
                this.showAddNewValidationError = false;
                this.showManageModulesValidationError = false;
                this.showManageModuleTypesValidationError = false;

                modulesService.getModules(this.organisation.organisationId)
                    .then(function (data) {
                        self.model = data;
                    })
                    .catch(function (err) {
                        simpleAlertService.errorAlert();
                        console.error(err);
                    });

                this.anyChanges = false;
                this.anyModuleTypeChanges = false;
            }.bind(this);

            this.loadCommunicationsModule = () => {
                modulesService.getCommunicationsModule(this.organisation.organisationId)
                    .then(res => {
                        this.communicationsModuleClean = res.data;
                        this.cleanCommunicationsModule();
                    });
            };

            this.cleanCommunicationsModule = () => {
                this.communicationsModule = Object.assign({}, this.communicationsModuleClean);
            };

            this.checkCommunicationsModuleChanges = () => {
                return JSON.stringify(this.communicationsModule) !== JSON.stringify(this.communicationsModuleClean);
            }

            this.saveModules = function () {
                if (typeof this.model === 'undefined') {
                    return;
                }

                if (!this.isDataValid()) {
                    return;
                }

                modulesService.saveModules(this.model)
                    .then(function (data) {
                        self.loadModules();
                    })
                    .catch(function (err) {
                        const { Message: message } = { ...err.data };

                        simpleAlertService.errorAlert({ message });
                    });
            }.bind(this);

            this.saveModuleTypes = function () {
                if (typeof this.model === 'undefined') {
                    return;
                }

                if (!this.isModuleTypeDataValid()) {
                    return;
                }

                modulesService.saveCcaModuleTypes(this.model)
                    .then(function (data) {
                        self.loadModules();
                    })
                    .catch(function (err) {
                        simpleAlertService.errorAlert();
                        console.error(err);
                    });

            }.bind(this);

            this.saveCommunicationsModule = () => {
                modulesService.saveCommunicationsModule(this.organisation.organisationId, this.communicationsModule)
                    .then(() => {
                        this.communicationsModuleClean = Object.assign({}, this.communicationsModule);
                    })
                    .catch(() => {
                        this.cleanCommunicationsModule();
                    });
            };

            this.isModuleTypeDataValid = function () {
                this.showManageModuleTypesValidationError = false;

                for (var mt = 0; mt < this.model.moduleTypes.length; mt++) {
                    var moduleType = this.model.moduleTypes[mt];

                    if (typeof moduleType.displayIcon === 'undefined' ||
                        moduleType.displayIcon == '' ||
                        typeof moduleType.defaultUpsell === 'undefined' ||
                        moduleType.defaultUpsell == '') {
                        this.showManageModuleTypesValidationError = true;
                        return false;
                    }
                }

                return true;

            }.bind(this);

            this.isDataValid = function () {
                this.showValidationError = false;

                if (typeof this.model.feesModule === 'undefined') {
                    this.showManageModulesValidationError = true;
                    return false;
                }

                if (this.model.feesModule.isActive &&
                    (typeof this.model.feesModule.appLabel === 'undefined' ||
                        this.model.feesModule.appLabel == '')) {
                    this.showManageModulesValidationError = true;
                    return false;
                }

                if (this.model.feesModule.isActive &&
                    (typeof this.model.feesModule.webLabel === 'undefined' ||
                        this.model.feesModule.webLabel == '')) {
                    this.showManageModulesValidationError = true;
                    return false;
                }

                for (var mt = 0; mt < this.model.moduleTypes.length; mt++) {
                    var moduleType = this.model.moduleTypes[mt];

                    if (typeof moduleType.defaultUpsell === 'undefined' ||
                        moduleType.defaultUpsell == '') {
                        this.showManageModulesValidationError = true;
                        return false;
                    }

                    for (var cca = 0; cca < moduleType.ccaSignupModules.length; cca++) {
                        var ccaSignupType = moduleType.ccaSignupModules[cca];

                        if (ccaSignupType.isActive) {
                            if (typeof ccaSignupType.title === 'undefined' || ccaSignupType.title == '') {
                                this.showManageModulesValidationError = true;
                                return false;
                            }

                            if (typeof ccaSignupType.menuDisplayOrder === 'undefined' || ccaSignupType.menuDisplayOrder == '' || isNaN(ccaSignupType.menuDisplayOrder)) {
                                this.showManageModulesValidationError = true;
                                return false;
                            }

                            if (typeof ccaSignupType.iconClass === 'undefined' || ccaSignupType.iconClass == '') {
                                this.showManageModulesValidationError = true;
                                return false;
                            }

                            if (ccaSignupType.isForInfoOnly &&
                                typeof ccaSignupType.upsell === 'undefined' ||
                                ccaSignupType.upsell == '') {
                                this.showManageModulesValidationError = true;
                                return false;;
                            }

                            if (typeof ccaSignupType.hasTransport === 'undefined' || (ccaSignupType.hasTransport != true && ccaSignupType.hasTransport != false)) {
                                this.showManageModulesValidationError = true;
                                return false;
                            }

                        }
                    }
                }

                var ccaSignupNames = [];
                for (var i = 0; i < this.model.moduleTypes.length; i++) {
                    for (var cca = 0; cca < this.model.moduleTypes[i].ccaSignupModules.length; cca++) {
                        ccaSignupNames.push(this.model.moduleTypes[i].ccaSignupModules[cca].title.trim().toLowerCase());
                    }
                }

                // Use Javascript filter to check for duplicate items in the ccaSignupNames array.
                var duplicates = ccaSignupNames.filter(function (a) {
                    return ccaSignupNames.indexOf(a) !== ccaSignupNames.lastIndexOf(a);
                });

                if (duplicates.length > 0) {
                    this.showManageModulesValidationError = true;
                }

                return duplicates.length == 0;

            }.bind(this);

            this.showAddCcaSignupType = function (moduleType) {
                this.showNewModuleValidationError = false;
                this.showManageModulesValidationError = false;

                // initialise add control properties from module type defaults
                for (var i = 0; i < this.model.moduleTypes.length; i++) {
                    if (this.model.moduleTypes[i].type == moduleType) {
                        this.newCcaSignupType.title = '';
                        this.newCcaSignupType.isActive = true;
                        this.newCcaSignupType.isForInfoOnly = false;
                        this.newCcaSignupType.type = moduleType;
                        this.newCcaSignupType.organisationId = this.model.organisationId;
                        this.newCcaSignupType.moduleTypeId = this.model.moduleTypes[i].id;
                        this.newCcaSignupType.menuDisplayOrder = this.model.moduleTypes[i].displayOrder;
                        this.newCcaSignupType.iconClass = this.model.moduleTypes[i].displayIcon;
                        this.newCcaSignupType.upsell = this.model.moduleTypes[i].defaultUpsell;
                        this.newCcaSignupType.hasTransport = this.model.moduleTypes[i].hasTransport;
                        break;
                    }
                }

                this.showingAddNewCcaSignupType = true;

                $('#divAddNewSignupType_' + moduleType).removeClass('hidden');

            }.bind(this);

            this.cancelAddNewCcaSignupType = function (moduleType) {
                this.newCcaSignupType.organisationId = 0;
                this.newCcaSignupType.moduleTypeId = 0;
                this.newCcaSignupType.type = '';
                this.newCcaSignupType.title = '';
                this.newCcaSignupType.isActive = false;
                this.newCcaSignupType.isForInfoOnly = false;
                this.newCcaSignupType.menuDisplayOrder = 1;
                this.newCcaSignupType.iconClass = 'far fa-question-circle';
                this.newCcaSignupType.upsell = '';
                this.newCcaSignupType.hasTransport = false;

                $('#divAddNewSignupType_' + moduleType).hide();

                this.showingAddNewCcaSignupType = false;
                this.showNewModuleValidationError = false;
                this.showManageModulesValidationError = false;
            };

            this.addNewCcaSignupType = function () {
                var newType = JSON.parse(JSON.stringify(this.newCcaSignupType));
                newType.title = newType.title.trim().toLowerCase();

                if (!this.anyAddNewChanges || !this.isNewCcaSignupTypeValid(newType)) {
                    return false;
                }

                for (var i = 0; i < this.model.moduleTypes.length; i++) {
                    if (this.model.moduleTypes[i].type == newType.type) {
                        this.model.moduleTypes[i].ccaSignupModules.push(newType);
                        break;
                    }
                }

                modulesService.addCcaSignupModule(this.newCcaSignupType)
                    .then(function (data) {
                        self.loadModules();
                        this.showingAddNewCcaSignupType = false;
                        $('#divAddNewSignupType_' + this.newCcaSignupType.type).hide();
                    }.bind(this))
                    .catch(function (err) {
                        simpleAlertService.errorAlert();
                        console.error(err);
                    });
            }.bind(this);

            this.isNewCcaSignupTypeValid = function (newType) {
                this.showNewModuleValidationError = false;

                if (newType.isActive &&
                    (typeof newType.title === 'undefined' ||
                        newType.title == '')) {
                    this.showNewModuleValidationError = true;
                    return false;
                }

                if (newType.isActive &&
                    (typeof newType.iconClass === 'undefined' ||
                        newType.iconClass == '')) {
                    this.showNewModuleValidationError = true;
                    return false;
                }

                if (newType.isActive &&
                    (typeof newType.menuDisplayOrder === 'undefined' ||
                        newType.menuDisplayOrder == '' ||
                        isNaN(newType.menuDisplayOrder))) {
                    this.showNewModuleValidationError = true;
                    return false;
                }

                if (newType.isActive &&
                    newType.isForInfoOnly &&
                    (typeof newType.upsell === 'undefined' ||
                        newType.upsell == '')) {
                    this.showNewModuleValidationError = true;
                    return false;
                }

                for (var i = 0; i < this.model.moduleTypes.length; i++) {
                    for (var cca = 0; cca < this.model.moduleTypes[i].ccaSignupModules.length; cca++) {
                        if (newType.title == this.model.moduleTypes[i].ccaSignupModules[cca].title.trim().toLowerCase()) {
                            this.showNewModuleValidationError = true;
                            return false;
                        }
                    }
                }

                return true;

            }.bind(this);

            this.changesMade = function () {
                this.anyChanges = true;
            }

            this.transportActiveChanged = function () {
                this.model.transportModule.isEventGenerationEnabled = this.model.transportModule.isActive;
                if(!this.model.transportModule.IsActive) {
                    this.model.transportModule.isGPSTrackingEnabled = false;
                }
            }.bind(this);

            this.ccaModuleIsActiveChanged = function (ccaSignupModule) {
                if (typeof ccaSignupModule !== 'undefined' && ccaSignupModule != null) {
                    if (!ccaSignupModule.isActive) {
                        ccaSignupModule.isForInfoOnly = false;
                    }
                }
            }

            this.moduleTypeChangesMade = function () {
                this.anyModuleTypeChanges = true;

            }.bind(this);

            this.addNewChangesMade = function () {
                this.anyAddNewChanges = true;
            }.bind(this);
        }
    ]
});
