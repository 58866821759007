import { animate, style, transition, trigger } from '@angular/animations';
import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EventFieldIds } from '@sb-events/constants/event-fields-ids';
import { EventTabId } from '@sb-events/enums/event-tab';
import { isDebug, momentParamsConverter } from '@sb-helpers';
import { OrganisationLabelTags } from '@sb-shared/constants/organisation-label-tags.constants';
import { formElements } from '@sb-shared/constants/shared.constant';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { DateFormats } from '@sb-shared/globals/date-formats';
import { MemberTypes } from '@sb-shared/globals/member-types';
import { ValidationStates } from '@sb-shared/globals/validation-states';
import { Button } from '@sb-shared/models/UI/buttons';
import { Options } from '@sb-shared/models/UI/filter';
import { LoadingMessage } from '@sb-shared/models/UI/loading-message';
import {
  CrossFieldValidationEnum,
  DateRangeLimit,
  FieldRuleList,
  FieldRules,
  ValidationState,
  WizardField,
  WizardModalSettings,
  WizardTab,
  WizardTabs
} from '@sb-shared/models/UI/wizard-tabs';
import { SubWizardSave } from '@sb-shared/models/sub-wizard-save';
import { BlobImageService } from '@sb-shared/services/blob-image.service';
import { ConfirmDialogService } from '@sb-shared/services/confirm-dialog.service';
import { DateTimeService } from '@sb-shared/services/date-time.service';
import { MathService } from '@sb-shared/services/math.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
import { TwitterService } from '@sb-shared/services/twitter.service';
import { UrlService } from '@sb-shared/services/url.service';
import { WizardService } from '@sb-shared/services/wizard.service';
import { addHours, isAfter } from 'date-fns';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { ComponentBase } from '../component-base/component-base';

@Component({
  selector: 'sb-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))])
    ])
  ]
})
export class WizardComponent extends ComponentBase implements OnInit, OnChanges, OnDestroy {
  @ViewChild('subWizard') subWizardModal: NgbModalRef;
  // Event emitters for various actions
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onButtonClick = new EventEmitter();
  @Output() onSecondaryButtonClick = new EventEmitter();
  @Output() onDangerButtonClick = new EventEmitter();
  // Tabs change event to allow two way binding
  @Output() tabsChange: EventEmitter<WizardTabs> = new EventEmitter<WizardTabs>();
  // Wizard data change event
  @Output() onDataChange = new EventEmitter();

  // Layout to show inside modal
  @Input() isModal: boolean;
  // Don't show green ticks on wizard tabs - useful when editing a record
  @Input() hideChecks = false;
  // Don't show images on wizard pages
  @Input() hideImages: boolean;
  // Title for wizard form
  @Input() formTitle: string;
  // Tabs for wizard
  @Input() tabs: WizardTabs;
  // Data to be passed in
  @Input() parentData;
  // Id for parent data form to be used in template and by children
  @Input() parentDataId: number | string;
  // Saving state - show loading and disable form
  @Input() isSaving: boolean;
  // Loading state  - show loading and disable form
  @Input() isLoading: boolean;
  // Secondary button for alternate click event
  @Input() secondaryButton: Button;
  // Danger button for alternate click event - likely a 'delete' etc
  @Input() dangerButton: Button;
  // Whether to flatten data - not currently used
  @Input() isFlat: boolean;
  // Whether to merge tabs - potentially for when editing a record in single page
  @Input() mergeTabs: boolean;
  // Label for save button
  @Input() saveLabel: string;
  // Title for discard wizard modal which shows when clicking 'x'
  @Input() discardTitle: string;
  // message for discard wizard modal which shows when clicking 'x'
  @Input() discardMessage: string;
  // Message content to show when loading - eg when calling multiple endpoints running different processes
  @Input() loadingMessage: LoadingMessage;
  // Whether to show action buttons at top as well as at bottom
  @Input() hasTopActions: boolean;

  @Input() readOnly: boolean;

  readonly VALUE_CHANGE_DEBOUNCE_TIME = 300;
  isDebouncing = false;

  wizardForm: UntypedFormGroup;
  formValidationMessages;
  Validators: Validators;

  title: string;
  data: { roles?; isExternal?; isExternalCoachAccess?; isTransportBusMonitor? } = {};
  ui;
  changes = {};
  activeTabId: number;
  telCodes;
  tinymceOptions;
  changesForParent = 0;
  currencySymbol: string;
  showSubjects: boolean;

  formElements = formElements;
  tabHistory: number[] = [];
  memberTypes = MemberTypes;
  showCancel: boolean;

  // Subwizard
  subWizardField: WizardField;
  subWizardTitle: string;
  subWizardData;
  subWizardRecordId: number;
  isLoadingSubWizardRecord: boolean;
  isSavingSubWizard: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private blobImageService: BlobImageService,
    private twitter: TwitterService,
    private translate: TranslateService,
    private modal: NgbModal,
    private dateTime: DateTimeService,
    private confirmDialog: ConfirmDialogService,
    private organisation: OrganisationService,
    private wizard: WizardService,
    private math: MathService,
    private changeDetector: ChangeDetectorRef,
    private urlService: UrlService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data) {
      this.resetData();
    }
    this.ui = {
      showOptions: {}
    };
    this.organisation.getCurrentOrganisation().subscribe(org => {
      this.currencySymbol = org.currencyDisplaySymbol;
      this.showSubjects = org.isSubjectsEnabled;
    });
    this.showCancel = this.onCancel.observers.length > 0;

    this.setupTabs();
    this.setupData();
    this.updateControls();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.tabs = [];
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Only run this once.
    if (changes?.tabs?.currentValue && !changes.tabs.previousValue) {
      this.setupTabs();
    }

    if (changes?.parentData) {
      this.setupData();
      if (changes.parentData.previousValue) {
        // data has changed, need to update values on controls.
        this.updateControls();
      }
    }

    if (changes?.readOnly) {
      this.updateControls();
    }
  }

  setupData() {
    this.data = { ...this.parentData };
    this.tabs.forEach(tab => {
      tab.fields.forEach(field => {
        if (!field.id) {
          return;
        }

        if (field.type === this.formElements.Content && !field.isPlainText) {
          field.editorConfig = { ...field.editorConfig, fullScreen: true };
        }

        const nestArray = field.id.split(CommonChars.Hyphen);
        if (this.data[nestArray[0]] && nestArray.length === 2) {
          // Handle two levels for now
          this.data[field.id] = this.data[nestArray[0]][nestArray[1]];
        }

        // Add content to elements for data table
        if (this.data[field.id] && field.type === this.formElements.Data) {
          field.elements = [];
          Object.entries(this.data[field.id]).forEach(([key, value]) => {
            field.elements.push({
              class: 'semi-bold',
              html: (key || '').toString()
            });
            field.elements.push({
              html: (value || '').toString()
            });
          });
        }

        if (field.type === formElements.Colours) {
          const colourObject = this.data[field.id];
          const primaryColour = this.data[field.secondaryIds[0]];
          const secondaryColour = this.data[field.secondaryIds[1]];
          if (!colourObject && primaryColour && secondaryColour) {
            this.data[field.id] = {
              primaryColour,
              secondaryColour
            };
          }
        }

        const {
          yearGroupIds: clubIds = [],
          groupIds: teamIds = [],
          subjectClassIds = []
        } = { ...this.data } as {
          yearGroupIds: number[];
          groupIds: number[];
          subjectClassIds: number[];
        };

        const selectedCount = clubIds.length + teamIds.length + subjectClassIds.length;
        const isRestore = selectedCount > 0;
        const hasNewGroup = teamIds.length !== 1 || (teamIds.length === 1 && selectedCount > 1);

        if (isRestore && field.type === formElements.UserGroupings) {
          this.isSaving = false;
          this.updateValue(tab, field, { clubIds, teamIds, subjectClassIds });

          return;
        }

        if (isRestore && hasNewGroup && tab.id === EventTabId.GroupStaff) {
          const val = this.getNestedValueFromField(field);

          if (val === null) return;

          this.updateValue(tab, field, val);

          if (field.id === EventFieldIds.NewGroupLeadStaff) {
            field.options = this.data[EventFieldIds.NewGroupStaffList];
            this.updateValue(tab, field, val);

            return;
          }
        }

        if (field.type === formElements.CheckboxList) {
          if (this.data.isExternal) {
            this.data.roles = [];

            if (this.data.isExternalCoachAccess) {
              this.data.roles.push('isExternalCoachAccess');
            }

            if (this.data.isTransportBusMonitor) {
              this.data.roles.push('isTransportBusMonitor');
            }
          }
        }

        // Update related fields if value
        const value = this.data[field.id];
        if (this.wizardForm && value) {
          this.updateRelatedFields(field, value);
        }

        if (this.data[field.id] === undefined) {
          if (field.defaultValue !== undefined) {
            this.updateValue(tab, field, field.defaultValue);
          } else if (field.isRandomDefault && field.options) {
            const randomIndex = this.math.getRandomInt(field.options.length);
            const option = field.options[randomIndex];
            const value = option?.id || option;
            this.updateValue(tab, field, value);
          } else if (field.suggestions) {
            const randomIndex = this.math.getRandomInt(field.suggestions.length);
            const value = field.suggestions[randomIndex];
            this.updateValue(tab, field, value);
          }
        }

        if (field.dateFormat && this.data[field.id]) {
          this.data[field.id] = this.dateTime.formatDate(this.data[field.id], field.dateFormat);
        }

        if (field.type?.isDatePicker) {
          if (this.data[field.id]) {
            this.data[field.id] = {
              startDate: new Date(this.data[field.id]),
              endDate: new Date(this.data[field.id])
            };
          } else {
            const now = new Date();
            this.updateValue(tab, field, {
              startDate: now,
              endDate: addHours(now, 1)
            });
          }

          if (!field.type?.isSingleDate) {
            // Set secondary fields for date range input by default
            field.secondaryIds = field.secondaryIds || field.type?.secondaryKeys;
          }
        }

        // Set select option properties for any not previously prepared (all fields onInit and some fields onChanges)
        if (Array.isArray(field.options) && field.options.length > 0) {
          const firstOption = field.options[0];
          if (!field.idProp) {
            const idPropSuggestions = ['id', 'value'];
            idPropSuggestions.reverse().forEach(suggestion => {
              if (firstOption[suggestion]) {
                field.idProp = suggestion;
              }
            });
          }
          if (!field.labelProp) {
            const labelPropSuggestions = ['label', 'name', 'text'];
            labelPropSuggestions.reverse().forEach(suggestion => {
              if (firstOption[suggestion]) {
                field.labelProp = suggestion;
              }
            });
          }
          // Set checkbox list UI model if not yet defined
          this.ui = this.ui || {};
          if (field.type === this.formElements.CheckboxList && !this.ui[field.id] && this.data[field.id]) {
            this.ui[field.id] = {};
            field.options.forEach(option => {
              this.ui[field.id][option.id] === this.data[field.id].includes(option.id);
            });
          }
        }

        if (Array.isArray(field.options) && field.options.length == 0 && field.type === this.formElements.Select) {
          const blankValue = '';
          field.options.push({ id: 0, name: this.translate.instant(OrganisationLabelTags.None) });

          this.data[field.id] = blankValue;
        }

        this.initMultiSelect(field);
      }); // for each field
    }); // for each tab
  }

  setupTabs() {
    this.prepareControls();

    this.tabs?.forEach(tab => {
      tab.svg = this.blobImageService.getUndrawSvg(tab.image);
    });
    this.filterTabs();
    const imageTabs = this.tabs.filter(tab => {
      return tab.image !== undefined;
    });
    if (imageTabs.length == 0) {
      this.hideImages = true;
    }
    if (!this.activeTabId) {
      this.setTab(this.tabs[0].id);
    }
  }

  filterTabs() {
    // Only show non-hidden tabs
    this.tabs = this.tabs.filter(tab => {
      return !tab.isHidden;
    });
  }

  updateControls() {
    if (!this.wizardForm) {
      return;
    }

    this.tabs.forEach(tab => {
      tab.fields.forEach(field => {
        if (field.isToggledOn) {
          field.isToggledOn = false;
          this.changeDetector.detectChanges();
        }
        const existingFormGroup = this.wizardForm.get(tab.formGroupName);
        const existingControl = existingFormGroup.get(field.id);
        if (existingControl) {
          existingControl.setValue(this.data[field.id], { emitEvent: false });

          this.setFieldEnabled(field, existingControl);
        }
      });
    });
  }

  setFieldEnabled(field: WizardField, control: AbstractControl) {
    this.enableField(field) ? control.enable({ emitEvent: false }) : control.disable({ emitEvent: false });
  }

  onCheckBoxChanged(tab, field, { id: optionName }, value) {
    const currentValue = this.getValue(tab, field) || [];
    let newValue;

    if (value) {
      newValue = [...currentValue, optionName];
    } else {
      newValue = currentValue?.filter(val => val !== optionName);
    }
    this.wizardForm.get(`${tab.formGroupName}.${field.id}`).patchValue(newValue);
  }

  prepareControls() {
    this.isLoading = true;
    this.wizardForm = this.wizardForm || new UntypedFormGroup({});

    this.subscriptions.add(this.wizardForm.statusChanges.subscribe(() => this.updateTabs()));

    this.tabs.forEach(tab => {
      tab.formGroupName = tab.formGroupName || 'wizardFormGroup' + tab.id;
      const existingFormGroup = this.wizardForm.get(tab.formGroupName);
      const isNew = !existingFormGroup;
      const tabFormGroup = (existingFormGroup as UntypedFormGroup) || new UntypedFormGroup({});
      tab.fields.forEach(field => {
        // Ignore untyped or decorative fields.
        if (!field.type || field.type.isDecorative) {
          return;
        }

        // Only create control if missing
        const existingControl = tabFormGroup.get(field.id);
        if (existingControl) {
          return;
        }

        const control = new UntypedFormControl(this.data[field.id] || field.defaultValue);

        // Handle validation
        control.addAsyncValidators([this.requiredValidator(field, control)]);
        const minlength = field.minlength || field.type?.minlength;
        if (minlength && field.type === formElements.Content && !field.isPlainText) {
          control.addValidators([this.htmlMinLengthValidator(field)]);
        } else if (minlength) {
          control.addValidators([Validators.minLength(minlength)]);
        }
        // Pattern to show in red validation text
        const pattern = field.pattern || field.type?.pattern;
        if (pattern) {
          control.addValidators([Validators.pattern(pattern)]);
        }
        // Patterns to show in checklist
        if (field.patternList) {
          field.patternList.forEach(rule => {
            control.addValidators([Validators.pattern(new RegExp(rule.regexValue, 'i'))]);
          });
        }
        if (field.type === formElements.Email) {
          control.addValidators([Validators.email]);
        }
        if (field.min) {
          control.addValidators([Validators.min(field.min)]);
        }
        const max = field.max || field.type?.max;
        if (field.max) {
          control.addValidators([Validators.max(max)]);
        }
        if (field.minProvider) {
          const type =
            field.type === formElements.Time ? CrossFieldValidationEnum.MinHoursMinutes : CrossFieldValidationEnum.Min;
          control.addAsyncValidators([this.crossFieldValidator(field, field.minProvider, type)]);
        }
        if (field.maxProvider) {
          const type =
            field.type === formElements.Time ? CrossFieldValidationEnum.MaxHoursMinutes : CrossFieldValidationEnum.Max;
          control.addAsyncValidators([this.crossFieldValidator(field, field.maxProvider, type)]);
        }
        if (field.matchField) {
          const type = CrossFieldValidationEnum.Match;
          control.addAsyncValidators([this.crossFieldValidator(field, field.matchField, type)]);
        }
        if (field.type === formElements.DateTimeRange) {
          control.addAsyncValidators([this.dateRangeValidator(control)]);
        }

        tabFormGroup.addControl(field.id, control);

        if (field.toggleMessage || !this.enableField(field)) {
          control.disable();
        }

        if (!field.type?.manualValidation) {
          control.valueChanges
            .pipe(
              distinctUntilChanged(),
              tap(() => {
                this.isDebouncing = true;
              }),
              debounceTime(this.VALUE_CHANGE_DEBOUNCE_TIME),
              tap(() => {
                this.isDebouncing = false;
              })
            )
            .subscribe(value => {
              this.updateValue(tab, field, value);
            });
        }

        if (field.options) {
          // Set select option properties for any not previously prepared (all fields onInit and some fields onChanges)
          if (Array.isArray(field.options) && field.options.length > 0) {
            const firstOption = field.options[0];
            if (!field.idProp) {
              const idPropSuggestions = ['id', 'value'];
              idPropSuggestions.reverse().forEach(suggestion => {
                if (firstOption[suggestion]) {
                  field.idProp = suggestion;
                }
              });
            }
            if (!field.labelProp) {
              const labelPropSuggestions = ['label', 'name', 'text'];
              labelPropSuggestions.reverse().forEach(suggestion => {
                if (firstOption[suggestion]) {
                  field.labelProp = suggestion;
                }
              });
            }
            // Set checkbox list UI model if not yet defined
            this.ui = this.ui || {};
            if (field.type === this.formElements.CheckboxList && !this.ui[field.id] && this.data[field.id]) {
              this.ui[field.id] = {};
              field.options.forEach(option => {
                this.ui[field.id][option.id] === this.data[field.id].includes(option.id);
              });
            }
          }
        }

        this.initMultiSelect(field);
      });
      if (isNew) {
        this.wizardForm.addControl(tab.formGroupName, tabFormGroup);
      }
    });
  }

  setTab(tabId: number) {
    this.activeTabId = tabId;
    this.tabHistory.push(tabId);
    this.updateTabs();
    this.updateControls();

    this.wizard.addDataToNewTabs(this.tabs, this.activeTabId).subscribe(wizardTabs => {
      if (!wizardTabs.alreadyLoaded) {
        this.tabsChange.emit(wizardTabs.tabs);
      }
    });
  }

  getAdjacentTab(value: number) {
    const currentTab = this.tabs.find(tab => tab.id === this.activeTabId);
    const currentTabIndex = this.visibleTabs().indexOf(currentTab);
    return this.visibleTabs()[currentTabIndex + value];
  }

  toggleTab(value: number) {
    const nextTab = this.getAdjacentTab(value);
    /* Tab will be enabled if previous tabs not invalid - however it displays not disabled if pending
    meaning a user could quickly invalidate a field and then click next - so we need to block toggle
    unless form on next page is valid */
    const {
      wizardForm: { value: formValue },
      tabHistory,
      tabs
    } = this;
    const isValid = this.visibleTabs()
      .filter(otherTab => otherTab.id < nextTab.id)
      .every(previousTab => this.wizardForm?.get(previousTab.formGroupName)?.valid);
    if (isValid) {
      this.setTab(nextTab.id);
    }
  }

  save() {
    this.isSaving = true;
    const exportData = this.wizard.formatData(this.data, this.tabs);
    this.onSave.emit(exportData);
    if (this.isModal) {
      // Reset data once parent has closed modal
      setTimeout(() => {
        this.resetData();
      });
    }
  }

  backDisabled() {
    // back disabled if no previous tabs, or in expanded view of form element
    const previousTab = this.getAdjacentTab(-1);
    return !previousTab;
  }

  nextDisabled() {
    // next disabled if no more tabs, or in expanded view of form element
    const nextTab = this.getAdjacentTab(1);
    return !nextTab || nextTab.isDisabled;
  }

  saveDisabled() {
    // save disabled if readonly (and no fields are visible and enabled due to ignoreReadOnly prop), saving, debouncing,
    // form invalid, not all tabs viewed, or in expanded view of form element.
    const anyFieldsVisibleAndEnabled = this.tabs.some(tab =>
      tab.fields.some(field => !field.isHidden && this.enableField(field))
    );
    return (
      (this.readOnly && !anyFieldsVisibleAndEnabled) ||
      this.isSaving ||
      this.isDebouncing ||
      (this.mergeTabs && this.wizardForm?.invalid) ||
      (!this.mergeTabs &&
        this.visibleTabs().some(
          tab => this.wizardForm?.get(tab.formGroupName)?.invalid || !this.tabHistory.includes(tab.id)
        ))
    );
  }

  private checkForRule(ruleSet: FieldRules, fieldId: string) {
    return Array.isArray(ruleSet) && ruleSet.find(rule => rule.property === fieldId);
  }

  private applyRuleProviders(targetFieldRuleSets: FieldRules[]) {
    targetFieldRuleSets.forEach(ruleSet => {
      ruleSet.forEach(rule => {
        if (rule.valuesProvider) {
          const wizardFieldProvider = this.wizard
            .getAllFields(this.tabs)
            ?.find(field => field.id == rule.valuesProvider);
          if (wizardFieldProvider?.options) {
            const options: Options = wizardFieldProvider.options;
            rule.values = (rule.valuesProviderFilterFn ? rule.valuesProviderFilterFn(options) : options)?.map(
              option => {
                return option.id;
              }
            );
          }
        }
      });
    });
  }

  updateValue(tab: WizardTab, field: WizardField, value) {
    const fieldId = field.id;
    const formGroupName = tab.formGroupName;
    const { id = null } = { ...value };
    value = id ?? value;

    setTimeout(() => {
      if (tab.isNest) {
        const nest = this.data[formGroupName] || {};
        nest[fieldId] = value;
        this.data[formGroupName] = nest;
      } else {
        this.data[fieldId] = value;
      }
      // Trigger changes in child
      this.data = { ...this.data };
      if (field.type?.manualValidation) {
        setTimeout(() => {
          // Allow for 0 as a valid value but consider other falsy values as null
          this.wizardForm
            .get(formGroupName)
            ?.get(fieldId)
            ?.patchValue(value || value === 0 ? value : null);
        }, 0);
      }

      // Convert flattened data to object when passing back up
      const unFlattenedData = { ...this.data };

      // Validate related field
      this.tabs.forEach(otherTab => {
        otherTab.fields
          .filter(otherField => otherField.id !== fieldId)
          .forEach(otherField => {
            const existingControl = this.wizardForm.get(otherTab.formGroupName)?.get(otherField.id);
            if (existingControl) {
              this.setFieldEnabled(otherField, existingControl);
            }

            const providerTypes = ['min', 'max', 'minHoursMinutes', 'maxHoursMinutes'];
            const providesFor = providerTypes.some(type => otherField[type + 'Provider'] === fieldId);

            const otherfieldRuleSets: FieldRules[] = [];

            this.checkForRule(otherField.displayIf, field.id) ? otherfieldRuleSets.push(otherField.displayIf) : null;
            this.checkForRule(otherField.hideIf, field.id) ? otherfieldRuleSets.push(otherField.hideIf) : null;
            this.checkForRule(otherField.enableIf, field.id) ? otherfieldRuleSets.push(otherField.enableIf) : null;
            this.checkForRule(otherField.requiredIf, field.id) ? otherfieldRuleSets.push(otherField.requiredIf) : null;
            this.checkForRule(otherField.requiredIfAny, field.id)
              ? otherfieldRuleSets.push(otherField.requiredIfAny)
              : null;

            if (otherfieldRuleSets.length > 0) {
              this.applyRuleProviders(otherfieldRuleSets);
            }

            if (providesFor || otherfieldRuleSets.length > 0) {
              setTimeout(() => {
                existingControl?.updateValueAndValidity();
              });
            }

            // Lock selected item in provider field
            if (field.options && field.optionProvider === otherField.id) {
              otherField.lockedItemId = value;
            }

            this.updateRelatedField(field, otherField, otherTab, value);

            if (field.id) {
              const nestArray = field.id.split(CommonChars.Hyphen);
              if (nestArray.length === 2) {
                delete unFlattenedData[field.id];
                if (!unFlattenedData[nestArray[0]]) {
                  unFlattenedData[nestArray[0]] = {};
                }
                unFlattenedData[nestArray[0]][nestArray[1]] = this.data[field.id];
              }
            }
          });
      });

      if (isDebug('event-wiz')) {
        console.log({ unFlattenedData });
      }

      this.onDataChange.emit(unFlattenedData);
    });
  }

  updateRelatedFields(field: WizardField, value) {
    // Set options for another field
    setTimeout(() => {
      this.tabs.forEach(otherTab => {
        otherTab.fields.forEach(otherField => {
          this.updateRelatedField(field, otherField, otherTab, value);
        });
      });
    });
  }

  private updateRelatedField(field: WizardField, otherField: WizardField, otherTab: WizardTab, value) {
    if (otherField.defaultProvider === field.id) {
      this.updateOtherField(otherTab, otherField, value);
    }

    if (otherField.defaultProviderStart === field.id && value?.startDate) {
      this.updateOtherField(otherTab, otherField, this.mapDateToTime(value.startDate));
    }

    if (otherField.defaultProviderEnd === field.id && value?.endDate) {
      this.updateOtherField(otherTab, otherField, this.mapDateToTime(value.endDate));
    }

    if (field.updateValueOnChange?.fieldId && field.updateValueOnChange.fieldId === otherField.id) {
      const newValue = field.updateValueOnChange?.updateValueFn(this.data);
      this.updateOtherField(otherTab, otherField, newValue);
    }

    const otherFieldOptions = otherField.options;
    const hasRelatedOptionRule =
      otherFieldOptions &&
      otherFieldOptions.some(
        option =>
          option.disableIf &&
          option.disableIf.find(rule => {
            return rule.property === field.id;
          })
      );

    if (hasRelatedOptionRule) {
      otherField.options = otherFieldOptions.map(option => {
        return {
          ...option,
          isDisabled: option.disableIf && this.wizard.requirementsMatched(option.disableIf, this.data)
        };
      });

      otherField.enabledOptions = otherField.options.filter(option => !option.isDisabled);
    }

    if (otherField.dateRangeLimit) {
      if (otherField.dateRangeLimit.minLimit?.providerFieldId === field.id) {
        this.setDateRangeLimit(otherField.dateRangeLimit.minLimit, value);
      }

      if (otherField.dateRangeLimit.maxLimit?.providerFieldId === field.id) {
        this.setDateRangeLimit(otherField.dateRangeLimit.maxLimit, value);
      }
    }
  }

  private updateOtherField(updateTab: WizardTab, updateField: WizardField, updateValue) {
    const otherControl = this.wizardForm?.get(updateTab.formGroupName)?.get(updateField.id);

    if (otherControl?.untouched) {
      otherControl.patchValue(updateValue, { emitEvent: false });
      this.data[updateField.id] = updateValue;
      this.updateRelatedFields(updateField, updateValue);
    }
  }

  @momentParamsConverter
  private mapDateToTime(date: Date) {
    return {
      hour: date.getHours(),
      minute: date.getMinutes()
    };
  }

  private setDateRangeLimit(limit: DateRangeLimit, value) {
    limit.limitValue =
      limit.useProviderStartValue && value?.startDate
        ? this.getDate(value.startDate)
        : this.getDate(value.endDate) ?? null;
  }

  private getDate(date) {
    if (typeof date?.toDate === 'function') {
      return date.toDate();
    }
    return date;
  }

  updateTabs() {
    this.tabs.forEach(tab => {
      tab.isTemplateHidden = this.isTabHidden(tab);
      tab.isDone = this.isTabDone(tab);
      tab.isDisabled = this.isTabDisabled(tab);
    });

    // This is a bit of a bodge. Leaving it out was causing ExpressionChangedAfterItHasBeenCheckedError errors when using toggles
    // to enable/disable fields. This could probably do with a fairly major reworks so that the funcs i.e. isTabDone are used in the
    // template and removing all usages of setTimeout in here.
    this.changeDetector.detectChanges();
  }

  setFieldAsTouched(tab: WizardTab, field: WizardField) {
    this.wizardForm?.get(tab.formGroupName)?.get(field.id)?.markAsTouched();
  }

  isFieldTouched(tab: WizardTab, field: WizardField): boolean {
    return this.wizardForm?.get(tab.formGroupName)?.get(field.id).touched;
  }

  isTabDone(tab: WizardTab) {
    /* Check if tabs with required fields are complete and whether optional
    tabs have been viewed by the user */
    return this.mergeTabs || (this.tabHistory.includes(tab.id) && this.wizardForm?.get(tab.formGroupName)?.valid);
  }

  isTabDisabled(tab: WizardTab) {
    // For testing
    // return false
    // Can always go backwards
    if (tab.id < this.activeTabId) {
      return false;
    }
    // A tab is disabled unless all previous tabs are valid
    return this.visibleTabs()
      .filter(otherTab => otherTab.id < tab.id)
      .some(previousTab => this.wizardForm?.get(previousTab.formGroupName)?.invalid);
  }

  isTabHidden(tab: WizardTab) {
    if (tab.isHidden) {
      return true;
    }
    if (tab.isConfirmation) {
      return false;
    }
    return tab.fields.every(field => !this.showField(field));
  }

  visibleTabs(): WizardTabs {
    return this.tabs.filter(tab => !this.isTabHidden(tab));
  }

  requiredValidator(field: WizardField, control: UntypedFormControl): AsyncValidatorFn {
    return (): Observable<ValidationErrors | null> => {
      return of(null)
        .pipe(
          map(() => {
            const isRequired = this.isFieldRequired(field);

            // Check if required
            if (!isRequired) return null;
            // Check if has value
            const value = control.value;
            // 0 is considered to be a valid value for some dropdowns
            if (!Array.isArray(value) && (value || value === 0)) return null;
            if (value?.length) return null;

            // Return validation error
            return { required: true };
          })
        )
        .pipe(first());
    };
  }

  crossFieldValidator(field: WizardField, providerFieldId: string, type: number): AsyncValidatorFn {
    return (): Observable<ValidationErrors | null> => {
      return of(null)
        .pipe(
          map(() => {
            if (!this.showField(field)) {
              return null;
            }
            let sourceValue;
            let targetValue;
            this.tabs.forEach(otherTab =>
              otherTab.fields.forEach(otherField => {
                if (otherField.id === providerFieldId) {
                  sourceValue = this.wizardForm?.get(otherTab.formGroupName)?.get(otherField.id)?.value;
                }
                if (otherField.id === field.id) {
                  targetValue = this.wizardForm?.get(otherTab.formGroupName)?.get(field.id)?.value;
                }
              })
            );
            if (type === CrossFieldValidationEnum.Min) {
              return targetValue >= sourceValue ? null : { min: sourceValue };
            }
            if (type === CrossFieldValidationEnum.Max) {
              return targetValue <= sourceValue ? null : { max: sourceValue };
            }
            if (type === CrossFieldValidationEnum.MinHoursMinutes) {
              const sourceEnd = sourceValue?.endDate || sourceValue;
              if (!sourceEnd) {
                return null;
              }
              const minHoursOver = targetValue?.hour > sourceEnd.getHours();
              const minMinutesMatch =
                targetValue?.hour === sourceEnd.getHours() && targetValue?.minute >= sourceEnd.getMinutes();
              return minHoursOver || minMinutesMatch
                ? null
                : { minHoursMinutes: formatDate(sourceEnd, DateFormats.Time, 'en-US') };
            }
            if (type === CrossFieldValidationEnum.MaxHoursMinutes) {
              const sourceStart = sourceValue?.startDate || sourceValue;
              if (!sourceStart) {
                return null;
              }
              const maxHoursUnder = targetValue?.hour < sourceStart.getHours();
              const maxMinutesMatch =
                targetValue?.hour === sourceStart.getHours() && targetValue?.minute <= sourceStart.getMinutes();
              return maxHoursUnder || maxMinutesMatch
                ? null
                : { maxHoursMinutes: formatDate(sourceStart, DateFormats.Time, 'en-US') };
            }
            if (type === CrossFieldValidationEnum.Match) {
              return sourceValue === targetValue ? null : { match: true };
            }
            return null;
          })
        )
        .pipe(first());
    };
  }

  htmlMinLengthValidator(field: WizardField) {
    return (control: AbstractControl) => {
      const textValue = control?.value?.replace(/<[^>]*>?/gm, '');
      return textValue?.length >= field.minlength ? null : { minlength: field.minlength };
    };
  }

  dateRangeValidator(control: UntypedFormControl): AsyncValidatorFn {
    return (): Observable<ValidationErrors | null> => {
      return of(null).pipe(
        map(() => {
          const value = control.value;
          // Check end date is after start date
          if (value && isAfter(value.startDate, value.endDate)) {
            return {
              dateRangeInvalid: true
            };
          }
          return null;
        })
      );
    };
  }

  isFieldRequired(field: WizardField) {
    // Not required if not shown
    if (!this.showField(field)) {
      return false;
    }
    // Required if set
    if (field.isRequired) {
      return true;
    }
    // Conditional required based on other fields
    if (field.requiredIf && Array.isArray(field.requiredIf)) {
      return field.requiredIf.every(item => !this.wizard.unmatchedRequirement(item, this.data));
    }
    if (field.requiredIfAny && Array.isArray(field.requiredIfAny)) {
      return field.requiredIfAny.some(item => !this.wizard.unmatchedRequirement(item, this.data));
    } else {
      return false;
    }
  }

  openUrl(url: string) {
    window.open(this.urlService.createAbsoluteUrl(url));
  }

  openTwitterHandle(handle: string) {
    window.open(this.twitter.getTwitterUrl(handle));
  }

  getDataStyle(field) {
    // Get style of data element
    let columnsString = '';
    const columnCount = field.dataCols || 2;
    for (let i = 0; i < columnCount; i++) {
      columnsString += (i > 0 ? ' ' : '') + '1fr';
    }
    return {
      'grid-template-columns': columnsString
    };
  }

  getButtonClass(tab, fieldId, option): string {
    return `btn btn-${
      (option.buttonClass || 'info') +
      (option.customClass ? ' ' + option.customClass : '') +
      (this.getValue(tab, fieldId) === option.value ? ' active' : '')
    }`;
  }

  buttonClick(fieldId: string) {
    this.onButtonClick.emit(fieldId);
  }

  getValidationMessage(tab: WizardTab, field: WizardField): string {
    const fieldId = field.id;
    const formControl = this.wizardForm?.get(tab.formGroupName).get(fieldId);
    if (!formControl) {
      return '';
    }
    if (formControl.untouched) {
      return '';
    }
    if (formControl.hasError('min') && field.type !== formElements.Currency) {
      return `Value must be at least ${formControl.errors.min.min}`;
    }
    if (field.type === formElements.Currency && formControl.hasError('min')) {
      if (!field.allowZero) {
        return 'Value must be more than zero';
      } else {
        return 'Value must be positive';
      }
    }
    if (formControl.hasError('max')) {
      return `Value must be no more than ${formControl.errors.max.max}`;
    }
    if (formControl.hasError('minHoursMinutes')) {
      return `Time must be at or after the end of the activity ${formControl.errors.minHoursMinutes}`;
    }
    if (formControl.hasError('maxHoursMinutes')) {
      return `Time must be at or before the start of the activity ${formControl.errors.maxHoursMinutes}`;
    }
    if (formControl.hasError('match')) {
      return `Fields do not match`;
    }
    // If rule list, show the rest in there instead
    if (field.patternList) {
      return '';
    }
    if (formControl.hasError('required')) {
      return 'This field is required';
    }
    if (formControl.hasError('pattern') || formControl.hasError('email')) {
      let validationMessage;
      this.tabs.forEach(tab => {
        validationMessage =
          validationMessage || tab.fields.find(field => field.id === fieldId)?.type.patternErrorMessage;
      });
      return validationMessage || 'This value is not in the correct format';
    }
    if (formControl.hasError('minlength')) {
      return `The minimum length for this field is ${formControl.errors.minlength.requiredLength || formControl.errors.minlength} characters`;
    }
    if (formControl.hasError('dateRangeInvalid')) {
      return 'End time must be after the start time';
    }
    return '';
  }

  getFormattedRuleList(tab: WizardTab, field: WizardField): FieldRuleList {
    const items = [];
    const formControl = this.wizardForm?.get(tab.formGroupName).get(field.id);
    const value = formControl.value;
    if (field.minlength) {
      items.push({
        label: `At least ${field.minlength} characters.`,
        isComplete: value && !formControl.hasError('minlength')
      });
    }
    if (field.patternList) {
      field.patternList.forEach(rule => {
        items.push({
          label: rule.label,
          isComplete: value?.match(rule.regexValue)
        });
      });
    }
    return items;
  }

  getFormGroup(formGroupName: string): UntypedFormGroup {
    return this.wizardForm?.get(formGroupName) as UntypedFormGroup;
  }

  clickSecondaryButton() {
    this.onSecondaryButtonClick.emit();
  }

  clickDangerButton() {
    this.onDangerButtonClick.emit();
  }

  onSelect(tab: WizardTab, field: WizardField, option) {
    let newValue;
    if (!field.isMultiOption) {
      newValue = option.id;
    } else if (Array.isArray(this.data[field.id])) {
      if (this.data[field.id].find(item => item === option.id)) {
        newValue = this.data[field.id].filter(item => item !== option.id);
      } else {
        newValue = [...this.data[field.id], option.id];
      }
    }
    this.updateValue(tab, field, newValue);
  }

  onDeselect(tab: WizardTab, field: WizardField, option) {
    if (!field.isMultiOption) {
      this.updateValue(tab, field, null);
    } else {
      const newValue = this.getValue(tab, field).filter(item => item !== option.id);
      this.updateValue(tab, field, newValue);
    }
  }

  initMultiSelect(field: WizardField) {
    if (field.type?.isMultiSelectComponent) {
      field.dropdownSettings = {
        singleSelection: !field.isMultiOption,
        allowSearchFilter: field.type === formElements.SearchAdd || field.options?.length > 5,
        idField: field.idProp || 'id',
        textField: field.labelProp || 'name',
        closeDropDownOnSelection: !field.isMultiOption
      };
    }
  }

  getMultiSelectPlaceholder(tab: WizardTab, field: WizardField) {
    if (field.isMultiOption) {
      // TODO: Need to handle this case
    }
    const currentValue = this.getValue(tab, field);
    if (currentValue == null) {
      return field.placeholder || 'SB_Select';
    }
    const currentOptionName = field.options.find(option => option.id === currentValue)?.name;
    return currentOptionName;
  }

  onDatePickerChange(tab: WizardTab, field: WizardField, $event) {
    this.updateValue(tab, field, $event);
    this.setFieldAsTouched(tab, field);
  }

  getControl(tab: WizardTab, field: WizardField) {
    return this.getFormGroup(tab.formGroupName)?.get(field.id);
  }

  getValue(tab: WizardTab, field: WizardField) {
    const data = this.data;

    if (tab?.isNest && data[tab.formGroupName]) {
      return data[tab.formGroupName][field.id];
    }

    return data[field.id] ?? '';
  }

  updateAndProvide(tab: WizardTab, field: WizardField, value) {
    const valueOptions = value.options;

    this.updateOtherFieldFromOptionProvider(tab, field, valueOptions);

    this.updateValue(tab, field, value.ids);

    this.updateOtherFieldSummary(tab, field, valueOptions);
  }

  updateOtherFieldFromOptionProvider(tab: WizardTab, field: WizardField, valueOptions): void {
    // shouldn't this look for multiple other fields which have this field as an option provider?
    const provideTarget = tab.fields.find(otherField => otherField.optionProvider === field.id);
    if (provideTarget) {
      provideTarget.options = valueOptions;
      this.initMultiSelect(provideTarget);
    }
  }

  updateOtherFieldSummary(tab: WizardTab, field: WizardField, valueOptions): void {
    const summary = tab.fields.find(otherField => otherField.summaryOf === field.id);
    if (summary) {
      this.wizardForm.get(tab.formGroupName)?.get(summary.id)?.patchValue(valueOptions);
    }
  }

  showRelatedField(tab: WizardTab, field: WizardField) {
    tab.fields.forEach(otherField => {
      if (otherField.id === field.summaryOf) {
        otherField.isToggledOn = !otherField.isToggledOn;
      }
    });
  }

  isHiddenByToggle(field: WizardField) {
    return field.isToggledOn === false;
  }

  onToggle(tab: WizardTab, field: WizardField, $event: boolean) {
    field.isToggledOn = $event;
    const formControl = this.wizardForm.get(tab.formGroupName).get(field.id);
    if (field.isToggledOn) {
      formControl.enable();
    } else {
      formControl.disable();
    }
    formControl.updateValueAndValidity();
  }

  // SubWizard

  openSubWizard(field: WizardField, recordId?: number) {
    if (recordId) {
      this.isLoadingSubWizardRecord = true;
      field.subWizardConfig?.loadRecordFn(recordId).subscribe(result => {
        this.subWizardRecordId = recordId;
        this.subWizardData = result;
        this.isLoadingSubWizardRecord = false;
      });
    } else {
      delete this.subWizardData;
      delete this.subWizardRecordId;
    }
    this.subWizardField = field;
    const title = recordId ? field.subWizardConfig?.subWizardEditTitle : field.subWizardConfig?.subWizardNewTitle;
    const translatedLabel = this.translate.instant(field.label);
    const translatedTitle = this.translate.instant(title);
    this.subWizardTitle = translatedTitle ? `${translatedTitle} (${translatedLabel})` : translatedLabel;
    this.modal
      .open(this.subWizardModal, { ...WizardModalSettings, modalDialogClass: 'mt-5 pt-5', size: 'lg' })
      .result.then();
  }

  saveSubWizard(data, modal: NgbModalRef) {
    this.isSavingSubWizard = true;

    const exportData = this.wizard.formatData(data, this.subWizardField.subWizardConfig?.subWizardTabs);
    const subWizardSave: SubWizardSave = {
      data: exportData,
      fieldId: this.subWizardField.id,
      activeTabId: this.activeTabId
    };

    this.wizard.onSubWizardSave(subWizardSave, this.tabs, this.data).subscribe(updateRes => {
      this.tabsChange.emit(updateRes.tabs);
      this.data = { ...updateRes.data };
      this.onDataChange.emit(updateRes.data);
      this.isSavingSubWizard = false;
    });

    modal.close();
  }

  closeSubWizard(modal: NgbModalRef) {
    this.subWizardData = {};
    modal.close();
  }

  showField(field: WizardField) {
    return this.wizard.showField(field, this.data);
  }

  enableField(field: WizardField) {
    if (this.readOnly && !field.ignoreReadOnly) {
      return false;
    }

    return this.wizard.enableField(field, this.data);
  }

  getFieldValidationState(tab: WizardTab, field: WizardField): ValidationState {
    const formControl = this.wizardForm.get(tab.formGroupName)?.get(field.id);

    if (!this.showField(field) || !formControl) {
      return ValidationStates.Default;
    }

    const isInvalid = formControl.invalid;
    const value = this.getValue(tab, field);

    // 0 is a valid value for some dropdowns so can't do a simple falsy check here.
    if (this.isFieldRequired(field) && (value == null || value === '')) {
      if (formControl.touched) {
        return ValidationStates.RequiredTouched;
      } else {
        return ValidationStates.RequiredUntouched;
      }
    } else if (isInvalid) {
      return ValidationStates.Invalid;
    } else if (value || value === 0) {
      return ValidationStates.Ok;
    } else {
      return ValidationStates.Default;
    }
  }

  // Confirmation

  getFieldConfirmationValue(field: WizardField) {
    const tab =
      this.tabs.find(tab => tab.fields.find(tabField => tabField.id === field.id)) ||
      field.subWizardConfig?.subWizardTabs?.find(tab => tab.fields.find(tabField => tabField.id === field.id));
    const value = this.getValue(tab, field);
    if (value) {
      if (field.options) {
        return field.options.find(option => option.id === value).name;
      }
      if (field.type?.isDatePicker) {
        const startDate = value?.startDate;
        const endDate = !field.type.isSingleDate && value?.endDate;
        const formatDate = (date, format) => this.dateTime.formatDate(date, format);
        const startDayFormatted = formatDate(startDate, DateFormats.Date);
        const endDayFormatted = formatDate(endDate, DateFormats.Date);
        let dateString = startDayFormatted;
        if (endDate && startDayFormatted !== endDayFormatted) dateString += ` - ${endDayFormatted}`;
        if (field.type?.hasTime) {
          dateString += ` (${formatDate(startDate, DateFormats.Time)}`;
          if (endDate) dateString += ` - ${formatDate(endDate, DateFormats.Time)}`;
          dateString += ')';
        }
        return dateString;
      }
      if (field.type === formElements.Time) {
        return `${('00' + value.hour).slice(-2)}:${('00' + value.minute).slice(-2)}`;
      }
      if (field.type === formElements.UserGroupings) {
        let string = '';
        if (value.clubIds?.length > 0) {
          string += `${this.translate.instant('SB_Clubs')}: ${value.clubIds.length}<br>`;
        }
        if (value.teamIds?.length > 0) {
          string += `${this.translate.instant('SB_Teams')}: ${value.teamIds.length}<br>`;
        }
        if (value.subjectClassIds?.length > 0) {
          string += `${this.translate.instant('SB_SubjectClasses')}: ${value.subjectClassIds.length}<br>`;
        }
        return string;
      }
      if (field.type === formElements.Switch) {
        return field.trueLabel || 'SB_Yes';
      }
      if (field.type === formElements.Currency) {
        return this.currencySymbol + value;
      }

      if (Array.isArray(value)) {
        return value.length;
      }

      return value;
    }
    if (field.type === formElements.Switch) {
      return field.falseLabel || 'SB_No';
    }
    return field.placeholder && field.confirmPlaceholder ? field.placeholder : 'Not set';
  }

  confirmationImageCheck() {
    // Only show confirmation tab image if no fields
    const confirmationTab = this.tabs.find(tab => tab.isConfirmation);
    return !confirmationTab || this.activeTabId !== confirmationTab.id || confirmationTab.fields.length === 0;
  }

  isConfirmation() {
    return this.tabs.find(tab => tab.isConfirmation && tab.id === this.activeTabId);
  }

  isFullWidthConfirmation() {
    return (
      this.isConfirmation() &&
      !this.showField(this.wizard.getAllFields(this.tabs).find(field => field.type === formElements.GameSummary))
    );
  }

  isFieldInline(field: WizardField) {
    return field.isInline || field.toggleMessage !== undefined;
  }

  clickCancelButton() {
    this.onCancel.emit();
  }

  getFieldEnabledOptions(field: WizardField) {
    return field.enabledOptions || field.options;
  }

  confirmClose() {
    if (this.tabs.filter(tab => !tab.isConfirmation).length < 2) {
      this.close();
      return;
    }
    this.confirmDialog.confirmThis(
      {
        title: this.discardTitle || 'SB_Discard',
        text: this.discardMessage || 'SB_Discard_New_Record',
        isDanger: true
      },
      () => {
        this.close();
      },
      () => {
        // Leave open
      }
    );
  }

  private close() {
    this.resetData();
    this.onClose.emit();
  }

  showLoadingMessage() {
    return this.isSaving && this.loadingMessage;
  }

  resetData() {
    this.data = {};
    this.subWizardData = {};
    this.tabHistory = [];
    this.tabs.forEach(tab => (tab.isDone = false));
    this.wizard.getAllFields(this.tabs).forEach(field => {
      if (field.optionProvider && field.options) {
        field.options = null;
      }
      if (field.isToggledOn) {
        field.isToggledOn = false;
      }
    });
    this.onDataChange.emit(null);
  }

  optionsLoading(field: WizardField) {
    const optionsToLoad = field.loadOptions && !field.options && !field.type?.isVisualSelect;
    const optionsToUpdate = field.subWizardConfig?.subWizardTabs && this.isSavingSubWizard;
    return optionsToLoad || optionsToUpdate;
  }

  disabledAttrValue(val: boolean) {
    return val ? '' : null;
  }

  private getNestedValueFromField({ nestedField }: WizardField) {
    if ((nestedField?.length ?? 0) === 0) {
      return null;
    }

    if (this.data[nestedField[0]] && nestedField.length === 2) {
      return this.data[nestedField[0]][nestedField[1]];
    }
  }
}
