'use strict';

angular.module('events.components.consentEventInvites',
    [
        'shared.components.sbOnOffSwitch',
        'events.services.eventsService',
        'shared.services.blobStoragePhotosService',
        'shared.services.cachedLookupService',
        'shared.services.searchService',
        'shared.services.simpleAlertService',
    ])
    .component('consentEventInvites', {
        templateUrl: '/Scripts/app/events/components/consent-event-invites.template.html',
        bindings: {
            eventId: '<',
            settingsDisabled: '<'
        },
        controller: [
            '$filter',
            '$timeout',
            '$translate',
            '$uibModal',
            'blobStoragePhotosService',
            'cachedLookupService',
            'eventsService',
            'personPickerService',
            'searchService',
            'simpleAlertService',
            function consentEventInvitesController(
                $filter,
                $timeout,
                $translate,
                $uibModal,
                blobStoragePhotosService,
                cachedLookupService,
                eventsService,
                personPickerService,
                searchService,
                simpleAlertService)
            {
                //Table Sorting
                this.sortType = 'name';
                this.sortReverse = false;

                this.students = [];
                this.notifyPeopleTypes = [];
                this.event = null;
                this.isSendEnabled = false;
                this.currentFilter = '';
                this.numberOfInvitesToSend = 0;
                this.isLoading = false;

                this.exportInfo = [];
                this.exportData = [];

                this.filter = {
                    selectedSchoolYearNames: [],
                    text: ''
                };

                this.selectedSchoolYearNames = [];
                this.selectedSchoolYearNameIds = [];
                this.uniqueSchoolYearNames = [];

                this.showDob = false;
                this.showClass = true;
                this.showYear = true;

                var systemSettingsLoaded = false;

                this.xsShowSearch = false;

                this.translationsLoaded = false;

                // Save the config settings to remember filter preferences
                this.saveUserSettings = function ()
                {
                    cachedLookupService
                        .saveUserSettings({
                            'Reception_Register_ShowClass': this.showClass.toString().toLowerCase(),
                            'Reception_Register_ShowYear': this.showYear.toString().toLowerCase(),
                            'Reception_Register_ShowDOB': this.showDob.toString().toLowerCase()
                        });
                }.bind(this);

                if (!systemSettingsLoaded)
                {
                    cachedLookupService
                        .getSystemSettings([
                            'Reception_Register_ShowClass',
                            'Reception_Register_ShowYear',
                            'Reception_Register_ShowDOB'
                        ])
                        .then(function (settings)
                        {
                            this.systemSettings = settings;

                            this.showDob = settings['Reception_Register_ShowDOB'].toString().toLowerCase() === 'true';
                            this.showClass = settings['Reception_Register_ShowClass'].toString().toLowerCase() === 'true';
                            this.showYear = settings['Reception_Register_ShowYear'].toString().toLowerCase() === 'true';

                            systemSettingsLoaded = true;

                        }.bind(this));
                }

                this.$onInit = function ()
                {
                    // Configure the multi-select drop down control
                    this.dropdownSettings =
                    {
                        buttonClasses: 'btn btn-default pl-2 pr-2',
                        checkBoxes: true,
                        keyboardControls: true,
                        showUncheckAll: false,
                        showCheckAll: false,
                        smartButtonMaxItems: 3,
                        smartButtonTextConverter: function (itemText) { return itemText; }
                    };
                }.bind(this);

                this.$onChanges = function (changes)
                {
                    if (changes.eventId && changes.eventId.currentValue)
                    {
                        this.loadNotifyPeopleTypes();
                        this.loadStudents(this.eventId);
                    }
                }.bind(this);

                this.loadStudents = function (eventId)
                {
                    eventsService.getConsentEventInvitesData(eventId)
                        .then(function (response)
                        {
                            this.consentRequiredFromPersonType = response.consentRequiredFromPersonType;
                            this.notifyPersonType = response.notifyPersonType;
                            this.notifyTypeId = response.notifyTypeId;
                            this.consentMessage = response.consentMessage;
                            this.consentDeadline = response.consentDeadline;
                            this.maximumPlaces = response.maximumPlaces;
                            this.fee = response.fee;
                            this.currencySymbol = response.currencySymbol;
                            this.isAdditionalSelectionRequired = response.isAdditionalSelectionRequired;

                            this.students = response.students;

                            /* The below is not needed as the photos are not shown in this component
                            blobStoragePhotosService.addStudentPhotoUrlsToArray(this.students)
                                .then(function (array)
                                {
                                    this.students = array;
                                }.bind(this));
                            */

                            this.numberConsented = this.students.filter(function (student)
                            {
                                return student.hasConsented;
                            }).length;

                            this.numberRejected = this.students.filter(function (student)
                            {
                                return student.isConsentRejected;
                            }).length;

                            this.numberAwaitingResponse = this.students.filter(function (student)
                            {
                                return student.isConsentAwaited;
                            }).length;

                            // Clone this.students for use when filtering
                            this.unfilteredStudents = JSON.parse(JSON.stringify(this.students));
                            //TODO try angular.copy as Mike suggested

                            this.getUniqueSchoolYearNames();

                            eventsService.getEventBasics(eventId)
                                .then(function (response)
                                {
                                    this.exportInfo.push(
                                        {
                                            label: this.eventLabel,
                                            text: response.title
                                        });

                                    this.pageTitle = this.eventLabel + ': ' + response.title;
                                }.bind(this))
                                .catch(function (error)
                                {
                                    simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                                }.bind(this));

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));

                }.bind(this);

                this.getUniqueSchoolYearNames = function ()
                {
                    var uniqueSchoolYearNameItems = $filter('unique')(this.students, 'schoolYearName');

                    this.uniqueSchoolYearNames = uniqueSchoolYearNameItems
                        .filter(item => item.schoolYearName?.length)
                        .map(item => ({
                            label: item.schoolYearName,
                            id: item.schoolYearName
                        }));

                    this.uniqueSchoolYearNames.sort(this.compareSchoolYearNames);
                    this.schoolYearNameFilter = this.uniqueSchoolYearNames[0];
                }.bind(this);

                $translate.onReady().then(function ()
                {
                    this.eventLabel = $filter('translate')('SB_Event');
                    this.selectYearsText = $translate.instant('SB_Select_Years');

                    // TODO in separate task
                    this.exportTableHeaders = [
                        $filter('translate')('SB_Name'),
                        $filter('translate')('SB_Diary_And_Register'),
                        $filter('translate')('SB_Notified')
                    ];

                    this.translationsLoaded = true;
                }.bind(this));

                this.compareSchoolYearNames = function (a, b)
                {
                    // Use toUpperCase() to ignore character casing
                    var schoolIdA = a.id.toUpperCase();
                    var schoolIdB = b.id.toUpperCase();

                    var comparison = 0;

                    if (schoolIdA > schoolIdB)
                    {
                        comparison = 1;
                    }
                    else if (schoolIdA < schoolIdB)
                    {
                        comparison = -1;
                    }

                    return comparison;
                }

                this.loadNotifyPeopleTypes = function ()
                {
                    eventsService.getNotifyAudienceTypes(false)
                        .then(function (response)
                        {
                            this.notifyPeopleTypes = response.notifyAudienceTypes;

                        }.bind(this))
                        .catch(function (error)
                        {
                            simpleAlertService.errorAlert({ message: 'SB_Error_loading' });

                        }.bind(this));
                }.bind(this);

                this.toggleInviteStudent = function (student)
                {
                    if (student.calendarEventAttendeeStatusId == 2)
                    {
                        return;
                    }

                    student.sendInvite = !student.sendInvite;

                    if (student.sendInvite)
                    {
                        this.numberOfInvitesToSend++;
                    }
                    else
                    {
                        this.numberOfInvitesToSend--;
                    }

                    this.isSendEnabled = this.numberOfInvitesToSend > 0;
                }.bind(this);

                this.toggleInviteAll = function (selectAll)
                {
                    // TODO implement in separate task SB-3369

                }.bind(this);

                this.manualYes = function (student)
                {
                    if (student.hasAccepted)
                    {
                        return;
                    }

                    var notSetByParentWarningModalInstance = $uibModal.open(
                        {
                            animation: true,
                            component: 'confirmManualYesOrNo',
                            size: 'md',
                            backdrop: 'static',
                            resolve:
                            {
                                student: function () { return student; },
                                title: function () { return $translate.instant('SB_Confirm_Manual_Yes_Title'); },
                                confirmFor: function () { return $translate.instant('SB_Confirm_Manual_Yes_For', {studentName: student.name}); }
                            }
                        });

                    notSetByParentWarningModalInstance.result
                        .then(function ()
                        {
                            student.calendarEventAttendeeStatusId = 2;
                            student.hasAccepted = true;

                            var invitees = [student];

                            eventsService.updateAndNotifyInvitees(this.eventId, invitees, null, null, this.notifyTypeId)
                                .then(function (response)
                                {
                                    this.loadStudents(this.eventId);

                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Manual_Yes_Processed',
                                        message: 'SB_Manual_Yes_Processed',
                                        staticBackdrop: true
                                    });

                                }.bind(this))
                                .catch(function (error)
                                {
                                    simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                                }.bind(this));
                        }.bind(this),
                        function ()
                        {
                        });
                }.bind(this);

                this.manualNo = function (student)
                {
                    if (student.isConsentRejected) {
                        return;
                    }

                    var notSetByParentWarningModalInstance = $uibModal.open(
                        {
                            animation: true,
                            component: 'confirmManualYesOrNo',
                            size: 'md',
                            backdrop: 'static',
                            resolve:
                            {
                                student: function () { return student; },
                                title: function () { return $translate.instant('SB_Confirm_Manual_No_Title'); },
                                confirmFor: function () { return $translate.instant('SB_Confirm_Manual_No_For', { studentName: student.name }); }
                            }
                        });

                    notSetByParentWarningModalInstance.result
                        .then(function ()
                        {
                            var unavailableStudentIds = [student.personId];

                            eventsService.updateAndNotifyInvitees(this.eventId, null, unavailableStudentIds, null, this.notifyTypeId)
                                .then(function (response)
                                {
                                    this.loadStudents(this.eventId);

                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Manual_No_Processed',
                                        message: 'SB_Manual_No_Processed',
                                        staticBackdrop: true
                                    });

                                }.bind(this))
                                .catch(function (error)
                                {
                                    simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                                }.bind(this));
                        }.bind(this),
                        function ()
                        {
                        });
                }.bind(this);

                this.sendInvites = function ()
                {
                    if (this.numberOfInvitesToSend == 0)
                    {
                        return;
                    }

                    var studentsToInvite = this.students.filter(function (student)
                    {
                        return student.sendInvite && !student.isConsentAwaited;
                    });

                    var studentsToReinvite = this.students.filter(function (student)
                    {
                        return student.sendInvite && student.isConsentAwaited;
                    });

                    this.isLoading++;
                    var studentIdsToReinvite = [];
                    if(studentsToReinvite.length > 0) {
                        for (var i = 0; i < studentsToReinvite.length; i++)
                        {
                            studentIdsToReinvite.push(studentsToReinvite[i].personId);
                        }
                    }

                    if (studentsToInvite.length > 0 || studentIdsToReinvite.length > 0)
                    {
                        eventsService.updateAndNotifyInvitees(this.eventId, studentsToInvite, null, studentIdsToReinvite, this.notifyTypeId)
                            .then(function (response)
                            {
                                this.loadStudents(this.eventId);

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Students_Invited',
                                    message: 'SB_Students_Invited_Result',
                                    messageTranslationParameters: { numberInvited: studentsToInvite?.length + studentIdsToReinvite?.length },
                                    staticBackdrop: true
                                });

                            }.bind(this))
                            .catch(function (error)
                            {
                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                            }.bind(this))
                            .finally((() => {
                                this.numberOfInvitesToSend = 0;
                                this.isLoading--;
                            }
                            ).bind(this));
                    }
                }.bind(this);

                this.enableOrDisableSend = function ()
                {
                    // TODO implement in separate task SB-3371
                }.bind(this);

                this.filterStudents = function ()
                {
                    this.students = $filter('filter')(this.unfilteredStudents, this.search);
                    this.students.forEach(function (student)
                    {
                        student.searchScore = searchService.getPupilRelevance(student, this.filter.text);
                    }.bind(this));

                    this.sortType = 'searchScore';
                    this.sortReverse = true;
                }.bind(this);

                this.showTable = function ()
                {
                    if (typeof this.students === 'undefined')
                    {
                        return false;
                    }

                    var filteredStudents = this.students.filter(function (item)
                    {
                        return this.search(item);
                    }.bind(this));

                    return filteredStudents.length > 0;
                }.bind(this);

                this.search = function (item)
                {
                    if (!this.filter.text && this.filter.selectedSchoolYearNames.length == 0)
                    {
                        return true;
                    }

                    var nameMatch = this.filter.text == '';

                    var searchTextArray = this.filter.text.toLowerCase().split(' ');

                    angular.forEach(searchTextArray, function (textItem)
                    {
                        if (item.name.toLowerCase().indexOf(textItem) !== -1)
                        {
                            nameMatch = true;
                        }
                    });

                    var yearMatch = this.filter.selectedSchoolYearNames.length == 0;

                    var selectedYearNames = this.filter.selectedSchoolYearNames;
                    var selectedYearIds = Object.keys(selectedYearNames).map(function (e)
                    {
                        return selectedYearNames[e].id;
                    })

                    if (selectedYearIds.includes(item.schoolYearName))
                    {
                        yearMatch = true;
                    }

                    return nameMatch && yearMatch;

                }.bind(this);

                this.filterConsentStatus = function (filter)
                {
                    switch (filter)
                    {
                        case 'consented':
                            if (this.currentFilter == 'consented')
                            {
                                this.showAllStudents();
                                return;
                            }

                            this.currentFilter = 'consented';

                            this.students = this.unfilteredStudents.filter(function (student)
                            {
                                return student.hasConsented;
                            });
                            break;

                        case 'rejected':
                            if (this.currentFilter == 'rejected')
                            {
                                this.showAllStudents();
                                return;
                            }

                            this.currentFilter = 'rejected';

                            this.students = this.unfilteredStudents.filter(function (student)
                            {
                                return student.isConsentRejected;
                            });
                            break;

                        case 'awaited':
                            if (this.currentFilter == 'awaited')
                            {
                                this.showAllStudents();
                                return;
                            }

                            this.currentFilter = 'awaited';

                            this.students = this.unfilteredStudents.filter(function (student)
                            {
                                return student.isConsentAwaited;
                            });
                            break;
                    }
                }.bind(this);

                this.quickReinvite = function ()
                {
                    var studentsToQuickReinvite = this.unfilteredStudents.filter(function (student)
                    {
                        return student.isConsentAwaited;
                    });

                    var studentIdsToReinvite = [];

                    if (studentsToQuickReinvite.length > 0)
                    {
                        for (var i = 0; i < studentsToQuickReinvite.length; i++)
                        {
                            studentIdsToReinvite.push(studentsToQuickReinvite[i].personId);
                        }
                        this.isLoading++;
                        eventsService.updateAndNotifyReInvitees(this.eventId, studentIdsToReinvite)
                            .then(function (response)
                            {
                                this.loadStudents(this.eventId);

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Students_Reinvited',
                                    message: 'SB_Students_Reinvited_Result',
                                    messageTranslationParameters: { numberReinvited: studentIdsToReinvite.length},
                                    staticBackdrop: true
                                });

                            }.bind(this))
                            .catch(function (error)
                            {
                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                            }.bind(this))
                            .finally((() => {
                                this.numberOfInvitesToSend = 0;
                                this.isLoading--;
                            }
                            ).bind(this));
                    }
                }.bind(this);

                this.inviteAll = function ()
                {
                    var studentsToInvite = this.unfilteredStudents.filter(function (student)
                    {
                        student.sendInvite = true;
                        return student;
                    });
                    this.isLoading++;
                    if (studentsToInvite.length > 0)
                    {
                        eventsService.updateAndNotifyInvitees(this.eventId, studentsToInvite, null, null)
                            .then(function (response)
                            {
                                this.loadStudents(this.eventId);

                                simpleAlertService.simpleAlert({
                                    title: 'SB_Students_Invited',
                                    message: 'SB_Students_Invited_Result',
                                    messageTranslationParameters: { numberInvited: studentsToInvite.length },
                                    staticBackdrop: true
                                });

                            }.bind(this))
                            .catch(function (error)
                            {
                                simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                            }.bind(this))
                            .finally((() => {
                                this.numberOfInvitesToSend = 0;
                                this.isLoading--;
                            }
                            ).bind(this));
                    }
                }.bind(this);

                this.showAllStudents = function ()
                {
                    this.students = this.unfilteredStudents.filter(function (student)
                    {
                        return student;
                    });

                    this.currentFilter == '';
                }.bind(this);

                this.editSettings = function ()
                {
                    var eventId = this.eventId;

                    var editModalInstance = $uibModal.open(
                        {
                            animation: true,
                            component: 'consentEventSettingsEdit',
                            size: 'lg',
                            backdrop: 'static',
                            resolve:
                            {
                                params: function ()
                                {
                                    return {
                                        eventId: eventId,
                                        invitesSent: this.hasInvitesOrResponses()
                                    };
                                }.bind(this)
                            }
                        });

                    editModalInstance.result
                        .then(function ()
                        {
                            this.loadStudents(this.eventId);
                        }.bind(this),
                        function ()
                        {
                        });
                }.bind(this);

                this.addStudents = function ()
                {
                    var selectedPersonIds = [];

                    angular.forEach(this.students,
                        function (item)
                        {
                            selectedPersonIds.push(item.personId);
                        });

                    var modalInstance = personPickerService.openMultiPersonPicker({
                        selectedPersonIds: selectedPersonIds,
                        defaultClubId: 0,
                        defaultTeamId: 0,
                        okButtonText: 'SB_Save',
                        includeStaff: false,
                        includePupils: true,
                        hideGroupFilter: false,
                        hideYearFilter: false,
                        hideGenderFilter: false,
                        hideRemove: true,
                        shownFromRegister: true, // hide the delete/remove button next to each student
                        filterOutExternalStaff: false,
                        overridePersonLookupService: null,
                        isMassStudentAssignmentCopyFromMode: false,
                        selectedTimeSlotId: null
                    });

                    modalInstance.result
                        .then(function (selectedPersonIds)
                        {
                            var personIdsToAdd = this.getStudentsToAdd(selectedPersonIds);
                            if (personIdsToAdd.length == 0)
                            {
                                return;
                            };

                            // ADD NEW STUDENT(S) TO THE EVENT
                            eventsService.addNewStudentsToConsentEvent(this.eventId, personIdsToAdd)
                                .then(function (response)
                                {
                                    simpleAlertService.simpleAlert({
                                        title: 'SB_Students_Added',
                                        message: 'SB_Students_Added_Result',
                                        messageTranslationParameters: { numberAdded: response.data },
                                        staticBackdrop: true
                                    });

                                    window.location.reload();
                                }.bind(this))
                                .catch(function (error)
                                {
                                    simpleAlertService.errorAlert({ message: 'SB_Error_loading' });
                                }.bind(this));
                        }.bind(this));
                }.bind(this);

                // Returns a list of students who have have been added using the person picker and who don't exist for the event.
                this.getStudentsToAdd = function (studentIdsFromPersonPicker)
                {
                    var students = [];

                    if (studentIdsFromPersonPicker == null || studentIdsFromPersonPicker.length == 0)
                    {
                        return students;
                    };

                    // Filter to get the students not found in the current list
                    angular.forEach(studentIdsFromPersonPicker, function (studentId)
                    {
                        var studentFound = $filter('filter')(this.students, { 'personId': studentId }, true);
                        if (studentFound.length === 0)
                        {
                            students.push(studentId);
                        };
                    }, this);

                    return students;

                }.bind(this);

                this.hasInvitesOrResponses = function () {
                    return (this.numberConsented + this.numberRejected + this.numberAwaitingResponse) > 0;
                }.bind(this);
            }
        ]
    });
