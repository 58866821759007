'use strict';

angular.module('shared.services.blobStoragePhotosService', [])
    .factory('blobStoragePhotosService', [
        '$http', '$filter', '$cacheFactory', '$window', '$q', 'cachedLookupService',
        function ($http, $filter, $cacheFactory, $window, $q, cachedLookupService)
        {
            var urlBase = '/webapi/WebBlobStoragePhotos/';
            var service = {};
            var repoCache = $cacheFactory('blobStoragePhotosServiceCache');
            var orgString = $window.EveryBuddy.CurrentOrganisationId;
            var orgHasPhotos = $window.EveryBuddy.OrganisationHasPhotos;

            service.getPhotoUrlsForStudents = function (studentPersonIds)
            {
                if (!Array.isArray(studentPersonIds) || studentPersonIds.length < 1 || !orgHasPhotos )
                {
                    return $q(function (resolve) {
                        resolve([]);
                    });
                }
                // distinct list of personIDs
                let uniqueIds = Array.from(new Set(studentPersonIds));
                let arrayChunkSize = 30;
                let chunkArray = function (myArray, chunk_size) {
                    var results = [];

                    while (myArray.length) {
                        results.push(myArray.splice(0, chunk_size));
                    }

                    return results;
                };

                return cachedLookupService.currentPersonId().then(function (currentPersonId) {
                    return $q(function (resolve) {
                        let dataresponse = [];
                        let promises = []

                        chunkArray(uniqueIds, arrayChunkSize).forEach(function (element) {

                            var params = {
                                id: element,
                                organisationId: orgString,
                                currentPersonId: currentPersonId
                            };

                            promises.push($http.get(urlBase + 'GetPhotoUrlsForStudents', { params: params, cache: repoCache }));
                        });

                        $q.all(promises).then(function (result) {

                            for (var i = 0; i < result.length; i++) {

                                if (result[i].data?.photosUrlsForStudents.length > 0) {
                                    dataresponse = dataresponse.concat(result[i].data.photosUrlsForStudents);
                                }
                            }

                            return resolve({ photosUrlsForStudents : dataresponse });
                        }, function (error) {
                            console.log(error);
                            resolve([]);
                        });
                    })
                });
            };

            service.getPropName = function (array, customProp)
            {
                var prop;

                if (!(Array.isArray(array) && array.length > 0))
                {
                    return '';
                }

                if (customProp && array[0][customProp])
                {
                    prop = customProp;
                }
                else if (array[0].personId)
                {
                    prop = 'personId';
                }
                else
                {
                    prop = 'id';
                }

                return prop;
            };

            service.addStudentPhotoUrlsToArray = function (array, customProp)
            {
                var prop = service.getPropName(array, customProp);

                var personIds = array.map(function (item)
                {
                    return item[prop];
                });

                return service.getPhotoUrlsForStudents(personIds).then(function (data)
                {
                    if (data.photosUrlsForStudents)
                    {
                        array.forEach(function (person)
                        {
                            var photoPerson = data.photosUrlsForStudents.filter(function (photo)
                            {
                                return photo.personId == person[prop];
                            })[0];

                            if (photoPerson)
                            {
                                person.photoUrl = photoPerson.thumbnail;
                            }
                        });
                    }
                    return array;
                });
            };

            service.addStudentPhotoUrlsToNestedArrays =  function (array, customNestedArrayName, customProp) {

                var allstudents = [];

                var nestedArrayName;

                var nestedArrayCommonNames = ['pupils', 'attendees', customNestedArrayName];

                if (!(Array.isArray(array) && array.length > 0)) {
                    return [];
                }

                nestedArrayCommonNames.forEach(function(name) {
                    if (array[0][name]) {
                        nestedArrayName = name;
                    }
                });

                array.forEach(function(item) {
                    item[nestedArrayName].forEach(function (subItem) {
                        allstudents.push(subItem);
                    });
                });

                var prop = service.getPropName(array[0][nestedArrayName], customProp);

                var uniqueStudents = $filter('unique')(allstudents, prop);

                return service.addStudentPhotoUrlsToArray(uniqueStudents).then(function (photoStudentArray) {
                    array.forEach(function(item) {
                        item[nestedArrayName].forEach(function (student) {
                            student.photoUrl = photoStudentArray.filter(function(photoStudent) {
                                return student.personId == photoStudent.personId;
                            })[0].photoUrl;
                        });
                    });
                    return array;
                }.bind(this));
            };

            return service;
        }
    ]);
