'use strict';

angular.module('receptionModule').component('receptionAllGroupsComms', {
    templateUrl:
    '/Scripts/app/reception/controllers/groups/reception-allgroups-comms.template.html?v=', // + window.EveryBuddy.version
    bindings: {
        selectedGroup: '<'
    },
    controller:
    [
        '$scope',
        'messageViewingService',
        'moduleService',
        function ($scope, messageViewingService, moduleService) {

            this.$onChanges = (changes) => {
                if (changes.selectedGroup && changes.selectedGroup.currentValue) {
                    $scope.selectedGroup = this.selectedGroup;
                    $scope.allowComms = this.selectedGroup.details?.isGroupAdmin || this.selectedGroup.isGroupAdmin;
                }
            };

            this.$onInit = () => { // Not indented to avoid merge issues

            $scope.recipients = {
                teams : [
                    {
                        id: $scope.selectedGroup.id,
                        memberType: 0
                    }
                ]
            };

            $scope.showTags = false;

            moduleService.getCommunicationModule()
                .then(res => {
                    $scope.showAttachToEmail = res.emailAttachmentsEnabled;
                })
                .catch(err => {
                    console.log(err);
                })

            messageViewingService
                .getAllLookups()
                .then(function (data) {
                    $scope.showTags = data.tagsEnabled;
                })
                .catch(function (err) {
                    console.error(err);
                    $scope.showTags = false;
                });

            }
        }
    ]});
