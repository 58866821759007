import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tabs } from '@sb-shared/models/UI/menu-item';
import { Tab } from './../../models/UI/menu-item';

@Component({
  selector: 'sb-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() tabs: Tabs;
  @Input() isTop: boolean;
  @Input() isDisabled: boolean;
  @Input() isWizard: boolean;
  @Input() routePrefix: string;
  @Input() isWrapTabHeaders: boolean;
  @Output() tabsChange: EventEmitter<Tabs> = new EventEmitter<Tabs>();
  isRouted: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    // router.events.subscribe(event => {
    //   // console.log(event)
    // })
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check whether component is for navigation or value selection
    if (changes.tabs?.currentValue) {
      this.isRouted = this.tabs.some(tab => tab.route);
      if (!this.tabs.some(tab => tab.isActive)) {
        this.tabs[0].isActive = true;
      }
    }
  }

  click(i: number) {
    this.tabs = this.tabs.map((tab, tabIndex) => {
      return { ...tab, isActive: tabIndex === i };
    });
    this.tabsChange.emit(this.tabs);
  }

  getTabRoute(route: string): string {
    return (this.routePrefix ? `${this.routePrefix}/` : '') + route;
  }

  onClick(event: MouseEvent, tab: Tab) {
    // Handling of tabs as regular routing does not work
    if (this.routePrefix) {
      event.preventDefault();
      setTimeout(() => {
        const route = this.getTabRoute(tab.route);
        this.router.navigate([route], { relativeTo: this.route });
      });
    }
  }
}
