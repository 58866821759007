export enum EventTabId {
  Type = 1,
  Who = 2,
  GroupStaff = 3,
  Selection = 4,
  Details = 5,
  Cost = 6,
  Opponent = 7,
  Tournament = 8,
  Consent = 9,
  Repeat = 10,
  Confirmation = 11
}
