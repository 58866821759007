import { Component } from '@angular/core';
import { ToastService } from '@sb-core/services/toast.service';

@Component({
  selector: 'sb-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss']
})
export class ToastContainerComponent {
  autohide: boolean;

  constructor(public toast: ToastService) {}
}
