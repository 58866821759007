

angular.module('events.components.eventDetailEditor',
    [
        'ui.tinymce',
        'events.components.cancelEventButton',
        'events.services.eventsService',
        'person.services.staffRepository',
        'shared.controllers.confirmationPopup',
        'shared.directives.sbCurrencyInput',
        'shared.components.sbDatePicker',
        'shared.components.sbIcon',
        'shared.components.sbPercentageInput',
        'shared.components.sbModalHeader',
        'shared.directives.sbCheckBox',
        'shared.services.tinymceConfigHelper',
        'shared.services.simpleAlertService',
        'pascalprecht.translate'
    ])
    .component('eventDetailEditor',
        {
            bindings: {
                eventId: '<',
                onSelectedEventUpdated: '&',
                onRelatedEventUpdated: '&',
                allowFeeChanges: '<',
                suppressNotifications: '<',
                showTitleBar: '<'
            },
            templateUrl: '/Scripts/app/events/components/event-detail-editor.template.html',

            controller: [
                '$state',
                '$uibModal',
                '$scope',
                'eventsService',
                'staffRepository',
                'tinymceConfigHelper',
                'simpleAlertService',
                'availabilityRequirementsEnum',
                'eventSectionEnum',
                function ($state,
                    $uibModal,
                    $scope,
                    eventsService,
                    staffRepository,
                    tinymceConfigHelper,
                    simpleAlertService,
                    availabilityRequirementsEnum,
                    eventSectionEnum) {
                    this.selectedOrganiserOnlineMeetingId = '';

                    this.$onChanges = function (changes) {
                        if (changes.eventId && changes.eventId.currentValue) {
                            this.loadEvent();
                        }
                    };

                    this.tinymceOptions = tinymceConfigHelper.getTinyMceConfig({ tables: true, hyperlinks: true });

                    this.dateOptions = {
                        formatYear: 'yy',
                        startingDay: 1
                    };

                    this.staffInformations = [];

                    this.$doCheck = function () {
                        // Nasty bodge around an issue in bootstrap uib datepicker. Essentially if you have a min and max set and then manually
                        // set the date it wasn't validating it properly and was considering the whole form invalid. This was affecting when a
                        // pickup date was set and we shift the To date forward our date picker component manually shifts the date forward
                        // to be in a valid min/max range but then was hence invalid. Seems to be a known unresolved issue, see links...
                        // https://stackoverflow.com/questions/46609577/manually-entered-date-is-not-being-validated-uib-datepicker-popup
                        // https://github.com/angular-ui/bootstrap/issues/6124
                        $scope?.eventForm?.$$controls.forEach(ctrl => {
                            if (ctrl.$error.dateDisabled) {
                                ctrl.$setValidity('dateDisabled', true);
                            }
                        });
                    }

                    this.loadEvent = function () {
                        this.loadingEventDetails = true;

                        eventsService.getEventDetails(this.eventId)
                            .then(function (data) {
                                this.eventDetails = data.details;
                                this.organisers = data.organisers;
                                this.calendarEventCategoryTypes = data.calendarEventCategoryTypes;
                                this.eventDetails.momentFrom = moment.utc(data.details.from);
                                this.eventDetails.momentTo = moment.utc(data.details.to);
                                this.eventDetails.momentMeetingAt = !data.details.meetingAt
                                    ? null
                                    : moment.utc(data.details.meetingAt);
                                this.eventDetails.momentSignUpOpen = !data.details.signUpOpen
                                    ? null
                                    : moment.utc(data.details.signUpOpen);
                                this.eventDetails.momentSignUpClose = !data.details.signUpClose
                                    ? null
                                    : moment.utc(data.details.signUpClose);
                                this.eventDetails.momentPickupAt = !data.details.pickupAt
                                    ? null
                                    : moment.utc(data.details.pickupAt);
                                this.setMaxPickupAt(this.eventDetails.momentTo);

                                this.locations = data.locations;

                                // deep copy to keep clean reference of object
                                this.eventDetailsClean = { ...this.eventDetails };

                                this.setShowPickupFields();

                                this.futureEvents = [];
                                this.deletableFutureEvents = [];
                                this.loadingEventDetails = false;

                                var staffIds = [];
                                for (var i = 0; i < this.organisers.length; i++) {
                                    staffIds.push(this.organisers[i].id);
                                }

                                staffRepository.getStaffInformationForStaffIds(staffIds)
                                    .then(function (staffInformations) {
                                        this.staffInformations = staffInformations;

                                        if (typeof this.eventDetails.ccaSignUpId !== 'undefined' && this.eventDetails.ccaSignUpId != null) {
                                            if (!this.eventDetails.isOnlineEvent) {
                                                return;
                                            }

                                            this.selectedOrganiserOnlineMeetingId = '';
                                            for (var i = 0; i < this.staffInformations.length; i++) {
                                                if (this.staffInformations[i].personId == this.eventDetails.owningPersonId) {
                                                    this.selectedOrganiserOnlineMeetingId = this.staffInformations[i].onlineMeetingId;
                                                    break;
                                                }
                                            }
                                        }

                                        $state.go('.details');
                                    }.bind(this));
                            }.bind(this))
                            .catch(function (error) {
                                this.loadingEventDetails = false;
                                return error;
                            }.bind(this));
                    }.bind(this);

                    this.setMaxPickupAt = function (momentTo) {
                        this.maxPickupAt = momentTo
                            ? momentTo.clone().add(1, 'days')
                            : null;
                    }.bind(this);

                    this.hasOpponent = function() {
                        if (this.loadingEventDetails) return false;
                        const currentEventType = this.calendarEventCategoryTypes.find(eventType => eventType.id === this.eventDetails.calendarEventCategoryTypeId);
                        return currentEventType && currentEventType.eventSectionId === eventSectionEnum.Sports && currentEventType.isFixture;
                    }

                    this.setShowPickupFields = function () {
                        const currentEventType = this.calendarEventCategoryTypes.find(eventType => eventType.id === this.eventDetails.calendarEventCategoryTypeId);
                        this.showPickupFields = currentEventType && (
                            currentEventType.eventSectionId === eventSectionEnum.Sports
                            || currentEventType.eventSectionId === eventSectionEnum.Trips
                        );

                        if (!this.showPickupFields) {
                            this.eventDetails.pickupLocationId = null;
                            this.eventDetails.pickupAt = null;
                        }
                    }

                    this.leadStaffMemberSelected = function () {
                        this.selectedOrganiserOnlineMeetingId = '';
                        for (var i = 0; i < this.staffInformations.length; i++) {
                            if (this.staffInformations[i].personId == this.eventDetails.owningPersonId) {
                                this.selectedOrganiserOnlineMeetingId = this.staffInformations[i].onlineMeetingId;
                                break;
                            }
                        }
                    }

                    this.deleteEvent = function () {
                        // check is this is a part of a series?
                        this.deletableFutureEvents = [];
                        var modalInstance = null;

                        eventsService.getOtherDeletableEventsInSeries(this.eventDetails.id)
                            .then(function (data) {
                                this.deletableFutureEvents = data;

                                if (this.deletableFutureEvents.length > 0) {
                                    modalInstance = $uibModal.open({
                                        animation: this.animationsEnabled,
                                        templateUrl: 'deleteSeriesEventsSelection.html',
                                        controller: 'popupDeleteSeriesController',
                                        size: 'md',
                                        resolve: {
                                            deletableFutureEvents: function () { return this.deletableFutureEvents; }.bind(this),
                                            eventDetails: function () { return this.eventDetails; }.bind(this)
                                        }
                                    });

                                    modalInstance.result.then(function () {
                                        this.saving = true;

                                        var eventListToDelete = [];
                                        eventListToDelete.push(this.eventDetails.id);

                                        for (var i = 0; i < this.deletableFutureEvents.length; i++) {
                                            if (this.deletableFutureEvents[i].selected) {
                                                eventListToDelete.push(this.deletableFutureEvents[i].id);
                                            }
                                        }

                                        eventsService.deleteEvents(eventListToDelete)
                                            .then(function (updatedEvents) {
                                                if (updatedEvents) {
                                                    for (var i = 0; i < updatedEvents.length; i++) {
                                                        if (updatedEvents[i].id === this.eventDetails.id) {
                                                            this.eventDetails.isDeleted = true;

                                                            if (typeof this.onSelectedEventUpdated() === 'function') {
                                                                this.onSelectedEventUpdated()(this.eventDetails);
                                                            }
                                                        } else {
                                                            if (typeof this.onRelatedEventUpdated() === 'function') {
                                                                this.onRelatedEventUpdated()(updatedEvents[i]);
                                                            }
                                                        }
                                                    }
                                                }

                                                this.saving = false;

                                                // update our deep clean copy of object event
                                                this.eventDetailsClean =
                                                    { ...this.eventDetails };
                                            }.bind(this))
                                            .catch(function (err) {
                                                console.error(err);
                                                this.saving = false;
                                            }.bind(this));
                                    }.bind(this))
                                        .catch(function () { });
                                } else {
                                    modalInstance = simpleAlertService.simpleAlert({
                                        title: 'SB_Delete_Event',
                                        message: 'SB_Confirm_delete_event',
                                        okButtonText: 'SB_Delete',
                                        cancelButtonText: 'SB_Cancel',
                                        windowSize: 'sm'
                                    });

                                    modalInstance.result.then(function () {
                                        this.saving = true;
                                        eventsService.deleteEvents([this.eventDetails.id])
                                            .then(function (data) {
                                                this.eventDetails.isDeleted = true;
                                                this.saving = false;

                                                if (typeof this.onSelectedEventUpdated() === 'function') {
                                                    this.onSelectedEventUpdated()(this.eventDetails);
                                                }
                                                // update our deep clean copy of object event
                                                this.eventDetailsClean =
                                                    { ...this.eventDetails };
                                            }.bind(this))
                                            .catch(function (err) {
                                                console.error(err);
                                                this.saving = false;
                                            }.bind(this));
                                    }.bind(this))
                                        .catch(function () { });
                                }
                            }.bind(this))
                            .catch(function (err) { });
                    }.bind(this);

                    this.restoreEvent = function () {
                        var modalInstance = simpleAlertService.simpleAlert({
                            title: 'SB_Restore_Event',
                            message: 'SB_Confirm_restore_event',
                            okButtonText: 'SB_Restore',
                            cancelButtonText: 'SB_Cancel',
                            windowSize: 'sm'
                        });

                        modalInstance.result.then(function () {
                            this.saving = true;
                            eventsService.restoreEvent(this.eventDetails.id)
                                .then(function (data) {
                                    this.eventDetails = data;
                                    this.eventDetails.momentFrom = moment.utc(data.from);
                                    this.eventDetails.momentTo = moment.utc(data.to);
                                    if (typeof data.meetingAt !== 'undefined') {
                                        this.eventDetails.momentMeetingAt = moment.utc(data.meetingAt);
                                    }
                                    if (typeof data.signUpOpen !== 'undefined') {
                                        this.eventDetails.momentSignUpOpen = moment.utc(data.signUpOpen);
                                    }
                                    if (typeof data.signUpClose !== 'undefined') {
                                        this.eventDetails.momentSignUpClose = moment.utc(data.signUpClose);
                                    }
                                    if (typeof data.pickupAt !== 'undefined') {
                                        this.eventDetails.momentPickupAt = moment.utc(data.pickupAt);
                                    }

                                    this.saving = false;

                                    if (typeof this.onSelectedEventUpdated() === 'function') {
                                        this.onSelectedEventUpdated()(this.eventDetails);
                                    }
                                    // update our deep clean copy of object event
                                    this.eventDetailsClean = { ...this.eventDetails };
                                }.bind(this));
                        }.bind(this),
                            function () { });
                    }.bind(this);

                    this.updateEvent = function () {
                        this.saving = true;

                        // Format moment dates into date-time strings.
                        this.eventDetails.from = this.eventDetails.momentFrom.format();
                        this.eventDetails.to = this.eventDetails.momentTo.format();
                        this.eventDetails.meetingAt = !this.eventDetails.momentMeetingAt
                            ? null
                            : this.eventDetails.momentMeetingAt.format();
                        this.eventDetails.signUpOpen = !this.eventDetails.momentSignUpOpen
                            ? null
                            : this.eventDetails.momentSignUpOpen.format();
                        this.eventDetails.signUpClose = !this.eventDetails.momentSignUpClose
                            ? null
                            : this.eventDetails.momentSignUpClose.format();
                        this.eventDetails.pickupAt = !this.eventDetails.momentPickupAt
                            ? null
                            : this.eventDetails.momentPickupAt.format();

                        eventsService.updateEvent(this.eventDetails, this.suppressNotifications)
                            .then(function (data) {
                                if (data.isError) {
                                    this.saving = false;

                                    return simpleAlertService.errorAlert({ message: data?.errorData?.data?.Message });
                                }
                                this.eventDetails = data.event;
                                this.eventDetails.momentFrom = moment.utc(data.event.from);
                                this.eventDetails.momentTo = moment.utc(data.event.to);
                                if (typeof data.event.meetingAt !== 'undefined') {
                                    this.eventDetails.momentMeetingAt = moment.utc(data.event.meetingAt);
                                }
                                if (typeof data.event.signUpOpen !== 'undefined') {
                                    this.eventDetails.momentSignUpOpen = moment.utc(data.event.signUpOpen);
                                }
                                if (typeof data.event.signUpClose !== 'undefined') {
                                    this.eventDetails.momentSignUpClose = moment.utc(data.event.signUpClose);
                                }
                                if (typeof data.event.pickupAt !== 'undefined') {
                                    this.eventDetails.pickupAt = moment.utc(data.event.pickupAt);
                                }
                                this.futureEvents = data.futureEvents;

                                var i = 0;

                                for (i = 0; i < this.futureEvents.length; i++) {
                                    this.futureEvents[i].selected = true;
                                }

                                // set the event type name for convenience..
                                for (i = this.calendarEventCategoryTypes.length; i--;) {
                                    if (this.calendarEventCategoryTypes[i].id ===
                                        this.eventDetails.calendarEventCategoryTypeId) {
                                        this.eventDetails.calendarEventCategoryName =
                                            this.calendarEventCategoryTypes[i].name;
                                    }
                                }

                                this.saving = false;

                                const handleEventUpdated = () => {
                                    if (typeof this.onSelectedEventUpdated() === 'function') {
                                        this.onSelectedEventUpdated()(this.eventDetails);
                                    }
                                }

                                // what if this is a sequence of events?
                                if (this.futureEvents.length > 0) {
                                    var modalInstance = $uibModal.open({
                                        templateUrl: 'confirmApplySeries.html',
                                        controller: 'popupConfirmApplyToSeriesController',
                                        size: 'lg',
                                        resolve: {
                                            organisers: function () { return this.organisers; }.bind(this),
                                            locations: function () { return this.locations; }.bind(this),
                                            calendarEventCategoryTypes: function () {
                                                return this.calendarEventCategoryTypes;
                                            }.bind(this),
                                            eventDetails: function () { return this.eventDetails; }.bind(this),
                                            eventDetailsClean: function () { return this.eventDetailsClean; }.bind(this),
                                            futureEvents: function () { return this.futureEvents; }.bind(this),
                                            allowFeeChanges: function () { return this.allowFeeChanges; }.bind(this)
                                        }
                                    });

                                    modalInstance.result.then(
                                        function (fields) {
                                            // eventIds we want
                                            var eventIds = [];

                                            //map moment fields back to the actual field
                                            var indexOfSignUpOpen = fields.indexOf("momentSignUpOpen");
                                            if (indexOfSignUpOpen > -1) {
                                                fields[indexOfSignUpOpen] = "signUpOpenUtc";
                                            };

                                            var indexOfSignUpCloses = fields.indexOf("momentSignUpClose");
                                            if (indexOfSignUpCloses > -1) {
                                                fields[indexOfSignUpCloses] = "signUpCloseUtc";
                                            };

                                            for (var i = 0; i < this.futureEvents.length; i++) {
                                                if (this.futureEvents[i].selected) {
                                                    eventIds.push(this.futureEvents[i].id);
                                                }
                                            }

                                            eventsService.updateFutureEvents(eventIds, fields, this.eventDetails)
                                                .then(function (updatedEvents) {
                                                    if (typeof this.onRelatedEventUpdated() === 'function') {
                                                        if (updatedEvents) {
                                                            for (var i = 0; i < updatedEvents.length; i++) {
                                                                this.onRelatedEventUpdated()(updatedEvents[i]);
                                                            }
                                                        }
                                                    }
                                                }.bind(this));

                                            // update our clean deep copy of object event
                                            this.eventDetailsClean = { ...this.eventDetails };
                                        }.bind(this),
                                        function () {
                                            // update our clean deep copy of object event
                                            this.eventDetailsClean = { ...this.eventDetails };
                                        }.bind(this))
                                        .finally(() => {
                                            handleEventUpdated();
                                        });
                                } else {
                                    handleEventUpdated();
                                }
                            }.bind(this));
                    }.bind(this);

                    this.changeEventBlockStatus = function (blocked) {
                        eventsService.blockOutEvents([this.eventDetails.id], blocked)
                            .then(function (data) {
                                this.eventDetails.blockedOut = blocked;

                                if (typeof this.onSelectedEventUpdated() === 'function') {
                                    this.onSelectedEventUpdated()(this.eventDetails);
                                }

                                // update our deep clean copy of object event
                                this.eventDetailsClean = { ...this.eventDetails };
                            }.bind(this));
                    };

                    this.availabilityRequirementsDisplayText = function (id) {
                        switch (parseInt(id)) {
                            case availabilityRequirementsEnum.None:
                                return 'SB_None';
                            case availabilityRequirementsEnum.ConsentWithManualSelection:
                                return 'SB_Send_Availabilty_Consent_Requests';
                            case availabilityRequirementsEnum.NotifyOnly:
                                return 'SB_Notify_Only_Without_Availability_Requests';
                            case availabilityRequirementsEnum.AutoNotify:
                                return 'SB_Auto_Notify';
                            /*
                            case availabilityRequirementsEnum.InstantBooking:
                                return '';
                            */
                            case availabilityRequirementsEnum.ConsentWithAutoSelection:
                                return 'ConsentWithAutoSelection';
                            default:
                                return 'SB_None';
                        }
                    };

                    this.isInstantBook = function () {
                        if (!this.eventDetails) {
                            return false;
                        }

                        return this.eventDetails.availabilityRequirements == availabilityRequirementsEnum.InstantBooking &&
                            !this.eventDetails.isTimeSlotted;
                    };

                    this.blockActions = {
                        Block: 1,
                        Unblock: 2
                    };

                    this.getBlockAction = function () {
                        var event = this.eventDetails;
                        if (event && event.isTimeSlotted) {
                            if (!event.blockedOut) {
                                return this.blockActions.Block;
                            } else {
                                return this.blockActions.Unblock;
                            }
                        } else {
                            return 0;
                        }
                    }.bind(this);
                }
            ]


        })
    .controller('popupDeleteSeriesController',
        [
            '$scope',
            '$uibModalInstance',
            'deletableFutureEvents',
            'eventDetails',
            function ($scope, $uibModalInstance, deletableFutureEvents, eventDetails) {
                $scope.deletableFutureEvents = deletableFutureEvents;
                $scope.eventDetails = eventDetails;

                $scope.toggleSelected = function () {
                    for (var i = 0; i < deletableFutureEvents.length; i++) {
                        var field = deletableFutureEvents[i];
                        field.selected = !field.selected;
                    }
                };

                $scope.delete = function () {
                    $uibModalInstance.close(true);
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };

            }
        ])

    .controller('popupConfirmApplyToSeriesController',
        [
            '$scope',
            '$translate',
            '$uibModalInstance',
            'organisers',
            'locations',
            'calendarEventCategoryTypes',
            'eventDetails',
            'eventDetailsClean',
            'futureEvents',
            'allowFeeChanges',
            function (
                $scope,
                $translate,
                $uibModalInstance,
                organisers,
                locations,
                calendarEventCategoryTypes,
                eventDetails,
                eventDetailsClean,
                futureEvents,
                allowFeeChanges) {
                $scope.futureEvents = futureEvents;
                $scope.eventDetails = eventDetails;
                $scope.eventDetailsClean = eventDetailsClean;

                $scope.fields = [
                    {
                        fieldName: 'title',
                        displayName: 'SB_Title',
                        selected: false,
                        displayOrder: 1,
                        disabled: false
                    },
                    {
                        fieldName: 'keyInfo',
                        displayName: 'SB_Vital_Information',
                        selected: false,
                        displayOrder: 2,
                        disabled: false,
                        useShadowDom: true
                    },
                    {
                        fieldName: 'description',
                        displayName: 'SB_Additional_Information',
                        selected: false,
                        displayOrder: 3,
                        disabled: false,
                        useShadowDom: true
                    },
                    {
                        fieldName: 'owningPersonId',
                        displayName: 'SB_Organiser',
                        displayFunction: function (value) {
                            for (var i = 0; i < organisers.length; i++) {
                                if (organisers[i].id === value) {
                                    return organisers[i].name;
                                }
                            }
                            return 'Unspecified';
                        },
                        selected: false,
                        displayOrder: 4,
                        disabled: false
                    },
                    {
                        fieldName: 'locationId',
                        displayName: 'SB_Location',
                        displayFunction: function (value) {
                            for (var i = 0; i < locations.length; i++) {
                                if (locations[i].id === value) {
                                    return locations[i].name;
                                }
                            }
                            return 'Unspecified';
                        },
                        selected: false,
                        displayOrder: 5,
                        disabled: false
                    },
                    {
                        fieldName: 'calendarEventCategoryTypeId',
                        displayName: 'SB_Event_Type',
                        displayFunction: function (value) {
                            for (var i = 0; i < calendarEventCategoryTypes.length; i++) {
                                if (calendarEventCategoryTypes[i].id === value) {
                                    return calendarEventCategoryTypes[i].name;
                                }
                            }
                            return 'Unspecified';
                        },
                        selected: false,
                        displayOrder: 6,
                        disabled: false
                    },
                    {
                        fieldName: 'meetingLocationId',
                        displayName: 'SB_Meeting_Location',
                        displayFunction: function (value) {
                            for (var i = 0; i < locations.length; i++) {
                                if (locations[i].id === value) {
                                    return locations[i].name;
                                }
                            }
                            return 'Unspecified';
                        },
                        selected: false,
                        displayOrder: 7,
                        disabled: false
                    },
                    {
                        fieldName: 'pickupLocationId',
                        displayName: 'SB_Pickup_Location',
                        displayFunction: function (value) {
                            for (var i = 0; i < locations.length; i++) {
                                if (locations[i].id === value) {
                                    return locations[i].name;
                                }
                            }
                            return 'Unspecified';
                        },
                        selected: false,
                        displayOrder: 8,
                        disabled: false
                    }
                ];

                if ($scope.eventDetails.isBlockEvent) {
                    $scope.fields.push(
                        {
                            fieldName: 'allowJoiningOfInFlightBlockEvent',
                            displayName: 'SB_Allow_joining_part_way_through_series',
                            selected: false,
                            displayOrder: 4.5,
                            disabled: false,
                            showFutureEventsWarning: true,
                            displayFunction: function (value) {
                                if (value) {
                                    return $translate.instant('SB_Yes');
                                }

                                return $translate.instant('SB_No');
                            },
                        });
                    $scope.fields.push(
                        {
                            fieldName: 'alwaysChargeFullPriceBlockEvent',
                            displayName: 'SB_Always_Charge_Full_Price',
                            selected: false,
                            displayOrder: 4.8, // bit of a nasty way to make it appear between two items without renumbering all the items after it
                            disabled: false,
                            showFutureEventsWarning: true,
                            displayFunction: function (value) {
                                if (value) {
                                    return $translate.instant('SB_Yes');
                                }

                                return $translate.instant('SB_No');
                            },
                        });
                }

                if (allowFeeChanges && !eventDetails.isBlockEvent) {
                    $scope.fields.push(
                        {
                            fieldName: 'size',
                            displayName: 'SB_Number_of_places',
                            selected: false,
                            displayOrder: 8,
                            disabled: false
                        });
                    $scope.fields.push(
                        {
                            fieldName: 'costPerPerson',
                            displayName: 'SB_Price_per_session',
                            selected: false,
                            displayOrder: 9,
                            disabled: false
                        });
                    $scope.fields.push(
                        {
                            fieldName: 'upfrontCostPerPerson',
                            displayName: 'SB_Percent_deposit',
                            selected: false,
                            displayOrder: 10,
                            disabled: false
                        });
                    $scope.fields.push(
                        {
                            fieldName: 'includeOrganisationTax',
                            displayName: 'SB_Includes_Tax',
                            selected: false,
                            displayOrder: 11,
                            disabled: false
                        });

                    if ($scope.eventDetails.momentSignUpOpen) {
                        $scope.fields.push({
                            fieldName: 'momentSignUpOpen',
                            displayName: 'SB_Sign_up_opens',
                            selected: false,
                            disabled: false,
                            displayOrder: 12,
                            displayFunction: function (value) {
                                if (typeof value == 'undefined') {
                                    this.selected = false;
                                    return value;
                                }

                                return value.format('DD-MM-YYYY HH:mm')
                            }
                        });
                    }

                    if ($scope.eventDetails.momentSignUpClose) {
                        $scope.fields.push({
                            fieldName: 'momentSignUpClose',
                            displayName: 'SB_Sign_up_closes',
                            selected: false,
                            disabled: false,
                            displayOrder: 13,
                            displayFunction: function (value) {
                                if (typeof value == 'undefined') {
                                    this.selected = false;
                                    return value;
                                }
                                return value.format('DD-MM-YYYY HH:mm')
                            }
                        });
                    }
                }

                $scope.isLeaveOtherEventsButtonDisabled = false;

                // Setup the initial values by checking for changes between the clean and dirty version of the calendar event
                $scope.fields.forEach((field) => {
                    var dirtyField = $scope.eventDetails[field.fieldName];
                    var cleanField = eventDetailsClean[field.fieldName];

                    if (typeof dirtyField === 'number') {
                        field.selected = parseInt(cleanField) !== dirtyField;
                    }
                    else if (moment.isMoment(dirtyField) && moment.isMoment(cleanField)) {
                        field.selected = moment(dirtyField).diff(cleanField);
                    }
                    else {
                        field.selected = dirtyField !== cleanField;
                    }
                });

                let selectedFields = $scope.fields.filter((field) => {
                    return field.selected;
                });

                let hasOnlyAllowJoiningOfInFlightBlockEventBeenChanged =
                    selectedFields.length == 1 && selectedFields[0].fieldName == 'allowJoiningOfInFlightBlockEvent';

                if (hasOnlyAllowJoiningOfInFlightBlockEventBeenChanged) {
                    $scope.isLeaveOtherEventsButtonDisabled = true;

                    $scope.fields.forEach((field) => {
                        if (field.fieldName != 'allowJoiningOfInFlightBlockEvent') {
                            field.disabled = true;
                        }
                    });
                }

                $scope.apply = function () {
                    var selectedFields = [];

                    for (var i = 0; i < $scope.fields.length; i++) {
                        if ($scope.fields[i].selected) {
                            selectedFields.push($scope.fields[i].fieldName);
                        }
                    }

                    $uibModalInstance.close(selectedFields);

                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }]);
