<sb-modal-header
  *ngIf="isModal"
  [modalTitle]="formTitle | translate"
  [headerClass]="mergeTabs ? 'secondary' : null"
  (onClose)="confirmClose()"
></sb-modal-header>
<ng-template #actions let-isTop="isTop">
  <div class="wizard__footer">
    <div>
      <sb-button
        *ngIf="dangerButton"
        buttonClass="danger"
        [message]="dangerButton.message"
        [size]="isTop ? 'sm' : null"
        [isDisabled]="dangerButton.isDisabled"
        [isLoading]="dangerButton.isLoading"
        (onClick)="clickDangerButton()"
      ></sb-button>
    </div>
    <div>
      <sb-button
        *ngIf="showCancel"
        buttonClass="warning"
        message="SB_Cancel"
        [size]="isTop ? 'sm' : null"
        (onClick)="clickCancelButton()"
      ></sb-button>
      <sb-button
        *ngIf="secondaryButton"
        buttonClass="secondary"
        [message]="secondaryButton.message"
        [size]="isTop ? 'sm' : null"
        [isDisabled]="secondaryButton.isDisabled"
        [isLoading]="secondaryButton.isLoading"
        (onClick)="clickSecondaryButton()"
      ></sb-button>
      <sb-button
        *ngIf="tabs?.length > 1 && !mergeTabs"
        buttonClass="info"
        type="button"
        (onClick)="toggleTab(-1)"
        [isDisabled]="backDisabled()"
        [size]="isTop ? 'sm' : null"
        message="SB_Back"
      ></sb-button>
      <sb-button
        *ngIf="tabs?.length > 1 && !mergeTabs"
        buttonClass="info"
        type="button"
        (onClick)="toggleTab(1)"
        [isDisabled]="nextDisabled()"
        [size]="isTop ? 'sm' : null"
        message="SB_Next"
      ></sb-button>
      <sb-button
        buttonClass="success"
        type="button"
        (onClick)="save()"
        [isDisabled]="saveDisabled()"
        [message]="saveLabel || 'SB_Save'"
        [size]="isTop ? 'sm' : null"
        [isLoading]="isSaving"
      ></sb-button>
    </div>
  </div>
</ng-template>
<div class="space-between">
  <h2 *ngIf="!isModal && formTitle" [innerHTML]="formTitle | translate" class="mb-5"></h2>
  <ng-container
    *ngIf="hasTopActions"
    [ngTemplateOutlet]="actions"
    [ngTemplateOutletContext]="{ isTop: true }"
  ></ng-container>
</div>
<div
  class="wizard"
  [ngClass]="{ 'modal-body pt-0': isModal, 'wizard-no-images': hideImages }"
  [hidden]="showLoadingMessage()"
  *ngIf="tabs"
>
  <div class="wizard__tabs" *ngIf="tabs.length > 1" [hidden]="mergeTabs">
    <ng-container *ngFor="let tab of tabs">
      <div
        class="wizard__tabs__tab"
        [ngClass]="{
          active: activeTabId === tab.id,
          'wizard__tabs__tab--hidden': tab.isTemplateHidden,
          'wizard__tabs__tab--disabled': tab.isDisabled
        }"
        (click)="tab.isDisabled ? null : setTab(tab.id)"
      >
        <sb-icon
          name="{{ tab.iconName }}"
          [overrideStyle]="activeTabId === tab.id ? 'solid' : 'regular'"
          [overlayName]="tab.isDone && !hideChecks ? 'tickCircle' : ''"
          overlayOverrideStyle="solid"
          overlayCustomClass="tab-done"
        ></sb-icon>
        <span [innerHTML]="tab.name | translate" class="hidden-xs"></span>
        <div class="tab-line" *ngIf="tabs.length > 1"></div>
      </div>
    </ng-container>
  </div>
  <div
    class="wizard__main"
    *ngIf="wizardForm && tabs"
    [ngClass]="{ 'wizard__main--single': hideImages || isFullWidthConfirmation() }"
  >
    <ng-container *ngFor="let tab of tabs">
      <div class="wizard__image hidden-xs" *ngIf="!hideImages && activeTabId === tab.id && confirmationImageCheck()">
        <div [inlineSVG]="tab.svg || (tab.isConfirmation ? 'new_entries' : '')" class="hidden-xs"></div>
      </div>
    </ng-container>
    <form [ngClass]="{ 'wizard__form--small': mergeTabs }" [formGroup]="wizardForm">
      <ng-container *ngFor="let tab of tabs">
        <ng-container *ngIf="mergeTabs || activeTabId === tab.id">
          <h2
            class="wizard__tab-heading"
            [ngClass]="mergeTabs ? 'wizard__tab-heading--small' : 'visible-xs'"
            [innerHTML]="tab.name | translate"
            *ngIf="tabs.length > 1"
          ></h2>
          <div *ngIf="tab.description" class="mt-3 mb-5 p-large text-base">
            <span [innerHTML]="tab.description | translate"></span>
            <sb-icon
              *ngIf="tab.descriptionTooltip"
              class="ml-3 text-muted"
              name="info"
              overrideStyle="solid"
              [ngbTooltip]="tab.descriptionTooltip | translate"
              placement="bottom"
            ></sb-icon>
          </div>
          <fieldset *ngIf="wizardForm.get(tab.formGroupName)" [formGroupName]="tab.formGroupName">
            <ng-container *ngFor="let field of tab.fields; let fieldIndex = index">
              <ng-container *ngIf="showField(field)">
                <ng-template #extraFieldIcons let-validationState="validationState">
                  <sb-icon
                    [name]="validationState?.iconName"
                    [textClass]="validationState?.textClass"
                    [ngbTooltip]="validationState?.label | translate"
                  ></sb-icon>
                  <sb-icon
                    *ngIf="field.tooltip"
                    name="questionCircle"
                    override-style="solid"
                    [ngbTooltip]="field.tooltip | translate"
                    class="text-muted ml-2"
                  ></sb-icon>
                </ng-template>
                <div
                  *ngIf="getControl(tab, field) || field.type?.isDecorative"
                  class="form-group {{ field.className + ' ' + (field.cols ? 'form-group-' + field.cols : '') }}"
                  id="formGroup-{{ parentDataId }}-{{ field.id }}"
                  [ngClass]="{
                    'form-group-decorative': field.type?.isDecorative,
                    'align-center': isFieldInline(field)
                  }"
                >
                  <div
                    *ngIf="field.description"
                    class="mb-5 text-muted"
                    [innerHTML]="field.description | translate"
                  ></div>
                  <div *ngIf="getControl(tab, field) as control" [ngClass]="{ 'align-center': isFieldInline(field) }">
                    <div *ngIf="field.toggleMessage" class="mr-3">
                      <sb-on-off-switch
                        [label]="field.toggleMessage"
                        [isChecked]="field.isToggledOn"
                        (modelChange)="onToggle(tab, field, $event)"
                      ></sb-on-off-switch>
                    </div>
                    <div
                      class="space-between transitionless-state"
                      [hidden]="field.toggleMessage && !field.isToggledOn"
                    >
                      <div class="align-center">
                        <label for class="control-label" *ngIf="field.label && !field.type?.includesLabel">
                          {{ field.label | translate }}
                          <span class="pl-2">
                            <ng-container *ngIf="!isFieldInline(field) && !field.type.isReadOnly">
                              <ng-container
                                *ngTemplateOutlet="
                                  extraFieldIcons;
                                  context: { validationState: getFieldValidationState(tab, field) }
                                "
                              ></ng-container>
                            </ng-container>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div [hidden]="isHiddenByToggle(field)">
                      <div *ngIf="field.type?.isText" class="space-between">
                        <div class="flex-grow" [ngClass]="{ 'input-group': field.type?.addon }">
                          <span *ngIf="field.type?.addon" class="input-group-addon" id="{{ field.id }}-addon">
                            <span *ngIf="field.type !== formElements.Tel" [innerText]="field.type?.addon"></span>
                          </span>
                          <input
                            [type]="field.type?.typeLabel"
                            class="form-control"
                            [name]="field.id"
                            [attr.disabled]="disabledAttrValue(!enableField(field))"
                            placeholder="{{ field.placeholder | translate }}"
                            [formControlName]="field.id"
                            [maxlength]="field.maxlength"
                            [min]="field.min"
                            [max]="field.max"
                          />
                        </div>
                        <div *ngIf="field.type === formElements.Url">
                          <button
                            class="btn btn-link btn-auto"
                            [attr.disabled]="
                              disabledAttrValue(
                                !getValue(tab, field) || (wizardForm.controls && wizardForm.controls[field.id]?.invalid)
                              )
                            "
                            (click)="openUrl(getValue(tab, field))"
                          >
                            <sb-icon name="followLink"></sb-icon>
                          </button>
                        </div>
                        <div *ngIf="field.type === formElements.Twitter">
                          <button
                            class="btn btn-link btn-auto"
                            [attr.disabled]="
                              disabledAttrValue(
                                !getValue(tab, field) || (wizardForm.controls && wizardForm.controls[field.id]?.invalid)
                              )
                            "
                            (click)="openTwitterHandle(getValue(tab, field))"
                          >
                            <sb-icon name="followLink"></sb-icon>
                          </button>
                        </div>
                      </div>
                      <select
                        *ngIf="field.type === formElements.SimpleSelect"
                        [formControlName]="field.id"
                        [attr.disabled]="disabledAttrValue(field.isDisabled)"
                        [required]="field.isRequired"
                        [hidden]="optionsLoading(field)"
                        class="form-control"
                      >
                        <option
                          [attr.disabled]="disabledAttrValue(isFieldRequired(field))"
                          [innerHTML]="'SB_Select' | translate"
                          [ngValue]="null"
                        ></option>
                        <option
                          *ngFor="let option of field.options"
                          [innerHTML]="option[field.labelProp || 'name'] | translate"
                          [ngValue]="option[field.idProp || 'id']"
                          [attr.disabled]="disabledAttrValue(option.isDisabled)"
                        ></option>
                      </select>
                      <sb-telephone-input
                        *ngIf="field.type === formElements.Tel"
                        [dialCountry]="data[field.id + '_relatedProperty']"
                        [isDisabled]="!enableField(field)"
                        [fieldId]="field.id"
                        [parentId]="parentDataId?.toString()"
                        [form]="getFormGroup(tab.formGroupName)"
                      ></sb-telephone-input>
                      <sb-select
                        *ngIf="field.type?.isVisualSelect"
                        [preset]="field.type"
                        [selectOptions]="getFieldEnabledOptions(field)"
                        [isDisabled]="!enableField(field)"
                        [id]="field.id"
                        [labelProp]="field.labelProp"
                        [idProp]="field.idProp"
                        [isGrid]="field.isGrid"
                        [primaryOptionIds]="field.primaryOptionIds"
                        [isColourIcons]="field.isColourIcons"
                        [form]="getFormGroup(tab.formGroupName)"
                        [hidden]="optionsLoading(field)"
                      ></sb-select>
                      <sb-loading *ngIf="optionsLoading(field)" class="text-muted"></sb-loading>
                      <div
                        *ngIf="field.options?.length === 0 && !field.emptyOptionsMessage"
                        [innerHTML]="'SB_Shared_SelectOption_NoOptionsAvailable' | translate"
                      ></div>
                      <div
                        *ngIf="field.type === formElements.Switch"
                        [ngClass]="{ 'pt-2': !field.isInline && !field.noPadding }"
                      >
                        <sb-on-off-switch
                          [isDisabled]="false"
                          [isChecked]="getValue(tab, field)"
                          [id]="field.id"
                          [trueLabel]="field.trueLabel"
                          [falseLabel]="field.falseLabel"
                          [isDisabled]="!enableField(field)"
                          (modelChange)="updateValue(tab, field, $event)"
                          [ngClass]="{ 'p-2': !field.noPadding }"
                        >
                        </sb-on-off-switch>
                      </div>
                      <sb-colour-picker
                        *ngIf="field.type === formElements.Colours"
                        [colourOptions]="getValue(tab, field)"
                        (colourOptionsChange)="updateValue(tab, field, $event)"
                      ></sb-colour-picker>
                      <sb-textarea
                        *ngIf="field.type === formElements.Content"
                        [placeholder]="field.placeholder"
                        id="{{ field.id }}"
                        [height]="field.height"
                        [maxLength]="field.maxlength"
                        [rows]="field.rows"
                        class="mt-2"
                        [editorConfig]="field.editorConfig"
                        [ngClass]="{ 'disabled-area': !enableField(field) }"
                        [form]="getFormGroup(tab.formGroupName)"
                        (modelChange)="updateValue(tab, field, $event)"
                      />
                      <div
                        *ngIf="field.type === formElements.CheckboxList"
                        class="sb-grid p-2"
                        [ngClass]="{ 'disabled-area': !enableField(field) }"
                      >
                        <div class="align-center" *ngFor="let option of field.options; let optionIndex = index">
                          <sb-check-box
                            *ngIf="!option.isHidden"
                            [isChecked]="getValue(tab, field)?.includes(option.id)"
                            [controlName]="field.id"
                            [label]="option.name"
                            (modelChange)="onCheckBoxChanged(tab, field, option, $event)"
                          ></sb-check-box>
                        </div>
                      </div>
                      <div *ngIf="field.type === formElements.ButtonGroup" class="pt-3">
                        <div [ngClass]="{ 'btn-group': field.isGroup !== false }" role="group">
                          <button
                            *ngFor="let option of field.options"
                            type="button"
                            [ngClass]="getButtonClass(tab, field, option)"
                            id="{{ field.id }}-{{ option.id }}"
                            [attr.disabled]="disabledAttrValue(option.isDisabled || !enableField(field))"
                          >
                            <div class="w-100 align-center">
                              <sb-icon
                                *ngIf="option.iconName"
                                class="mr-3 p-large"
                                name="{{ option.iconName }}"
                                [overrideStyle]="getValue(tab, field) === option.id ? 'solid' : 'light'"
                              ></sb-icon>
                              <span [innerHTML]="option.name | translate"></span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="mt-4" *ngIf="field.type === formElements.Users" [hidden]="isHiddenByToggle(field)">
                        <div class="space-between text-base mb-5">
                          <h2 [innerHTML]="field.label || 'Select users' | translate"></h2>
                          <sb-button
                            *ngIf="field.isToggledOn"
                            buttonClass="link"
                            iconName="close"
                            iconClasses="text-muted"
                            (onClick)="field.isToggledOn = false"
                          ></sb-button>
                        </div>
                        <ng1-multi-person-picker
                          [selectedPersonIds]="getValue(tab, field) || []"
                          (selectedPersonIdsChange)="updateAndProvide(tab, field, $event)"
                          [showOkButton]="false"
                          [includePupils]="field.subTypeIds.includes(memberTypes.Student.id)"
                          [includeStaff]="field.subTypeIds.includes(memberTypes.Staff.id)"
                          [includeParents]="field.subTypeIds.includes(memberTypes.Parent.id)"
                          [hideGroupFilter]="!field.subTypeIds.includes(memberTypes.Student.id)"
                          [hideYearFilter]="!field.subTypeIds.includes(memberTypes.Student.id)"
                          [lockedPersonId]="field.lockedItemId"
                        ></ng1-multi-person-picker>
                      </div>
                      <sb-team-club-picker
                        *ngIf="field.type === formElements.UserGroupings"
                        [showSubjects]="showSubjects"
                        [model]="getValue(tab, field)"
                        (modelChange)="updateValue(tab, field, $event)"
                      ></sb-team-club-picker>
                      <div
                        *ngIf="field.type?.isMultiSelectComponent"
                        class="align-center"
                        [ngClass]="{ 'multiselect-single-container': !field.isMultiOption }"
                        [hidden]="optionsLoading(field)"
                      >
                        <ng-container>
                          <ng-container>
                            <sb-multiselect-dropdown
                              *ngIf="getFieldEnabledOptions(field)?.length && field.dropdownSettings"
                              [singleSelection]="!field.isMultiOption"
                              [settings]="field.dropdownSettings"
                              [placeholder]="getMultiSelectPlaceholder(tab, field)"
                              [data]="getFieldEnabledOptions(field)"
                              [model]="getValue(tab, field)"
                              [disabled]="!enableField(field)"
                              [reformat]="true"
                              (modelChange)="updateValue(tab, field, $event)"
                              (onClick)="setFieldAsTouched(tab, field)"
                              class="flex-grow"
                            ></sb-multiselect-dropdown>
                            <sb-button
                              *ngIf="field.type === formElements.SearchAdd && field.subWizardConfig?.subWizardTabs"
                              iconName="edit"
                              [isAutoSize]="true"
                              [isDisabled]="!getValue(tab, field)"
                              buttonClass="link"
                              (onClick)="openSubWizard(field, getValue(tab, field))"
                              tooltipText="SB_Edit"
                              tooltipPlacement="bottom"
                              class="ml-3"
                            ></sb-button>
                            <sb-button
                              *ngIf="field.type === formElements.SearchAdd && field.subWizardConfig?.subWizardTabs"
                              iconName="plus"
                              [isAutoSize]="true"
                              buttonClass="link"
                              (onClick)="openSubWizard(field)"
                              tooltipText="SB_Add"
                              tooltipPlacement="bottom"
                              class="ml-3"
                            ></sb-button>
                          </ng-container>
                        </ng-container>
                        <div
                          *ngIf="!field.options?.length && field.emptyOptionsMessage"
                          [innerHTML]="field.emptyOptionsMessage | translate"
                          class="form-group_empty-message"
                        ></div>
                      </div>
                      <sb-currency-input
                        *ngIf="field.type === formElements.Currency && !field.isDisabled"
                        [form]="getFormGroup(tab.formGroupName)"
                        [id]="field.id"
                        [allowZero]="field.allowZero"
                        [model]="getValue(tab, field)"
                      ></sb-currency-input>
                      <sb-text
                        *ngIf="field.type === formElements.Currency && field.isDisabled"
                        customClasses="text-primary semi-bold"
                        [message]="getFieldConfirmationValue(field)"
                      ></sb-text>
                      <sb-record-type-selector
                        *ngIf="field.type === formElements.RecordType && field.options"
                        [options]="field.options"
                        [id]="field.id"
                        [form]="getFormGroup(tab.formGroupName)"
                        [model]="getValue(tab, field)"
                      ></sb-record-type-selector>
                      <div *ngIf="field.type?.isDatePicker">
                        <sb-date-range-picker
                          *ngIf="field.type?.isSingleDate && field.type?.hasTime"
                          [startDate]="getValue(tab, field)?.startDate"
                          [minDate]="field.dateRangeLimit?.minLimit?.limitValue"
                          [singleDatePicker]="true"
                          [timePicker]="true"
                          [autoUpdate]="true"
                          [disabled]="!enableField(field)"
                          (onChange)="onDatePickerChange(tab, field, $event)"
                        ></sb-date-range-picker>
                        <sb-single-date-picker
                          *ngIf="field.type?.isSingleDate && !field.type?.hasTime"
                          [date]="getValue(tab, field)?.startDate"
                          [minDate]="field.dateRangeLimit?.minLimit?.limitValue"
                          [maxDate]="field.dateRangeLimit?.maxLimit?.limitValue"
                          [disabled]="!enableField(field)"
                          (onChange)="onDatePickerChange(tab, field, $event)"
                        ></sb-single-date-picker>
                        <sb-date-range-picker
                          *ngIf="!field.type?.isSingleDate"
                          [startDate]="getValue(tab, field)?.startDate"
                          [endDate]="getValue(tab, field)?.endDate"
                          [minDate]="field.dateRangeLimit?.minLimit?.limitValue"
                          [maxDate]="field.dateRangeLimit?.maxLimit?.limitValue"
                          [singleDatePicker]="false"
                          [timePicker]="field.type?.hasTime"
                          [autoUpdate]="true"
                          [disabled]="!enableField(field)"
                          (onChange)="onDatePickerChange(tab, field, $event)"
                        ></sb-date-range-picker>
                      </div>
                      <div *ngIf="field.type === formElements.Time" class="align-center">
                        <ngb-timepicker [formControlName]="field.id" [spinners]="false"></ngb-timepicker>
                        <div class="pl-3 text-muted">(24h)</div>
                      </div>
                      <div *ngIf="field.type === formElements.Summary" class="flex">
                        <sb-button
                          [buttonClass]="field.buttonClass || 'default'"
                          [message]="field.buttonMessage || 'SB_Edit'"
                          [isDisabled]="field.isDisabled"
                          (onClick)="showRelatedField(tab, field)"
                        ></sb-button>
                        <div class="pl-4 pt-2 text-base">
                          <div *ngFor="let item of getValue(tab, field) || []" [innerHTML]="item.name"></div>
                          <input hidden [formControlName]="field.id" />
                        </div>
                      </div>
                      <div *ngIf="field.type === formElements.IconList">
                        <sb-text *ngIf="field.iconList.length === 0" [message]="field.defaultValue"></sb-text>
                        <sb-icon
                          *ngFor="let icon of field.iconList"
                          [name]="icon.name"
                          [altText]="icon.tooltip"
                          [size]="icon.size"
                          [ngClass]="icon.class"
                          class="mr-2"
                        ></sb-icon>
                      </div>
                      <div *ngIf="field.type === formElements.Readonly">
                        <sb-text
                          [textClass]="field.uiClassName"
                          [customClasses]="field.className"
                          [message]="getFieldConfirmationValue(field)"
                          [icon]="field.icon"
                        ></sb-text>
                      </div>
                      <sb-badge
                        *ngIf="field.type === formElements.Badge"
                        [message]="getFieldConfirmationValue(field)"
                        [badgeClass]="field.uiClassName"
                      ></sb-badge>
                    </div>
                    <ng-container *ngIf="isFieldInline(field)">
                      <div class="ml-4" [ngClass]="{ invisible: field.isToggledOn === false || field.type.isReadOnly }">
                        <ng-container
                          *ngTemplateOutlet="
                            extraFieldIcons;
                            context: { validationState: getFieldValidationState(tab, field) }
                          "
                        ></ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <h2
                    *ngIf="field.type === formElements.SubHeading"
                    [innerHTML]="field.label | translate"
                    [ngClass]="{ 'mt-5': fieldIndex !== 0 }"
                  ></h2>
                  <sb-text
                    *ngIf="field.type === formElements.Paragraph"
                    [textClass]="field.uiClassName"
                    [customClasses]="field.className"
                  >
                    <p [innerHTML]="field.label | translate"></p>
                  </sb-text>
                  <sb-alert
                    *ngIf="field.type === formElements.Alert"
                    [message]="field.label"
                    [alertClass]="field.uiClassName"
                    [isSmall]="true"
                    [isTextOnly]="true"
                  ></sb-alert>
                  <sb-icon
                    *ngIf="field.type === formElements.Arrow"
                    name="arrow-right"
                    size="xl"
                    class="wizard__arrow"
                  ></sb-icon>
                  <div
                    *ngIf="field.type === formElements.Data"
                    class="sb-grid {{ field.className }}"
                    ng-style="getDataStyle(field)"
                  >
                    <div
                      *ngFor="let element of field.elements"
                      [innerHTML]="element.html | translate"
                      [ngClass]="element.class"
                    ></div>
                  </div>
                  <sb-button
                    *ngIf="field.type === formElements.Button"
                    [buttonClass]="field.buttonClass"
                    [message]="field.label"
                    [xsIconOnly]="field.xsIconOnly"
                    [isDisabled]="!enableField(field)"
                    (onClick)="buttonClick(field.id)"
                    [buttonId]="parentDataId + '-' + field.id"
                  ></sb-button>
                  <div
                    [ngClass]="{ invisible: !getValidationMessage(tab, field) }"
                    class="validation"
                    [innerHTML]="getValidationMessage(tab, field)"
                  ></div>
                  <div *ngIf="field.patternList" [ngClass]="{ 'text-muted': !isFieldTouched(tab, field) }">
                    <div
                      *ngFor="let item of getFormattedRuleList(tab, field)"
                      class="align-center"
                      [ngClass]="{ 'text-success': item.isComplete }"
                    >
                      <sb-icon [name]="item.isComplete ? 'tickCircle' : 'crossCircle'"></sb-icon>
                      <div [innerHTML]="item.label | translate" class="ml-2"></div>
                    </div>
                  </div>
                  <input
                    hidden
                    *ngIf="field.type?.manualValidation"
                    [formControlName]="field.id"
                    [value]="getValue(tab, field)"
                    [name]="field.id"
                  />
                </div>
                <sb-game-summary
                  *ngIf="field.type === formElements.GameSummary"
                  [data]="data"
                  [dataTabs]="tabs"
                  [size]="tab.isConfirmation ? 'md' : 'lg'"
                ></sb-game-summary>
                <sb-kit
                  *ngIf="field.type === formElements.Kit"
                  [kitStyleId]="data[field.secondaryIds[1]]"
                  [primaryColour]="data[field.secondaryIds[2]]?.primaryColour"
                  [secondaryColour]="data[field.secondaryIds[2]]?.secondaryColour"
                  size="lg"
                ></sb-kit>
                <div *ngIf="field.note" [innerHTML]="field.note | translate" class="wizard__note"></div>
              </ng-container>
            </ng-container>
          </fieldset>
        </ng-container>
      </ng-container>
    </form>
    <ng-content></ng-content>
    <ng-template #confirmation let-tabs="tabs" let-subWizardData="subWizardData">
      <div class="wizard__confirmation">
        <ng-container *ngFor="let tab of tabs">
          <div class="card w-100" *ngIf="!tab.isConfirmation && !tab.skipConfirmation && !isTabHidden(tab)">
            <div class="wizard__confirmation__section-heading">
              <sb-icon [name]="tab.iconName" overrideStyle="light"></sb-icon>
              <h3 [innerHTML]="tab.name | translate"></h3>
            </div>
            <div class="wizard__confirmation__section-block">
              <ng-container *ngFor="let field of tab.fields">
                <ng-container *ngIf="!field.type?.isDecorative && !field.skipConfirmation && showField(field)">
                  <div class="semi-bold" [innerHTML]="field.label || tab.name | translate"></div>
                  <div *ngIf="!field.subWizardData" [innerHTML]="getFieldConfirmationValue(field) | translate"></div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
    <ng-container *ngFor="let tab of tabs">
      <ng-container *ngIf="tab.id === activeTabId && tab.isConfirmation">
        <ng-container *ngTemplateOutlet="confirmation; context: { tabs: tabs }"></ng-container>
      </ng-container>
    </ng-container>
    <ng-content></ng-content>
  </div>
  <ng-container [ngTemplateOutlet]="actions"></ng-container>
</div>
<div *ngIf="showLoadingMessage()" class="loading-message">
  <sb-icon [name]="loadingMessage.iconName" overrideStyle="duotone"></sb-icon>
  <sb-text [message]="loadingMessage.message"></sb-text>
  <sb-loading></sb-loading>
</div>
<div *ngIf="isSavingSubWizard" class="justify-center p-5">
  <sb-loading [message]="subWizardField.subWizardConfig?.subWizardSavingMessage || 'Saving record'"></sb-loading>
</div>
<ng-template #subWizard let-modal>
  <sb-wizard
    *ngIf="!isLoadingSubWizardRecord && subWizardField?.subWizardConfig?.subWizardTabs"
    [isModal]="true"
    [formTitle]="subWizardTitle"
    [(tabs)]="subWizardField.subWizardConfig.subWizardTabs"
    [hideImages]="true"
    [mergeTabs]="true"
    [parentData]="subWizardData"
    [discardTitle]="subWizardField.subWizardConfig.subWizardDiscardTitle"
    [discardMessage]="subWizardRecordId ? 'Discard changes?' : subWizardField.subWizardConfig.subWizardDiscardMessage"
    [isLoading]="isLoadingSubWizardRecord"
    (onSave)="saveSubWizard($event, modal)"
    (onClose)="closeSubWizard(modal)"
  ></sb-wizard>
  <sb-loading [hasLoaded]="!isLoadingSubWizardRecord"></sb-loading>
</ng-template>
<sb-confirm-dialog></sb-confirm-dialog>
