import { Injectable } from '@angular/core';
import { isDebug } from '@sb-helpers';
import { OSType } from '@sb-shared/enums/os-type.enum';
import { RoleId } from '@sb-shared/enums/role.enum';
import { User } from '@sb-shared/models/user';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserUi } from '../models/user-ui';
import { MemberTypeId } from './../enums/member.type.enum';
import { HttpRequestSettings, apis, controllerTypes } from './../models/request-settings';
import { HttpWebApiService } from './http-web-api.service';
import { IdpExternalService } from './idp-external.service';
import { LocalStorageService } from './local-storage.service';
import { LouAssistService } from './lou-assist.service';
import { StringService } from './string.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  config: HttpRequestSettings = {
    api: apis.Web,
    shareReplay: 1
  };
  propertiesConfig: HttpRequestSettings = {
    api: apis.Core,
    controllerType: controllerTypes.User
  };
  urlPersonBase = 'WebPerson/';
  urlSettingsBase = 'WebSettings/';
  private currentUserSubj$ = new BehaviorSubject<UserUi>(null);
  welcomeMessage$: Observable<string>;
  localStorageKey = 'memberTypeId';

  private refreshPending = false;

  constructor(
    private http: HttpWebApiService,
    private stringService: StringService,
    private localStorage: LocalStorageService,
    private louAssistService: LouAssistService,
    private identityServerService: IdpExternalService,
    private authService: AuthService
  ) {}

  getCurrentUser(refresh?: boolean): Observable<UserUi> {
    if ((refresh || !this.currentUserSubj$.value) && !this.refreshPending) {
      //!reset currentUser to null before refresh to prevent emit prevous value.
      this.currentUserSubj$.next(null);
      this.refreshCurrentUser();
    }

    return this.currentUserSubj$.pipe(
      filter(value => !!value),
      first()
    );
  }

  private refreshCurrentUser() {
    this.refreshPending = true;

    this.http
      .get('Info/Properties', this.propertiesConfig)
      .pipe(
        map((user: UserUi) => {
          return this.addExtraInfoToUser(user);
        }),
        switchMap(user =>
          this.identityServerService.updateUserType(this.localStorage.get(this.localStorageKey)).pipe(
            switchMap(isSuccess =>
              isSuccess ? this.authService.refreshToken() : this.authService.getTokenAndRefreshIfExpired()
            ),
            map(() => user)
          )
        ),
        tap(user => this.louAssistService.setup(user)),
        tap(() => this.setPersonPlatformUsage())
      )
      .subscribe({
        next: currentUser => {
          this.currentUserSubj$.next(currentUser);
          this.refreshPending = false;
        },
        error: err => this.currentUserSubj$.error(err)
      });
  }

  getWelcomeMessage(): Observable<string> {
    // call getCurrentUser first to ensure that usermember type is set in local storage
    return (this.welcomeMessage$ ??= this.getCurrentUser().pipe(
      switchMap(() => {
        const audienceTypeId = <MemberTypeId>this.localStorage.get(this.localStorageKey);
        if (audienceTypeId == MemberTypeId.SysAdmin) {
          // No Welcome Message for Sys Admin.
          return of('You are logged-in as Sys Admin.');
        } else if (audienceTypeId == MemberTypeId.Unknown) {
          return of('Unable to get welcome message. Unknown user type.');
        }

        return this.http
          .get('Info/Welcome', {
            ...this.propertiesConfig,
            params: { audienceTypeId: audienceTypeId }
          })
          .pipe(shareReplay(1));
      })
    ));
  }

  addExtraInfoToUser(user: UserUi): UserUi {
    return {
      ...this.addCurrentUserTypeToUser(user),
      canSwitchOrganisation: this.localStorage.get('canSwitchOrganisation'),
      isCcaAdmin: user.userRoleIds.some(userRoleId => userRoleId == RoleId.CCAAdmin || userRoleId == RoleId.Admin)
    };
  }

  addCurrentUserTypeToUser(user: UserUi): UserUi {
    let currentMemberTypeId;
    currentMemberTypeId = this.localStorage.get(this.localStorageKey);
    if (currentMemberTypeId != null) {
      // Check they still have access, in case they've switched organisation.
      const isValid =
        currentMemberTypeId == MemberTypeId.Unknown
          ? false
          : currentMemberTypeId == MemberTypeId.Staff
            ? user.hasStaffAccess
            : currentMemberTypeId == MemberTypeId.Parent
              ? user.hasParentAccess
              : user.hasStudentAccess;

      if (!isValid) {
        currentMemberTypeId = null;
      }
    }

    if (currentMemberTypeId == null) {
      currentMemberTypeId = this.getMemberType(user);
    }

    this.localStorage.set(this.localStorageKey, currentMemberTypeId.toString());
    const isStaff =
      currentMemberTypeId === MemberTypeId.Staff ||
      //!when user is not student or parent, consider they as staff
      (currentMemberTypeId !== MemberTypeId.Student && currentMemberTypeId !== MemberTypeId.Parent);

    return {
      ...user,
      currentMemberTypeId: currentMemberTypeId,
      isStaff,
      isParent: currentMemberTypeId === MemberTypeId.Parent,
      isStudent: currentMemberTypeId === MemberTypeId.Student
    };
  }

  getMemberType(user: UserUi) {
    return this.getMemberTypeFromValues(
      user.hasStaffAccess,
      user.hasParentAccess,
      user.hasStudentAccess,
      user.isExternal,
      user.userRoleIds
    );
  }

  getMemberTypeOld(user: User) {
    return this.getMemberTypeFromValues(user.isStaff, user.isParent, user.isPupil, user.isExternal, null);
  }

  private getMemberTypeFromValues(hasStaff, hasParent, hasStudent, isExternal, userRoleIds) {
    return hasStaff || isExternal
      ? MemberTypeId.Staff
      : hasParent
        ? MemberTypeId.Parent
        : hasStudent
          ? MemberTypeId.Student
          : userRoleIds.includes(RoleId.SystemAdmin)
            ? MemberTypeId.SysAdmin
            : MemberTypeId.Unknown;
  }

  isAdmin(): boolean {
    const user = this.currentUserSubj$.value;
    const isAdmin = user.userRoleIds.includes(RoleId.Admin);
    const isSuperAdmin = user.userRoleIds.includes(RoleId.OrganisationSuperAdmin);

    return isAdmin || isSuperAdmin;
  }

  isSportAdmin(): boolean {
    const user = this.currentUserSubj$.value;
    const isSportCoordinator = user.userRoleIds.includes(RoleId.SportsCoordinator);

    return isSportCoordinator || this.isAdmin();
  }

  isTripAdminOrHigher(): boolean {
    const user = this.currentUserSubj$.value;
    const isTripAdmin = user.userRoleIds.includes(RoleId.TripCoordinator);

    return isTripAdmin || this.isAdmin();
  }

  isExternalCoachWithAccess(): boolean {
    return this.currentUserSubj$.value.isExternalCoach && this.currentUserSubj$.value.externalCoachHasOrganisingAccess;
  }

  isStaffInternal() {
    return !this.currentUserSubj$.value.isExternal && this.currentUserSubj$.value.isStaff;
  }

  updateCurrentUserType(userType: MemberTypeId) {
    const previousType = this.localStorage.get(this.localStorageKey);
    this.localStorage.set(this.localStorageKey, userType.toString());

    this.identityServerService
      .updateUserType(userType)
      .pipe(
        switchMap(isSuccess =>
          isSuccess ? this.authService.refreshToken() : this.authService.getTokenAndRefreshIfExpired()
        )
      )
      .subscribe({
        next: () => {
          // Force user to reload dashboard to clear any client-side caching
          // and display correct menu items.
          window.location.href = '/Dashboard';
        },
        error: err => {
          console.log(err);
          this.localStorage.set(this.localStorageKey, previousType);
        }
      });
  }

  getCurrentPersonId() {
    return this.getCurrentUser().pipe(map(user => user.id));
  }

  getCurrentPersonSettings() {
    return this.http.get('WebSettings/GetAllSettings', this.config);
  }

  getPerson(personId: number, inclusionFlags?: string[]): Observable<User> {
    const params = {
      personId: personId,
      toInclude: inclusionFlags
    };

    return this.http.get(this.urlPersonBase + 'GetPersonData', { ...this.config, params: params }).pipe(
      map(user => ({
        ...user,
        name: this.stringService.formatName(
          user.personData.lastName,
          user.personData.firstName,
          user.personData.nickName
        ),
        id: user.personData.id, // Card only shows menu if selectedValue has an id
        systemGenerateEmail: false // not relevant for existing users, set to false so that email is a required field. return user; })));
      }))
    );
  }

  getCurrentPersonData(): Observable<User> {
    return this.getCurrentPersonId().pipe(switchMap(userId => this.getPerson(userId)));
  }

  isStaff() {
    return this.getCurrentUser().pipe(map(user => user.isStaff));
  }

  isParent() {
    return this.getCurrentUser().pipe(map(user => user.isParent));
  }

  isStaffInternal$() {
    return this.getCurrentUser().pipe(map(user => !user.isExternal && user.isStaff));
  }

  isExternal() {
    return this.getCurrentUser().pipe(map(user => user.isExternal));
  }

  updateSetting(key: string, value: string, configSettings?: Partial<HttpRequestSettings>) {
    // Get cached settings
    return this.getCurrentPersonSettings().pipe(
      switchMap(settings => {
        // Update new setting
        settings[key] = value;
        // Save settings
        return this.http
          .post(this.urlSettingsBase + 'SaveUserSettings', settings, { ...this.config, ...configSettings })
          .pipe(
            tap(() => {
              this.refreshCurrentUser();
            })
          );
      })
    );
  }

  checkUserRole(user: UserUi, roleId: number): boolean {
    return user.isStaff && user.userRoleIds.some(userRoleId => userRoleId === roleId);
  }

  setPersonPlatformUsage() {
    if (isDebug('debug-platform')) {
      console.trace('setPersonPlatformUsage');
    }

    this.http
      .post(this.urlPersonBase + 'PersonPlatformUsage', OSType.Web, { ...this.config, showSuccessToast: false })
      .subscribe();
  }

  getUserNameFormatted(): Observable<string> {
    return this.getCurrentUser().pipe(
      map(user => {
        let name = `${user.lastName}, ${user.firstName}`;
        if (user.nickName) {
          name += ` (${user.nickName})`;
        }
        return name;
      })
    );
  }

  getTeamsOfUsers(includeArchivedTeams: boolean, includeDeletedTeams: boolean): Observable<{ [id: number]: number[] }> {
    return this.http
      .get('user/teams', {
        api: apis.Core,
        controllerType: controllerTypes.Admin,
        params: { includeArchivedTeams, includeDeletedTeams }
      })
      .pipe(map(data => data.teamsOfUser));
  }
}
