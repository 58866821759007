import { Injectable } from '@angular/core';
import { Organisation } from '@sb-shared/models/organisation';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { forkJoin, Observable, throwError } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { apis, controllerTypes, HttpRequestSettings } from './../models/request-settings';
import { Option } from './../models/UI/filter';
import { IdpExternalService } from './idp-external.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  urlBase = 'WebOrganisation/';
  currentOrganisation$: Observable<Organisation>;
  config: HttpRequestSettings = {
    api: apis.Web
  };
  propertiesConfig: HttpRequestSettings = {
    api: apis.Core,
    controllerType: controllerTypes.User
  };
  receptionConfig: HttpRequestSettings = {
    api: apis.Reception
  };
  webConfig: HttpRequestSettings = {
    api: apis.Web
  };

  constructor(
    private http: HttpWebApiService,
    private identity: IdpExternalService
  ) {}

  getCurrentOrganisation(refresh?: boolean): Observable<Organisation> {
    if (!this.currentOrganisation$ || refresh) {
      this.currentOrganisation$ = forkJoin([
        this.http.get('Organisation/Properties', this.propertiesConfig),
        this.identity.hasSingleSignOnEnabled(this.http.currentOrganisationId),
        this.http.get('WebBlobStoragePhotos/OrganisationHasPhotos', this.webConfig)
      ]).pipe(
        map(([organisation, hasSingleSignOnEnabled, organisationHasPhotos]: [Organisation, boolean, boolean]) => {
          if (organisation == null) {
            // May fail due to invalid injected JWT, throw error to force logout.
            throw throwError(null);
          }

          organisation.hasSingleSignOnEnabled = hasSingleSignOnEnabled;
          window.EveryBuddy.CurrentOrganisationId = organisation.id;
          window.EveryBuddy.CurrentOrganisationTimeZone = organisation.ianaTimeZoneName;
          window.EveryBuddy.Constants.CurrencyDisplaySymbol = organisation.currencyDisplaySymbol;
          window.EveryBuddy.Constants.CurrencyExponent = organisation.currencyExponent;
          window.EveryBuddy.ServerId = environment.serverId;
          window.EveryBuddy.OrganisationHasPhotos = organisationHasPhotos;

          return organisation;
        }),
        shareReplay(1)
      );
    }
    return this.currentOrganisation$;
  }

  getCurrentOrganisationId(): Observable<number> {
    return this.getCurrentOrganisation().pipe(map(data => data.id));
  }

  getCurrentOrganisationType(): Observable<number> {
    return this.getCurrentOrganisation().pipe(map(data => data.organisationTypeId));
  }

  isSchool(organisation: Organisation) {
    return organisation.organisationTypeId === window.EveryBuddy.Enums.OrganisationTypes.School;
  }

  isClub(organisation: Organisation) {
    return organisation.organisationTypeId === window.EveryBuddy.Enums.OrganisationTypes.Club;
  }

  getActivities(filter?: any) {
    const url = this.urlBase + 'GetActivities';
    return this.http.post(url, filter, { ...this.config, showSuccessToast: false }).pipe(
      map(activities => {
        return activities.map((activity: Option) => {
          return {
            ...activity,
            iconName: `sportIcon${activity.id}`,
            iconIsActivity: true
          };
        });
      })
    );
  }

  getOrganisationCurrencySymbol() {
    return this.getCurrentOrganisation().pipe(map(org => org.currencyDisplaySymbol));
  }
}
