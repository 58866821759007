import { Injectable } from '@angular/core';
import { apis, HttpRequestSettings } from '@sb-shared/models/request-settings';
import { HttpWebApiService } from '@sb-shared/services/http-web-api.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// There have been changes to this in AngularJs app which will need to be applied
import { DiaryEvent } from 'src/app/diary/models/diary-event';

@Injectable({
  providedIn: 'root'
})
export class BlobStoragePhotoService {
  urlBase = '/WebBlobStoragePhotos/';
  config: HttpRequestSettings = {
    api: apis.Web,
    showSuccessToast: false
  };

  constructor(private http: HttpWebApiService) {}

  organisationHasPhotos() {
    return window.EveryBuddy.OrganisationHasPhotos;
  }

  getPhotoUrlsForStudents(studentPersonIds: number[]): Observable<PhotoUrlsForStudents[]> {
    if (!Array.isArray(studentPersonIds) || studentPersonIds.length < 1 || !this.organisationHasPhotos()) {
      return of([]);
    }

    const params = { studentPersonIds: studentPersonIds };
    return this.http
      .post(this.urlBase + 'GetPhotoUrlsForStudents', params, this.config)
      .pipe(map(response => response?.photoUrlsForStudents));
  }

  addStudentPhotoUrlsToArray(events: DiaryEvent[]): Observable<DiaryEvent[]> {
    if (!this.organisationHasPhotos()) {
      return of(events);
    }

    const personIds = events.filter(event => event.personId > 0).map(event => event.personId);

    return this.getPhotoUrlsForStudents(personIds).pipe(
      map(photoUrls => {
        if (photoUrls?.length > 0) {
          // Person as any to allow varying photoUrl property
          events.forEach((event: DiaryEvent) => {
            const photoPerson = photoUrls.find(photo => photo.personId == event.personId);

            if (photoPerson) {
              event.photoUrl = photoPerson.thumbnail;
            }
          });
        }

        return events;
      })
    );
  }
}

interface PhotoUrlsForStudents {
  personId: number;
  thumbnail: string;
  oneHundredPx: string;
}
