<div class="record-type-selector" [ngClass]="{ 'record-type-selector--long': options.length > 3 }">
  <div
    *ngFor="let option of sortedOptions()"
    class="record-type-selector__option"
    [ngClass]="{
      'record-type-selector__option--selected': model === option.id,
      'disabled-area': option.isDisabled
    }"
    [hidden]="option.isHidden"
    (click)="change(option.id)">
    <div>
      <sb-radio-button [isChecked]="model === option.id" class="flex" radioClass="secondary"></sb-radio-button>
      <sb-icon
        [name]="getIconName(option)"
        [overrideStyle]="model === option.id ? 'solid' : 'regular'"
        [hidden]="hideIcons()"></sb-icon>
    </div>
    <div>
      <div class="semi-bold" [innerHTML]="option.name | translate"></div>
      <div [innerHTML]="option.description | translate"></div>
    </div>
  </div>
</div>
<ng-container [formGroup]="form">
  <input hidden [formControlName]="id" [(ngModel)]="model" />
</ng-container>
